import { ApolloClient, from, NormalizedCacheObject } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'
import cache from './cache'
import { getAccessTokenPromise } from 'src/utils/auth'
import { GET_USER_PERMISSIONS } from './queries/auth'

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {})
  }
})

const uploadAndHttpLink = createUploadLink({
  uri: process.env.REACT_APP_URI,
})

const hygraphLink = createUploadLink({
  uri: process.env.REACT_APP_HYGRAPH_ENDPOINT,
})

export let client: ApolloClient<NormalizedCacheObject>

const authLink = setContext(async (_, { headers }) => {
  const accessToken = await getAccessTokenPromise()

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${accessToken}` || '',
    },
  }
})

const link = from([errorLink, uploadAndHttpLink])

client = new ApolloClient({
  connectToDevTools: true,
  link: authLink.concat(link),
  cache,
})

export async function fetchUserPermissions(userId: string) {
  const permisssionClient = new ApolloClient({ cache, link: authLink.concat(link) })

  try {
    const userAccess = await permisssionClient.query({
      query: GET_USER_PERMISSIONS,
      variables: {
        userId,
      },
    })
    const userPermissions = userAccess?.data?.getUserAccess?.rolePermission?.permission
    localStorage?.removeItem('rbac')
    localStorage.setItem('rbac', JSON.stringify(userPermissions))
    return userPermissions
  } catch (error) {}
}

export default client

export const hygraphClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  connectToDevTools: true,
  link: from([errorLink, hygraphLink]),
  cache,
})
