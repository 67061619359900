// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-DocumentVideo-Confirm-container{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between}.onfido-sdk-ui-DocumentVideo-Confirm-container.onfido-sdk-ui-DocumentVideo-Confirm-center{-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center}.onfido-sdk-ui-DocumentVideo-Confirm-content{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-align:center;-webkit-align-items:center;align-items:center}.onfido-sdk-ui-DocumentVideo-Confirm-title{font-size:1.5em;font-weight:bold}.onfido-sdk-ui-DocumentVideo-Confirm-content>.onfido-sdk-ui-DocumentVideo-Confirm-icon{height:2em;width:2em;margin-bottom:.5em}.onfido-sdk-ui-DocumentVideo-Confirm-content>.onfido-sdk-ui-DocumentVideo-Confirm-body{font-weight:normal}.onfido-sdk-ui-DocumentVideo-Confirm-preview .onfido-sdk-ui-DocumentVideo-Confirm-videoWrapper{width:66%;margin:1em auto 0}.onfido-sdk-ui-DocumentVideo-Confirm-buttonsContainer>button{margin-top:1em}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_animation_duration": "200ms",
	"container": "onfido-sdk-ui-DocumentVideo-Confirm-container",
	"center": "onfido-sdk-ui-DocumentVideo-Confirm-center",
	"content": "onfido-sdk-ui-DocumentVideo-Confirm-content",
	"title": "onfido-sdk-ui-DocumentVideo-Confirm-title",
	"icon": "onfido-sdk-ui-DocumentVideo-Confirm-icon",
	"body": "onfido-sdk-ui-DocumentVideo-Confirm-body",
	"preview": "onfido-sdk-ui-DocumentVideo-Confirm-preview",
	"videoWrapper": "onfido-sdk-ui-DocumentVideo-Confirm-videoWrapper",
	"buttonsContainer": "onfido-sdk-ui-DocumentVideo-Confirm-buttonsContainer"
};
export default ___CSS_LOADER_EXPORT___;
