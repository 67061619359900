import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { IOption } from 'src/types/relisting'

interface IRESelectProps {
  id: string
  label: string
  required: boolean
  maxlength?: number
  placeholder?: string
  type?: string
  variant: string
  value: string
  options: IOption[]
  handleChange: Function
}
export default function RESelect({
  id,
  label,
  options,
  variant,
  required,
  handleChange,
  value,
}: IRESelectProps) {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext()
  return (
    <>
      <div className="relisting-input">
        <Controller
          name={id}
          control={control}
          rules={{ required: required }}
          render={({ field }) => (
            <FormControl className={`details -${variant}`} error={errors[id]?.type === 'required'}>
              <InputLabel>{label}</InputLabel>
              <Select
                id={id}
                key={`select=${value}`}
                label={label}
                size="small"
                defaultValue={value}
                {...register(id, {
                  onChange: (event) => handleChange(id, event),
                })}
              >
                {options.length > 0 &&
                  options.map((option, i) => (
                    <MenuItem key={i} value={option.option_value}>
                      {option.option_label}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText>
                {errors[id]?.type === 'required' ? `${label} is required` : ' '}
              </FormHelperText>
            </FormControl>
          )}
        ></Controller>
      </div>
    </>
  )
}
