interface IFileHPreviewProps {
  img: string
  file: File
}

export default function REFileHomeImagePreview({ img, file }: IFileHPreviewProps): JSX.Element {
  return (
    <div className="gallery-box">
      <div className="galleryimg">
        <div className="homepage">Homepage</div>
        <img className="reimg" src={img} alt={file.name} />
      </div>
    </div>
  )
}
