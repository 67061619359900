import HomePageLayout from 'src/components/layouts/HomepageLayout'
import FooterLandingPage from 'src/components/sections/FooterLandingPage'
import Header from 'src/components/sections/Header'
import { ArrowUp } from 'src/components/ui/icon/Icon'
import { scrollToTop } from 'src/utils/commonUtils'

export default function Privacy(): JSX.Element {
  return (
    <HomePageLayout variant="about">
      <Header variant="aboutpage" />
      <div className="terms">
        <div className="pointer" onClick={scrollToTop}>
          <ArrowUp color="var(--main-1)" size={30} />
        </div>
        <div className="info">
          <div className="docblock">
            <div className="doctitle">Privacy Policy</div>
            <div className="docdetail">
              The present privacy policy shall be effective starting from February 11th 2021. This
              Privacy Policy explains how 3Blocks LLC. (“3Blocks”, “we” or “us”) a company
              incorporated under the laws of Florida, USA, and having its address at 4910 E Adamo
              Dr, Unit B, Tampa, FL USA. collects, uses and discloses information about you when you
              use our website, https://www.3Blocks.io, and online services (collectively, the
              “Services”), when you interact with us as described below. We may change this Privacy
              Policy from time to time at our sole discretion. If we make changes, we will notify
              you by revising the date at the top of this Privacy Policy, and the updated Privacy
              Policy will be posted via a hyperlink at the bottom of our website homepage. In some
              cases, we may provide you with additional notice (such as by adding a statement
              relating to Privacy Policy changes to our website homepage or by sending an email
              notification). We encourage you to review this Privacy Policy whenever you access the
              Services to stay informed about our information practices and the choices available to
              you. It is your obligation to periodically visit our Website to review any changes
              that may be made to this Privacy Policy. Your continued use of our Website and
              Services constitutes your agreement to be bound by our Privacy Policy and any changes
              to the Privacy Policy. If you do not agree with this Privacy Policy, you are not
              authorized to use our Website and your sole recourse is to not use our Website or the
              Services. We understand that your privacy is important, and we are strongly committed
              to protecting it. This includes the Personal Data or Information that you may provide
              when you access and use content we own or operate on the websites located at
              www.3blocks.io, or any other websites, app, pages, features, or content we own or
              operate (hereafter: the “Site(s)”) and/or when you use our mobile application and
              related services (referred to collectively hereinafter as “Services”). In addition,
              information that you submit to 3Blocks in response to an email, request for
              information or other outreach to 3Blocks, or through any 3Blocks application
              processes, will also be treated in accordance with this Privacy Policy. In this
              written Privacy Policy, 3Blocks describes the use of your Personal Data by 3Blocks and
              the scope and breadth of our commitment to privacy. We undertake to ensure that your
              Personal Data is handled responsibly and in compliance with the legal requirement of
              State and Federal laws and regulations.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">1. Acceptance of 3Blocks Privacy Policy</div>
            <div className="docdetail">
              By exploring and using 3Blocks’s Site and/or Services, and by submitting information
              to 3Blocks, you signify acceptance to the terms of our Privacy Policy. Where we
              require your consent to process your Personal Data, we will ask for your consent to
              the collection, use, and disclosure of your Personal Data as described further below.
              3Blocks may provide additional “just-in-time” disclosures or additional information
              about the data collection, use and sharing practices of specific Services. If you do
              not agree with any aspect of this Privacy Policy, you should immediately discontinue
              access or use of our Services.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">2. Data & Personal Data</div>
            <div className="docdetail">
              Personal Data is data that can be used to identify you directly or indirectly, or to
              contact you. Our Privacy Policy covers all Personal Data that you voluntarily submit
              to us and that we obtain from our partners. This Privacy Policy does not apply to
              anonymized data, as it cannot be used to identify you. You may be asked to provide
              Personal Data anytime you are in contact with us. 3Blocks may also combine your
              Personal Data with other information to provide and improve our products, services,
              and content (see the section below). Except as described in this Privacy Policy,
              3Blocks will not give, sell, rent or loan any Personal Data to any third party.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">2.1 Personal Data We Collect</div>
            <div className="docdetail">
              We collect personal data you provide directly to us or which you generate when you
              open an account with us, use our Services or our website(s) and platform(s).
              <br />
              When we require certain personal data from users it is because we are required by law
              to collect this data (anti-fraud / anti-money laundering / counter financing of
              terrorism / know your customer obligations) or it is relevant for specified purposes.
              <br />
              Any information you provide to us that is not required is voluntary.
              <br />
              You are free to choose whether to provide us with the types of Personal Data
              requested, but we may not be able to serve you as effectively or offer you all of our
              Services when you do choose not to share certain information with us.
              <br />
              We also collect Personal Data when you use or request information about our Services,
              subscribe to marketing communications, request support, complete surveys, or sign up
              for one of our events.
              <br />
              We may also collect Personal Data from you offline, such as when you attend one of our
              events, or when you contact customer support.
              <br />
              We may use this information in combination with other information we collect about you
              as set forth in this Privacy Policy.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">2.2 Data Collection When Registering / Using Our Services</div>
            <div className="docdetail">
              When registering on our Site(s), you may be asked to enter:
              <ul>
                <li>
                  Contact information and personal details, such as your name, residential, business
                  and postal addresses, telephone number, email address, and family details such as
                  name of your spouse, partner or children;
                </li>
                <li>
                  Profile information, such as the username and password you establish with the
                  Services;
                </li>
                <li>
                  Demographic details, such as date of birth, country of citizenship and/or country
                  of residence, marital status;
                </li>
                <li>
                  Information about your net worth and/or income, such as your stated net worth;
                  stated current, historical, and expected future income; source(s) of income; and
                  supporting documentation that you choose to submit (e.g., tax forms, tax returns,
                  pay stubs, financial account statements, and information about your financial
                  assets and liabilities);
                </li>
                <li>
                  Government-issued identification (to the extent permitted under applicable law),
                  such as a national identification number (e.g., a Social Security Number, tax
                  identification number, or passport number), state or local identification number
                  (e.g., a Driver’s License or other government-issued identification number), a
                  copy of your government-issued identification card and related government issued
                  photographs;
                </li>
                <li>
                  Information about your investment experience and preferences, such as investment
                  objectives, investment experience, liquidity needs, and risk tolerance;
                </li>
                <li>
                  Financial Account and payment Information, such as your bank accounts you may use
                  to source your investment funds, other related payment information and the
                  cryptocurrency wallet addresses you may use to receive your 3Blocks real estate
                  NFTs/tokens and revenue payout tokens, if any;
                </li>
                <li>
                  Information about your relationship to a company, such as your current occupation,
                  job title and whether you are a beneficial owner or authorized signatory of the
                  legal entity using the Service;
                </li>
                <li>
                  Feedback and correspondence, such as information you provide when you request
                  information from us, receive customer support, or otherwise correspond with us,
                  including by interacting with our pages on social networking online sites or
                  services;
                </li>
                <li>
                  Marketing information, such as your preferences for receiving marketing
                  communications and details about how you engage with our marketing communications.
                </li>
                <li>
                  Transaction Information: Information about the transactions you make on our
                  Services, such as the name of the recipient, your name, the amount, and/or
                  timestamp.
                </li>
                <li>
                  Employment Information: Office location, job title, and/or description of role.
                </li>
                <li>Social media information such as username or handle, preferences; and</li>
                <li>Other information you choose to provide.</li>
              </ul>
              We may also collect Personal Data from third party partners and public sources as
              required or permitted by applicable law, such as public databases, credit bureaus, ID
              verification partners, resellers and channel partners, joint marketing partners, and
              social media platforms which include:
              <ul>
                <li>Financial information</li>
                <li>Reputational information</li>
                <li>Corporate/business activities for corporate customers</li>
              </ul>
              We use public databases and ID verification partners to verify your identity. Our ID
              verification partners use a combination of government records and publicly available
              information about you to verify your identity. Such information includes your name,
              address, job position, public employment profile, credit history, status on any
              sanctions lists maintained by public authorities, and other relevant data. We obtain
              such information to comply with our legal obligations, such as anti-money laundering
              laws.
              <br />
              In some cases, we may process additional data about you to ensure our Services are not
              used fraudulently or for other illicit activities. In such instances, processing is
              necessary for us to continue to perform our contract with you and others.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">2.3 Data Collection When Visiting Our Website</div>
            <div className="docdetail">
              When visiting our website, we store every instance of access in a log file and
              therefore, the following Data are stored in this process:
              <ul>
                <li>Computer or mobile device information</li>
                <li>Website from which our domain is accessed and website usage information</li>
                <li>Operating system of your computer</li>
                <li>Country from which our website is accessed</li>
                <li>Name of your internet provider</li>
                <li>Name and URL of the Data accessed</li>
                <li>Date and time of access</li>
                <li>IP address of the accessing computer</li>
                These Data are generally anonymized with no personal reference and only used to
                establish connection with the website, to ensure ongoing system security and
                stability and for statistical purposes. These Data are not associated or stored with
                Personal Data.
                <br />
                IP address of the accessing computers will be analyzed only in the event of an
                attack on the network infrastructure or in case of suspicion of otherwise
                unauthorized or improper use of the website and for the sole purposes of defense or
                use as part of criminal proceedings for identification purposes and for criminal
                and/or civil proceedings against the user involved. IP address is also used to
                filter out geographical zones from accessing our Services.
              </ul>
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">3. How Your Personal Data Is Used</div>
            <div className="docdetail">
              Our primary purpose in collecting personal information is to provide you with a
              secure, smooth, efficient, and customized experience. In general, we use personal
              information to create, develop, operate, deliver, and improve our Services, content
              and advertising, and for loss prevention and anti-fraud purposes. We may use this
              information in the following ways:
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">3.1 To Maintain Legal and Regulatory Compliance</div>
            <div className="docdetail">
              Some of our Services are subject to laws and regulations requiring us to collect and
              use your personal identification information, formal identification information,
              financial information, transaction information, employment information, online
              identifiers, and/or usage data in certain ways.
              <br />
              We must identify and verify customers using our Services in order to comply with
              anti-money laundering and terrorist financing laws across jurisdictions. In addition,
              we use third parties to verify your identity by comparing the personal information you
              provided against third-party databases and public records.
              <br />
              We may require you to provide additional information which we may use in collaboration
              with service providers acting on our behalf to verify your identity or address, and/or
              to manage risk as required under applicable law. If you do not want to have your
              personal information processed for such purposes, then we shall terminate your account
              as we cannot perform the Services in accordance with legal and regulatory
              requirements.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">
              3.2 To Enforce Our Terms in Our User Agreement and Other Agreements
            </div>
            <div className="docdetail">
              We handle sensitive information, such as your identification and financial data, so it
              is very important for us and our customers that we are actively monitoring,
              investigating, preventing and mitigating any potentially prohibited or illegal
              activities, enforcing our agreements with third parties, and/or violations of our
              posted user agreement or agreement for other Services. In addition, we may need to
              collect fees based on your use of our Services. We collect information about your
              account usage and closely monitor your interactions with our Services. We may use any
              of your personal information collected on our Services for these purposes. The
              consequences of not processing your personal information for such purposes is the
              termination of your account as we cannot perform our Services in accordance with our
              terms.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">3.3 To Provide 3Blocks Services</div>
            <div className="docdetail">
              We process your personal information in order to provide the Services to you.
              <br />
              We cannot provide you with Services without such information.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">3.4 To Provide Service Communications</div>
            <div className="docdetail">
              We send administrative or account-related information to you to keep you updated about
              our Services, inform you of relevant security issues or updates, or provide other
              transaction-related information. Without such communications, you may not be aware of
              important developments relating to your account that may affect how you can use our
              Services.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">3.5 To Provide Customer Service</div>
            <div className="docdetail">
              We process your personal information when you contact us to resolve any question,
              dispute, collected fees, or to troubleshoot problems. We may process your information
              in response to another customer’s request, as relevant. Without processing your
              personal information for such purposes, we cannot respond to your requests and ensure
              your uninterrupted use of the Services.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">3.6 To Ensure Quality Control</div>
            <div className="docdetail">
              We process your personal information for quality control and staff training to make
              sure we continue to provide you with accurate information. If we do not process
              personal information for quality control purposes, you may experience issues on the
              Services such as inaccurate transaction records or other interruptions. Our basis for
              such processing is based on the necessity of performing our contractual obligations
              with you.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">3.7 To Ensure Network and Information Security</div>
            <div className="docdetail">
              We process your personal information in order to enhance security, monitor and verify
              identity or service access, combat spam or other malware or security risks and to
              comply with applicable security laws and regulations. The threat landscape on the
              internet is constantly evolving, which makes it more important than ever that we have
              accurate and up-to-date information about your use of our Services. Without processing
              your personal information, we may not be able to ensure the security of our Services.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">3.8 For Research and Development Purposes</div>
            <div className="docdetail">
              We process your personal information to better understand the way you use and interact
              with our Services. In addition, we use such information to customize, measure, and
              improve the Services and the content and layout of our website and applications, and
              to develop new services. Without such processing, we cannot ensure your continued
              enjoyment of our Services. Our basis for such processing is based on legitimate
              interest.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">3.9 To Enhance Your Website Experience</div>
            <div className="docdetail">
              We process your personal information to provide a personalized experience and
              implement the preferences you request. For example, you may choose to provide us with
              access to certain personal information stored by third parties. Without such
              processing, we may not be able to ensure your continued enjoyment of part or all of
              our Services.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">
              3.10 To Facilitate Corporate Acquisitions, Mergers, or Transactions
            </div>
            <div className="docdetail">
              We may process any information regarding your account and use of our Services as is
              necessary in the context of corporate acquisitions, mergers, or other corporate
              transactions. You have the option of closing your account if you do not wish to have
              your personal information processed for such purposes.
              <br />
              EEA residents: pursuant to EEA Data Protection Law, we process this personal
              information to satisfy our legitimate interests as described above.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">3.11 To Engage in Marketing Activities</div>
            <div className="docdetail">
              Based on your communication preferences, we may send you marketing communications to
              inform you about our events or our partner events; to deliver targeted marketing; and
              to provide you with promotional offers based on your communication preferences. We use
              information about your usage of our Services and your contact information to provide
              marketing communications. You can opt-out of our marketing communications at any time.
              <br />
              If you are a current customer residing in the EEA, we will only contact you by
              electronic means (email or SMS) with information about our Services that are similar
              to those which were the subject of a previous sale or negotiations of a sale to you.
              <br />
              If you are a new customer and located in the EEA, we will contact you if you are
              located in the EU by electronic means for marketing purposes only if you have
              consented to such communication. You may opt-out of further processing for purposes of
              direct marketing, at any time and free of charge (such as New Letters).
              <br />
              We will not use your personal information for purposes other than those purposes we
              have disclosed to you, without your permission. From time to time we may request your
              permission to allow us to share your personal information with third parties. You may
              opt out of having your personal information shared with third parties or allowing us
              to use your personal information for any purpose that is incompatible with the
              purposes for which we originally collected it or subsequently obtained your
              authorization. If you choose to so limit the use of your personal information, certain
              features or our Services may not be available to you.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">
              4. Cookies – Collection & Use of Data Collected Automatically
            </div>
            <div className="docdetail">
              Cookies are small files that a site or its service provider transfers to your
              computer’s hard drive through your web browser (if you have permitted) that enables
              the site’s or service provider’s systems to recognize your browser and capture and
              remember certain information. They are widely used in order to make websites work, or
              work more efficiently, as well as to provide information to the owners of the site.
              <br />
              Cookies are stored on the hard drive of your computer and do not necessarily transmit
              your Personal Data to 3Blocks. Cookies are used to help 3Blocks understand your
              preferences based on previous or current site activity, which enables 3Blocks to
              provide you with improved services.
              <br />
              Cookies are also used for security purposes and to compile aggregate Data about site
              traffic and site interaction so that better site experiences and tools can be offered
              in the future.
              <br />
              3Blocks only uses “First-Party” cookies that interact directly between our Services
              and your computer. 3Blocks does NOT track your usage across applications. 3Blocks may
              also use trusted third-party service providers that track this information on
              3Blocks’s behalf. Our service provider(s) will place cookies on the hard drive of your
              computer and will receive data that we select to educate us notably on:
              <ul>
                <li>How visitors navigate around our Site</li>
                <li>What products are browsed</li>
                <li>General transaction information</li>
              </ul>
              Our service provider(s) analyses this data and provide(s) us with aggregate reports.
              The data and analysis provided by our service provider(s) will be used to assist us in
              better understanding our visitors’ interests in our Site and how to better serve those
              interests. The data collected by our service provider(s) may be linked to and combined
              with data that we collect about you while you are using the Platform. Our service
              provider(s) is/are contractually restricted from using information they receive from
              our Site other than to assist us.
              <br />
              You can choose to have your computer warn you (through your browser settings) each
              time a cookie is being sent, or you can choose to turn off all cookies. If you choose
              to turn cookies off, some of the features that make your site experience more
              efficient may not function properly.
              <br />
              For the purposes of continuous optimization of the Site, 3Blocks uses the web analysis
              service of “Google Analytics”. Google Analytics is a web analytics service offered by
              Google Inc., a company of the holding company Alphabet Inc., in the USA, that tracks
              and reports website traffic. The data collected is processed in a
              non-personally-identifying form (IP anonymization). Google Inc. discloses this
              information only to third parties to the extent required by law. Google uses the Data
              collected to track and monitor the use of the 3Blocks’ s website. This Data is shared
              with other Google services. Google may use the collected Data to contextualize and
              personalize the ads of its own advertising network.
              <br />
              You can opt-out of having made your activity on the 3Blocks Site available to Google
              Analytics by installing the Google Analytics opt-out browser add-on. The add-on
              prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing
              information with Google Analytics about visits activity.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">5. Disclosing and Transferring Personal Data</div>
            <div className="docdetail">
              We may disclose your Personal Data to third parties and legal and regulatory
              authorities, and transfer your Personal Data outside the EEA, as described below.
              <br />
              There are certain circumstances where we may transfer your personal data to employees,
              contractors and to other parties.
              <br />
              We may share information about you with other members of our group of companies, so we
              can provide the best service across our group. They are bound to keep your information
              in accordance with this Privacy Policy;
              <br />
              We may also share your information with certain contractors or service providers. They
              may process your personal data for us, for example, if we use a marketing agency.
              Other recipients/service providers include advertising agencies, IT specialists,
              database providers, backup and disaster recovery specialists, email providers or
              outsourced call centers. Our suppliers and service providers will be required to meet
              our standards on processing information and security. The information we provide them,
              including your information, will only be provided in connection with the performance
              of their function;
              <br />
              We may also share your information with certain other third parties. We will do this
              either when we receive your consent or because we need them to see your information to
              provide products or services to you. These include credit reference agencies,
              anti-fraud databases, screening agencies and other partners we do business with.
              <br />
              Your personal data may be transferred to other third-party organizations in certain
              scenarios:
              <ul>
                <li>
                  If we’re discussing selling or transferring part or all of our business – the
                  information may be transferred to prospective purchasers under suitable terms as
                  to confidentiality;
                </li>
                <li>
                  If we are reorganized or sold, information may be transferred to a buyer who can
                  continue to provide services to you;
                </li>
                <li>
                  If we’re required to by law, or under any regulatory code or practice we follow,
                  or if we are asked by any public or regulatory authority;
                </li>
                <li>
                  If we are defending a legal claim your information may be transferred as required
                  in connection with defending such claim.
                </li>
              </ul>
              Your personal data may be shared if it is made anonymous and aggregated, as in such
              circumstances the information will cease to be personal data.
              <br />
              Your information will not be sold, exchanged, or shared with any third parties without
              your consent, except to provide 3Blocks Services or as required by law.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">
              5.1 How Personal Information is Shared With Third Party Sites and Services
            </div>
            <div className="docdetail">
              Please note that merchants you interact with may have their own privacy policies.
              Hence 3Blocks is not responsible for their operations, including, but not limited to,
              their information practices.
              <br />
              Information collected by third parties, which may include such things as contact
              details or location data, is governed by their privacy practices. We encourage you to
              learn about the privacy policies of those third parties.
              <br />
              If you authorize one or more third-party applications to access your 3Blocks Account,
              then information you have provided to 3Blocks may be shared with those third parties.
              Unless you provide further authorization, these third parties are not allowed to use
              this information for any purpose other than to facilitate your transactions using
              3Blocks Services.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">6. Transfer of Data</div>
            <div className="docdetail">
              We store and process your Personal Data in data centers around the world, wherever we
              have our premises or service providers are located.
              <br />
              As such, we may transfer your Personal Data outside of the United States of America.
              Some of the countries to which your personal data may be transferred do not benefit
              from an appropriate protection regulation.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">7. Security of Data</div>
            <div className="docdetail">
              We take all reasonable effort on technical and organizational security measures to
              protect your Data from being manipulated, lost or accessed by unauthorized
              third-parties.
              <br />
              Our website is scanned on a regular basis for security holes and known vulnerabilities
              in order to make your visit to our Site as safe as possible.
              <br />
              Your Personal Data is contained behind secured networks and is only accessible by a
              limited number of individuals who have special access rights to such systems and are
              required to keep the information confidential.
              <br />
              Although no method of transmission over the Internet, or method of electronic storage
              is one hundred percent secure, we strive to continually update and improve our
              security measures with the most recent technological developments.
              <br />
              We would like to draw your attention on the fact that we normally never ask for
              financial or payment information, such as your credit card number, passcode, account
              number or pin number, in an e-mail, text or any other communication that we send to
              you. Please always check that any website on which you are asked for financial or
              payment information in relation to our reservations or services is operated by
              3Blocks. The risk of impersonating hackers exists and should be taken into account
              when using our website and/or Services.
              <br />
              If you do receive a suspicious request, do not provide your information and report it
              by contacting one of our member service representatives as set in this Privacy Policy.
              <br />
              Since we cannot 100% guarantee that loss, misuse, unauthorized acquisition, or
              alteration of your data will not occur, please accept that you play a vital role in
              protecting your own Personal Data. When registering with us, it is important to choose
              an appropriate password of sufficient length and complexity, to not reveal this
              password to any third-parties, and to immediately notify us if you become aware of any
              unauthorized access to or use of your account.
              <br />
              Furthermore, we cannot ensure or warrant the security or confidentiality of
              information you transmit to us or receive from us by Internet or wireless connection,
              including email, phone, or SMS, since we have no way of protecting that information
              once it leaves and until it reaches us. If you have reason to believe that your data
              is no longer secure, please contact us at the email address, mailing address or
              telephone number listed at the end of this Privacy Policy.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">8. Retention of Data</div>
            <div className="doclist">8.1 Personal Data</div>
            <div className="docdetail">
              3Blocks will retain your Personal Data only for as long as is necessary for the
              purposes set out in this Privacy Policy.
              <br />
              3Blocks will retain and use your Personal Data to the extent necessary to comply with
              our legal obligations (for example, if we are required to retain your Data to comply
              with applicable laws), resolve disputes, and enforce our legal agreements and
              policies.
              <br />
              Therefore, and in accordance with our record keeping obligations, we will retain
              Account and other Personal Data for at least five years (and some up to ten years, as
              required by applicable law) after an Account is closed.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">8.2 Data Collected Automatically</div>
            <div className="docdetail">
              3Blocks will also retain Data Collected Automatically either generated by the use of
              the website or from the website infrastructure itself (for example, the duration of a
              page visit) for internal analysis purposes.
              <br />
              This Data is generally retained for a shorter period of time, except when this Data is
              used to strengthen the security or to improve the functionality of our Service, or we
              are legally obligated to retain this Data for longer time periods.
              <br />
              Hence, this kind of Data collected via technical means such as cookies, webpage
              counters and other analytics tools is normally kept for a period of up to one year
              from expiry of the cookie.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">9. Disclosures Due to Legal Compliance</div>
            <div className="docdetail">
              3Blocks complies with all applicable privacy laws and regulations.
              <br />
              3Blocks may be compelled to surrender personal user or customer information to legal
              authorities without express user consent if presented with a court order or similar
              legal or administrative order, or as required or permitted by the laws, rules, and
              regulations of any nation, state, or other applicable jurisdiction.
              <br />
              Also, in the event of a violation of the Terms & Conditions of use of the Site or a
              violation of any restrictions on use of materials provided in or through the Site, we
              may disclose personal user information to our affected business partners or legal
              authorities.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">10. Your Rights</div>
            <div className="doclist">10.1 General</div>
            <div className="docdetail">
              3Blocks is the Data Controller with respect to your Personal Data. We determine the
              means and purposes of processing you Data.
              <br />
              We may process your Personal Data if you consent to the processing, to satisfy our
              legal obligations, if it is necessary to carry out our obligations arising from any
              contracts we entered with you, or to take steps at your request prior to entering into
              a contract with you, or for our legitimate interests to protect our property, rights
              or safety of 3Blocks, our customers or others.
              <br />
              Whenever made possible, you can update your Personal Data directly within your account
              settings section. If you are unable to change your Personal Data, please contact us to
              make the required changes.
              <br />
              Should you have any question or wish you exercise your Rights, please contact our Data
              Protection Officer by writing an email at admin@3Blocks.io so that we may consider
              your request under applicable law.
              <br />
              We reserve our right to verify your identity before responding to such requests.
              <br />
              Please note that according to US law, specific Data must be stored for a determined
              period of time. Such Data must be therefore remained with 3Blocks until the legal
              period has expired. These specific Data are blocked in our system and used only in
              order to meet legal requirements.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">10.2 Individual Rights in Relation With Your Data</div>
            <div className="doclist">10.2.1 Right to Access Your Personal Data</div>
            <div className="docdetail">
              You are entitled to ask 3Blocks if we are processing your Data. In case the answer is
              positive, you can request that we provide you a copy of your Personal Data we hold. We
              may require you to specify the information or processing activities to which your
              request relates.
              <br />
              Your right to access shall only be limited where the burden or expense of providing
              access would be disproportionate to the risks to your privacy in the case in question,
              or where the rights of persons other than you would be violated.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">10.2.2 Right to Correct Your Personal Data</div>
            <div className="docdetail">
              You are entitled to request that any incomplete or inaccurate Personal Data we hold
              about you is corrected or updated.
              <br />
              Your right to access and correction shall only be limited where the burden or expense
              of providing access would be disproportionate to the risks to your privacy in the case
              in question, or where the rights of persons other than you would be violated.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">10.2.3 Right to Withdraw Your Consent</div>
            <div className="docdetail">
              You have the right to withdraw your consent to the processing of your Personal Data
              collected on the basis of your consent at any time. Your withdrawal will not affect
              the lawfulness of previous processing based on your consent before the withdrawal.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">10.2.4 Right to Erasure of Your Personal Data</div>
            <div className="docdetail">
              You are entitled to ask us to delete or remove personal data in certain circumstances.
              There are also certain exceptions where we may refuse a request for erasure, for
              example, where the personal data is required for compliance with law or in connection
              with claims.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">10.2.5 Right to Data Portability</div>
            <div className="docdetail">
              If we process your Personal Data based on a contract with you or based on your
              consent, or the processing is carried out by automated means, you may request to
              receive your Personal Data in a structured, commonly used and machine-readable format,
              and to have us transfer your Personal Data directly to another “controller” (natural
              or legal person that determines the purposes and means of the processing of your
              Personal Data), where technically feasible, unless exercise of this right adversely
              affects the rights and freedoms of others.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">10.2.6 Right to Restriction of Processing</div>
            <div className="docdetail">
              You have the right to restrict or object to us processing your Personal Data where one
              of the following applies:
              <br />
              You contest the accuracy of your Personal Data that we processed. In such instances,
              we will restrict processing during the period necessary for us to verify the accuracy
              of your Personal Data.
              <br />
              The processing is unlawful, and you oppose the erasure of your Personal Data and
              request the restriction of its use instead.
              <br />
              We no longer need your Personal Data for the purposes of the processing, but it is
              required by you to establish, exercise or defense of legal claims.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">10.2.7 Right to Objection of Processing</div>
            <div className="docdetail">
              Where we are processing your personal data based on consent or legitimate interests
              (or those of a third party) you may object at any time to the processing of your
              Personal Data. However, we may be entitled to continue processing your Personal
              Information based on our legitimate interests or where this is relevant to the defense
              of legal claims.
              <br />
              You also have the right to object where we are processing your personal data for
              direct marketing purposes.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">10.2.8 Automated Decisions</div>
            <div className="docdetail">
              You may contest any automated decision made about you where this has a legal or
              similar significant effect and ask for it to be reconsidered.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">10.3 Complaint</div>
            <div className="docdetail">
              If you believe that we have infringed your rights, we encourage you to contact us
              first at admin@3Blocks.io so that we can try to resolve the issue informally.
              <br />
              You also have a right to lodge a complaint with a supervisory authority, either where
              we are based, or where an alleged infringement of Data Protection law has taken place.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">11. Children’s Personal Data</div>
            <div className="docdetail">
              We do not knowingly request to collect Personal Data from any person under the age of
              18.
              <br />
              If a user submitting Personal Data is suspected of being younger than 18 years of age,
              3Blocks will require the user to close his or her account.
              <br />
              We will also take all necessary steps to delete his or her Data as soon as possible.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">12. Links to Other Websites</div>
            <div className="docdetail">
              3Blocks’s website may contain links to other sites that are not operated by 3Blocks.
              If you click on a third-party link, you will be directed to that third party’s site.
              <br />
              3Blocks strongly advises you to review the privacy policy of every site you visit.
              <br />
              3Blocks has no control over and assumes no responsibility for the content, privacy
              policies or practices of any third-party sites or services.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">13. Changes to this Privacy Policy</div>
            <div className="docdetail">
              3Blocks may update the Privacy Policy from time to time.
              <br />
              You are advised to review this Privacy Policy periodically for any changes. Changes to
              this Privacy Policy are effective when they are posted on this page.
            </div>
          </div>
          <div className="docblock">
            <div className="doclist">14. Contact Us</div>
            <div className="docdetail">
              If you have questions or concerns regarding this Privacy Policy, or if you have a
              complaint, you should first contact us at: admin@3Blocks.io.
            </div>
          </div>
        </div>
      </div>
      <FooterLandingPage />
    </HomePageLayout>
  )
}
