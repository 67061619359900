import { useEffect, useRef, useState } from 'preact/hooks'
import useSdkConfigurationService from '~core/SdkConfiguration/useSdkConfigurationService'
import { performanceBenchmark } from './performanceBenchmark'

const usePerformanceBenchmark = (): boolean | undefined => {
  const { experimental_features } = useSdkConfigurationService()
  const [duration, setDuration] = useState<number | undefined>(undefined)
  const triggeredRef = useRef(false)

  const shouldRunBenchmark =
    experimental_features?.performance_benchmark?.enabled ?? false

  useEffect(() => {
    if (shouldRunBenchmark && !triggeredRef.current) {
      triggeredRef.current = true
      performanceBenchmark().then((duration) => setDuration(duration))
    }
  }, [shouldRunBenchmark])

  const threshold = experimental_features?.performance_benchmark?.threshold

  return duration !== undefined && threshold !== undefined
    ? duration <= threshold
    : undefined
}

export default usePerformanceBenchmark
