// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-ActiveVideo-Button-button{padding:11px 23px;border:none;background-color:rgba(var(--ods-color-background-action));color:rgba(var(--ods-color-content-on-action));border-radius:var(--ods-border-radius-medium);font-family:inherit;font-size:1rem;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-appearance:none;-moz-appearance:none;appearance:none;border:1px solid rgba(0,0,0,0);-webkit-box-sizing:border-box;box-sizing:border-box;position:relative;text-decoration:none;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;min-width:272px;min-height:48px;cursor:pointer;outline:none}.onfido-sdk-ui-ActiveVideo-Button-button:hover{background-color:rgba(var(--ods-color-background-action-hover))}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "onfido-sdk-ui-ActiveVideo-Button-button"
};
export default ___CSS_LOADER_EXPORT___;
