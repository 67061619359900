import * as React from "react";

export const Ban = ({ size = 21, color = "#ffffff", ...props }) => (
<svg
width={size}
height={size}
fill={color}
viewBox="0 0 24 24"
xmlns="http://www.w3.org/2000/svg"
{...props}
>
<path
fillRule="evenodd"
d="M16.172 17.868a7.2 7.2 0 0 1-10.04-10.04l10.04 10.042v-.002Zm1.697-1.697L7.83 6.132a7.2 7.2 0 0 1 10.04 10.04ZM21.6 12a9.6 9.6 0 1 1-19.2 0 9.6 9.6 0 0 1 19.2 0Z"
clipRule="evenodd"
/>
</svg>
);





export const ArrowRight = ({ size = 21, color = "#ffffff", ...props }) => (
<svg
width={size}
height={size}
fill={color}
viewBox="0 0 24 24"
xmlns="http://www.w3.org/2000/svg"
{...props}
>
<path
fillRule="evenodd"
d="M12 21.6a9.6 9.6 0 1 0 0-19.2 9.6 9.6 0 0 0 0 19.2Zm4.448-10.448-3.6-3.6a1.2 1.2 0 0 0-1.696 1.696l1.551 1.552H8.4a1.2 1.2 0 1 0 0 2.4h4.303l-1.551 1.552a1.2 1.2 0 1 0 1.696 1.696l3.6-3.6a1.2 1.2 0 0 0 0-1.696Z"
clipRule="evenodd"
/>
</svg>
);




export const ArrowUp = ({ size = 21, color = "#ffffff", ...props }) => (
<svg
width={size}
height={size}
fill={color}
viewBox="0 0 24 24"
xmlns="http://www.w3.org/2000/svg"
{...props}
>
<path
fillRule="evenodd"
d="M12 21.6a9.6 9.6 0 1 0 0-19.2 9.6 9.6 0 0 0 0 19.2Zm4.448-10.448-3.6-3.6a1.2 1.2 0 0 0-1.696 0l-3.6 3.6a1.2 1.2 0 0 0 1.696 1.696l1.552-1.551V15.6a1.2 1.2 0 1 0 2.4 0v-4.303l1.552 1.551a1.2 1.2 0 0 0 1.696-1.696Z"
clipRule="evenodd"
/>
</svg>
);



export const ArrowDown = ({ size = 21, strokeWidth = 1, color = "#ffffff", ...props }) => (
<svg
width={size}
height={size}
fill="none"
stroke={color}
strokeLinecap="round"
strokeLinejoin="round"
strokeWidth={strokeWidth}
viewBox="0 0 24 24"
xmlns="http://www.w3.org/2000/svg"
{...props}
>
<path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
<path d="m8 12 4 4 4-4" />
<path d="M12 8v8" />
</svg>
);


export const ArrowRightAlt = ({ size = 21, strokeWidth = 1, color = "#ffffff", ...props }) => (
<svg
width={size}
height={size}
fill="none"
stroke={color}
strokeLinecap="round"
strokeLinejoin="round"
strokeWidth={strokeWidth}
viewBox="0 0 24 24"
xmlns="http://www.w3.org/2000/svg"
{...props}
>
<path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
<path d="m12 16 4-4-4-4" />
<path d="M8 12h8" />
</svg>
);


export const ArrowUpAlt = ({ size = 21, strokeWidth = 1, color = "#ffffff", ...props }) => (
<svg
width={size}
height={size}
fill="none"
stroke={color}
strokeLinecap="round"
strokeLinejoin="round"
strokeWidth={strokeWidth}
viewBox="0 0 24 24"
xmlns="http://www.w3.org/2000/svg"
{...props}
>
<path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
<path d="m16 12-4-4-4 4" />
<path d="M12 16V8" />
</svg>
);

export const SignInIcon = ({ size = 21, color = "#ffffff", ...props }) => (
<svg
width={size}
height={size}
fill={color}
viewBox="0 0 24 24"
xmlns="http://www.w3.org/2000/svg"
{...props}
>
<path
fillRule="evenodd"
d="m16.53 5.655.015-.015 1.485 1.485V3.75L17.28 3H3.78l-.765.75v.74L3 4.5v15.435l.54.69 7.5 2.58.96-.705V21h5.28l.75-.75v-3.375l-1.5 1.5V19.5H12V7.065l-.495-.69L6.054 4.5H16.53v1.155ZM10.5 21.42l-6-2.01V5.58l6 2.01v13.83Zm4.635-10.125h7.455v1.5h-7.395l2.385 2.4-1.065 1.05-3.705-3.69V11.49l3.735-3.72 1.05 1.05-2.46 2.475Z"
clipRule="evenodd"
/>
</svg>
);



export const House = ({ size = 21, color = "#ffffff", ...props }) => (
<svg
width={size}
height={size}
fill={color}
viewBox="0 0 24 24"
xmlns="http://www.w3.org/2000/svg"
{...props}
>
<path
fillRule="evenodd"
d="m12.54 2.055 9.54 8.7-1.065 1.065-1.515-1.374v9.789l-.75.75h-4.5l-.75-.75v-5.25h-3v5.25l-.75.75h-4.5l-.75-.75v-9.777L3 11.82l-1.065-1.065 9.525-8.7h1.08ZM6 9.095v10.39h3v-5.25l.75-.75h4.5l.75.75v5.25h3v-10.4l-6-5.44-6 5.45Z"
clipRule="evenodd"
/>
</svg>
);



export const Account = ({ size = 21, color = "#ffffff", ...props }) => (
<svg
width={size}
height={size}
fill={color}
viewBox="0 0 24 24"
xmlns="http://www.w3.org/2000/svg"
{...props}
>
<path d="M24 11.988C24 5.37 18.624 0 12 0S0 5.37 0 11.988c0 3.645 1.656 6.93 4.248 9.135.024.024.048.024.048.048.216.168.432.336.672.504.12.072.216.166.336.262A11.97 11.97 0 0 0 12.024 24a11.97 11.97 0 0 0 6.72-2.063c.12-.072.216-.166.336-.24.216-.166.456-.334.672-.502.024-.024.048-.024.048-.048 2.544-2.23 4.2-5.514 4.2-9.159ZM12 22.49c-2.256 0-4.32-.72-6.024-1.919.024-.192.072-.383.12-.575.143-.52.353-1.02.624-1.486.264-.456.576-.864.96-1.224a6.74 6.74 0 0 1 1.224-.959 6.057 6.057 0 0 1 1.464-.6A6.224 6.224 0 0 1 12 15.513a6.278 6.278 0 0 1 4.392 1.749 6.01 6.01 0 0 1 1.296 1.942c.168.433.288.889.36 1.367A10.545 10.545 0 0 1 12 22.489Zm-3.672-11.1a3.735 3.735 0 0 1-.312-1.537c0-.526.096-1.054.312-1.534s.504-.91.864-1.27.792-.647 1.272-.863A3.743 3.743 0 0 1 12 5.874c.552 0 1.056.096 1.536.312s.912.504 1.272.862c.36.36.648.792.864 1.271a3.74 3.74 0 0 1 .312 1.534c0 .553-.096 1.056-.312 1.535a4.26 4.26 0 0 1-.864 1.272 4.26 4.26 0 0 1-1.272.863 4.072 4.072 0 0 1-3.096 0 4.26 4.26 0 0 1-1.272-.863 3.79 3.79 0 0 1-.84-1.272v.002Zm11.136 7.959c0-.049-.024-.073-.024-.12a7.83 7.83 0 0 0-1.032-2.11 7.325 7.325 0 0 0-1.632-1.702 7.813 7.813 0 0 0-1.56-.912c.252-.166.485-.359.696-.575a6.31 6.31 0 0 0 .936-1.175 5.437 5.437 0 0 0 .792-2.902 5.566 5.566 0 0 0-.432-2.204 5.698 5.698 0 0 0-1.224-1.799 5.767 5.767 0 0 0-1.8-1.2 5.58 5.58 0 0 0-2.208-.43 5.58 5.58 0 0 0-2.208.432 5.446 5.446 0 0 0-1.8 1.222 5.76 5.76 0 0 0-1.2 1.799 5.565 5.565 0 0 0-.432 2.204c0 .528.072 1.032.216 1.511.144.504.336.96.6 1.39.24.432.576.816.936 1.176.216.216.456.407.72.575a7.68 7.68 0 0 0-1.56.936 8.314 8.314 0 0 0-1.632 1.678 7.498 7.498 0 0 0-1.032 2.11c-.024.047-.024.096-.024.12-1.896-1.919-3.072-4.508-3.072-7.385C1.488 6.21 6.216 1.486 12 1.486c5.784 0 10.512 4.724 10.512 10.502a10.44 10.44 0 0 1-3.048 7.36Z" />
</svg>
);


export const Wallet = ({ size = 21, strokeWidth = 1, color = "#ffffff", ...props }) => (
<svg
width={size}
height={size}
fill="none"
stroke={color}
strokeLinecap="round"
strokeLinejoin="round"
strokeWidth={strokeWidth}
viewBox="0 0 24 24"
xmlns="http://www.w3.org/2000/svg"
{...props}
>
<path d="M2 6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6z" />
<path d="M2 8h20" />
<path d="M2 12h7c0 1 .6 3 3 3s3-2 3-3h7" />
</svg>
);


export const Property = ({ size = 21, color = "#ffffff", ...props }) => (
<svg
width={size}
height={size}
fill={color}
viewBox="0 0 24 24"
xmlns="http://www.w3.org/2000/svg"
{...props}
>
<path d="M18.99 2H9.01C7.899 2 7 2.899 7 4.01v5.637l-4.702 4.642A1 1 0 0 0 3 16v5a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V4.009C21 2.899 20.102 2 18.99 2Zm-8.068 13.111V20H5v-5.568l2.987-2.949 2.935 3.003v.625ZM13 9h-2V7h2v2Zm4 8h-2v-2h2v2Zm0-4h-2v-2h2v2Zm0-4h-2V7h2v2Z" />
<path d="M7 15h2v2H7v-2Z" />
</svg>
);



export const Documents = ({ size = 21, color = "#ffffff", ...props }) => (
<svg
width={size}
height={size}
fill={color}
viewBox="0 0 24 24"
xmlns="http://www.w3.org/2000/svg"
{...props}
>
<path d="m16.5 20.388-1.943-1.943-1.057 1.058 3 3 6-6-1.058-1.058-4.942 4.943Z" />
<path d="M11.25 21H6V3h6v4.5A1.504 1.504 0 0 0 13.5 9H18v4.5h1.5v-6a.68.68 0 0 0-.225-.525l-5.25-5.25A.682.682 0 0 0 13.5 1.5H6A1.504 1.504 0 0 0 4.5 3v18A1.504 1.504 0 0 0 6 22.5h5.25V21ZM13.5 3.3l4.2 4.2h-4.2V3.3Z" />
</svg>
);


export const Coin = ({ size = 21, strokeWidth = 1, color = "#ffffff", ...props }) => (
<svg
width={size}
height={size}
fill="none"
stroke={color}
strokeLinecap="round"
strokeLinejoin="round"
strokeWidth={strokeWidth}
viewBox="0 0 24 24"
xmlns="http://www.w3.org/2000/svg"
{...props}
>
<path
d="M4 10.857C4 9.372 7.582 7.43 12 7.43s8 1.943 8 3.428v3.429c0 1.485-3.582 3.428-8 3.428s-8-1.943-8-3.428v-3.429Z"
clipRule="evenodd"
/>
<path
d="M12 14.267c4.418 0 8-1.835 8-3.412 0-1.578-3.582-3.426-8-3.426s-8 1.848-8 3.425c0 1.579 3.582 3.413 8 3.413Z"
clipRule="evenodd"
/>
</svg>
);



export const Money = ({ size = 21, strokeWidth = 1, color = "#ffffff", ...props }) => (
<svg
width={size}
height={size}
fill="none"
stroke={color}
strokeLinecap="round"
strokeLinejoin="round"
strokeWidth={strokeWidth}
viewBox="0 0 24 24"
xmlns="http://www.w3.org/2000/svg"
{...props}
>
<path d="M2 6.5h20v12H2v-12Z" />
<path d="M2 10.5a4 4 0 0 0 4-4H2v4Z" clipRule="evenodd" />
<path d="M2 14.5a4 4 0 0 1 4 4H2v-4Z" clipRule="evenodd" />
<path d="M22 14.5v4h-4a4 4 0 0 1 4-4Z" clipRule="evenodd" />
<path d="M22 10.5a4 4 0 0 1-4-4h4v4Z" clipRule="evenodd" />
<path d="M12 15.5c1.38 0 2.5-1.343 2.5-3s-1.12-3-2.5-3-2.5 1.343-2.5 3 1.12 3 2.5 3Z" />
</svg>
);




export const Bank = ({ size = 21, strokeWidth = 1, color = "#ffffff", ...props }) => (
<svg
width={size}
height={size}
fill="none"
stroke={color}
strokeLinecap="round"
strokeLinejoin="round"
strokeWidth={strokeWidth}
viewBox="0 0 24 24"
xmlns="http://www.w3.org/2000/svg"
{...props}
>
<path d="M5 8.5V22h14V8.5" />
<path d="M2.5 11 5 8.5 12 2l7 6.5 2.5 2.5" />
<path d="m9.5 9.5 2.5 3 2.5-3" />
<path d="M9 15.5h6" />
<path d="M9 12.5h6" />
<path d="M12 12.5v6" />
</svg>
);




export const Shield = ({ size = 21, color = "#ffffff", ...props }) => (
<svg
width={size}
height={size}
fill={color}
viewBox="0 0 24 24"
xmlns="http://www.w3.org/2000/svg"
{...props}
>
<path
fillRule="evenodd"
d="M2.6 5.999A14.345 14.345 0 0 0 12 2.333 14.345 14.345 0 0 0 21.4 6c.133.78.2 1.584.2 2.401 0 6.27-4.008 11.604-9.6 13.58C6.408 20.005 2.4 14.67 2.4 8.4c0-.818.068-1.62.2-2.401Zm13.848 4.45a1.2 1.2 0 0 0-1.696-1.697L10.8 12.703l-1.552-1.551a1.2 1.2 0 0 0-1.696 1.696l2.4 2.4a1.2 1.2 0 0 0 1.696 0l4.8-4.8Z"
clipRule="evenodd"
/>
</svg>
);




export const Legal = ({ size = 21, strokeWidth = 1, color = "#ffffff", ...props }) => (
<svg
width={size}
height={size}
fill="none"
stroke={color}
strokeLinecap="round"
strokeLinejoin="round"
strokeWidth={strokeWidth}
viewBox="0 0 24 24"
xmlns="http://www.w3.org/2000/svg"
{...props}
>
<path d="m3 6 3 1m0 0-3 9a5.002 5.002 0 0 0 6.001 0L6 7Zm0 0 3 9M6 7l6-2m0 0 6 2m-6-2V3m0 2v16m6-14 3-1m-3 1-3 9a5.002 5.002 0 0 0 6.001 0L18 7Zm-6 14H9m3 0h3" />
</svg>
);


export const DoubleCash = ({ size = 21, color = "#ffffff", ...props }) => (
<svg
width={size}
height={size}
fill={color}
viewBox="0 0 24 24"
xmlns="http://www.w3.org/2000/svg"
{...props}
>
<path
fillRule="evenodd"
d="M4.8 4.8a2.4 2.4 0 0 0-2.4 2.4V12a2.4 2.4 0 0 0 2.4 2.4V7.2h12a2.4 2.4 0 0 0-2.4-2.4H4.8ZM7.2 12a2.4 2.4 0 0 1 2.4-2.4h9.6a2.4 2.4 0 0 1 2.4 2.4v4.8a2.4 2.4 0 0 1-2.4 2.4H9.6a2.4 2.4 0 0 1-2.4-2.4V12Zm7.2 4.8a2.4 2.4 0 1 0 0-4.8 2.4 2.4 0 0 0 0 4.8Z"
clipRule="evenodd"
/>
</svg>
);




export const WalletAlt = ({ size = 21, color = "#ffffff", ...props }) => (
<svg
width={size}
height={size}
fill={color}
viewBox="0 0 24 24"
xmlns="http://www.w3.org/2000/svg"
{...props}
>
<path d="M20.5 7.28V5c0-1.1-.9-2-2-2h-14a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-2.28a2 2 0 0 0 1-1.72V9a2 2 0 0 0-1-1.72ZM19.5 9v6h-7V9h7Zm-15 10V5h14v2h-6c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h6v2h-14Z" />
<path d="M15.5 13.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" />
</svg>
);


export const Dollar = ({ size = 21, color = "#ffffff", ...props }) => (
<svg
width={size}
height={size}
fill={color}
viewBox="0 0 24 24"
xmlns="http://www.w3.org/2000/svg"
{...props}
>
<path d="M12.39 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4Z" />
</svg>
);



export const Map = ({ size = 21, strokeWidth = 1, color = "#ffffff", ...props }) => (
<svg
width={size}
height={size}
fill="none"
stroke={color}
strokeLinecap="round"
strokeLinejoin="round"
strokeWidth={strokeWidth}
viewBox="0 0 24 24"
xmlns="http://www.w3.org/2000/svg"
{...props}
>
<path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
<path d="M12 7a3 3 0 1 0 0 6 3 3 0 1 0 0-6z" />
</svg>
);


export const Contact = ({ size = 21, color = "#ffffff", ...props }) => (
<svg
width={size}
height={size}
fill={color}
viewBox="0 0 24 24"
xmlns="http://www.w3.org/2000/svg"
{...props}
>
<path d="M21 2H6a2 2 0 0 0-2 2v3H2v2h2v2H2v2h2v2H2v2h2v3a2 2 0 0 0 2 2h15a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1Zm-8 2.999c1.648 0 3 1.351 3 3A3.012 3.012 0 0 1 13 11c-1.647 0-3-1.353-3-3.001 0-1.649 1.353-3 3-3ZM19 18H7v-.75c0-2.219 2.705-4.5 6-4.5s6 2.281 6 4.5V18Z" />
</svg>
);

