import { FaMapMarkerAlt } from 'react-icons/fa'
import Banner from '../../sections/Banner'
import { currencyFormatter, toFixedTrunc } from 'src/utils/relisting'
import Skeleton from 'react-loading-skeleton'
import threeblocksre from '../../../assets/threeblocksre.svg'

export default function Card({ item, info, bg, propertyType }: any): JSX.Element {
  const stopEvent = (event: { stopPropagation: () => any }) => event.stopPropagation()

  const handleOpenYoutubeLink = () => {
    window.open(item?.metaLink)
  }

  return (
    <>
      <div>
        <div className={`re-card -${propertyType}`}>
          {item?.thumbnail ? (
            <div className={'imgbox'}>
              <Banner bg={bg} info={info} item={item} />

              <img className={`img -${bg}`} src={item?.thumbnail} alt="re_img" />
            </div>
          ) : (
            <div className={'imgbox'}>
              <Banner bg={bg} info={info} item={item} />
              <img className={`img -${bg}`} src={threeblocksre} alt="re_img" />
            </div>
          )}

          <div className="content">
            <div className="address">
              <FaMapMarkerAlt color="var(--main-80)" />
              <div className="address">{item && item.address ? item.address : <Skeleton />}</div>
              {item.isAvailable === false ? null : (
                <div className="youtube">
                  <object onClick={handleOpenYoutubeLink} type="owo/uwu">
                    <a
                      href={item?.metaLink}
                      onClick={stopEvent}
                      rel="noopener noreferrer"
                      target={'_blank'}
                    >
                      <div>
                        <small style={{ display: 'inline', margin: 0, padding: 0 }}>3D VIEW</small>
                      </div>
                    </a>
                  </object>
                </div>
              )}
            </div>
            <div className="tokens">
              <div>Listing Price</div>
              <div>
                {item && item.listingPrice ? currencyFormatter(item.listingPrice) : <Skeleton />}
              </div>
            </div>
            <div className="tokens">
              <div>Total Number of Tokens</div>
              <div>
                {item && item.totalNumberOfTokens ? item.totalNumberOfTokens : <Skeleton />}
              </div>
            </div>
            <div className="tokens">
              <div>Token Price</div>
              <div>
                {item && item.tokenPrice ? currencyFormatter(item.tokenPrice) : <Skeleton />}
              </div>
            </div>
            <div className="tokens">
              <div>Est. Rent Start Date</div>
              <div>{item && item.estimatedRentStartDate ? item.estimatedRentStartDate : 'NA'}</div>
            </div>

            <div className="yield">
              <div>Estimated Net ROI</div>
              <div>
                {item && item.expectedNetRoi ? (
                  `${toFixedTrunc(item.expectedNetRoi, 2)} %`
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
