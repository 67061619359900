import { createContext, useContext, useState } from 'react'

interface IReContext {
  reData: any
  setValues: any
}
const ReContext = createContext<IReContext | undefined>(undefined)

export const useRe = () => {
  const context = useContext(ReContext)
  if (!context) {
    throw new Error(`useREListing must be used within REListingProvider`)
  }
  return context
}

export const ReListingProvider = (props: any) => {
  const [reData, setData] = useState<string>('')

  const setValues = (values: any) => {
    setData(() => values)
  }

  return <ReContext.Provider value={{ reData, setValues }} {...props} />
}
