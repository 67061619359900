import { useQuery } from '@apollo/client'
import Card from '../ui/card/Card'
import { Link } from 'react-router-dom'
import { GET_RE_INFO_MKT } from 'src/client/queries/relisting'
import { skeletonData } from 'src/utils/constants'
import Skeleton from 'react-loading-skeleton'
import { useAuth } from 'src/context/useAuth'
import { sendEventToGA } from 'src/utils/googleAnalytics'
import { useRefCode } from 'src/context/useRefCode'
import { useEffect } from 'react'

interface IMarketplaceGridProps {
  variant?: string
}

const propertyType = 'coming'
export default function MarketplaceGrid({ variant }: IMarketplaceGridProps) {
  const { data: listData, loading: listLoading, error: listError } = useQuery(GET_RE_INFO_MKT)
  //@ts-ignore
  const { user } = useAuth()

  //google analytics
  const handleGA = (reAddress: string) => {
    sendEventToGA({
      category: `real_estate-${reAddress}`,
      action: 'property_click',
    })
  }

  const { refCode, setRefCodeInLocalStorage } = useRefCode()

  useEffect(() => {
    if (refCode && refCode.length > 0) setRefCodeInLocalStorage(refCode)
  }, [refCode])

  if (listLoading) {
    return (
      <div className={`marketplace -${variant}`}>
        <div className="status">
          {skeletonData.map((loading: any) => (
            <div key={loading} className="re-card">
              <div className="imgbox">
                <Skeleton width={'100%'} height={'100%'} />
              </div>
              <div className="content" style={{ padding: '5px' }}>
                <Skeleton width={'50%'} height={30} />
                <Skeleton count={3} width={'100%'} height={30} />
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
  if (listError) {
    return <div>Couldn't fetch Real Estate, check your connection and reload page</div>
  }

  if (user.userId && user.userId.length > 0) {
    return (
      <div className={`marketplace -${variant}`}>
        <div className="status">
          {listData &&
            listData.getRealEstateInfoList.flatMap((item: any) =>
              item.listingPrice && item.address ? (
                <Link
                  key={item.re_id}
                  to={item.isAvailable === false ? `/market` : `/re/${item.re_id}`}
                  state={{ reData: item.re_id }}
                  onClick={() => handleGA(item.address)}
                >
                  <Card
                    key={item.re_id}
                    info={
                      item.tokensRemaining === 0
                        ? `Sold Out`
                        : item.isAvailable === false
                        ? `Coming Soon`
                        : `Available`
                    }
                    bg={
                      item.tokensRemaining === 0
                        ? `soldout`
                        : item.isAvailable === false
                        ? `coming`
                        : `available`
                    }
                    item={item}
                    propertyType={item.isAvailable === false ? propertyType : ''}
                  />
                </Link>
              ) : (
                []
              )
            )}
        </div>
      </div>
    )
  }
  return (
    <div className={`marketplace -${variant}`}>
      <div className="status">
        {listData &&
          listData.getRealEstateInfoList.flatMap((item: any) =>
            item.listingPrice && item.address ? (
              <Link
                key={item.re_id}
                to={item.isAvailable === false ? `/market` : `/realestate/${item.re_id}`}
                state={{ reData: item.re_id }}
                onClick={() => handleGA(item.address)}
              >
                <Card
                  key={item.re_id}
                  info={
                    item.tokensRemaining === 0
                      ? `Sold Out`
                      : item.isAvailable === false
                      ? `Coming Soon`
                      : `Available`
                  }
                  bg={
                    item.tokensRemaining === 0
                      ? `soldout`
                      : item.isAvailable === false
                      ? `coming`
                      : `available`
                  }
                  item={item}
                  propertyType={item.isAvailable === false ? propertyType : ''}
                />
              </Link>
            ) : (
              []
            )
          )}
      </div>
    </div>
  )
}
