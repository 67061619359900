import React from 'react'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import Button from '../ui/button/Button'
import { ThreeBlocksLoader } from '../ui/loader/Loader'
import { SubscriptionAgreement } from '../ui/contracts/GenericContracts'
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer'
import { useMutation } from '@apollo/client'
import { UPDATE_SUBSCRIPTION_AGREEMENT } from 'src/client/mutations/auth'
import { subContract } from 'src/utils/data/contract'
import UserAgreementSub from '../ui/form/UserAgreementSub'

const SubContract = ({
  realEstateId,
  allReData,
  allReLoading,
  user,
  currentTokens,
  paymentData,
}: any) => {
  const [open, setOpen] = React.useState(true)

  const [activateDownloadBtn, setActivateDownloadBtn] = React.useState(false)

  const [createSubscriptionAgreement] =
    useMutation(UPDATE_SUBSCRIPTION_AGREEMENT)

  const handleInitAgreement = () => {
    createSubscriptionAgreement({
      variables: {
        userId: user.userId,
        reId: realEstateId,
        subscriptionAccepted: true,
        tokenPrice: parseFloat(currentTokens.finalTokenPrice),
        tokensInThisOrder: currentTokens.tokens,
        totalNumberOfTokens: currentTokens.totalNumberOfTokens,
      },
      onCompleted: (_data) => {},
    })
    setOpen(false)
  }
  const handleClose = () => {
    setOpen(false)
  }

  if (allReLoading) {
    return (
      <div>
        {' '}
        <Dialog
          className="tnc-dialog"
          open={open}
          onClose={(_, reason) => {
            if (!(reason === 'backdropClick' || reason === 'escapeKeyDown')) {
              handleClose()
            }
          }}
        >
          <ThreeBlocksLoader />
        </Dialog>
      </div>
    )
  }

  return (
    <>
      {Object.keys(paymentData).length ? (
        <div>
          <Dialog
            className="tnc-dialog -sub"
            open={open}
            onClose={(_, reason) => {
              if (!(reason === 'backdropClick' || reason === 'escapeKeyDown')) {
                handleClose()
              }
            }}
          >
            {
              <>
                <DialogTitle className="dialogtitle">
                  <span>Subscription Agreement</span>
                </DialogTitle>

                <DialogContent className="dialogcontent">
                  <div>{subContract}</div>
                  <PDFViewer className="viewerframe">
                    <SubscriptionAgreement
                      realEstate={
                        allReData &&
                        allReData.getRealEstateInfoById.address.formatted_street_address
                      }
                      closingDate={allReData?.getRealEstateInfoById.closingDate}
                      companyName={allReData?.getRealEstateInfoById.companyName}
                      memoDate={allReData?.getRealEstateInfoById.offeringMemorandumDate}
                      realEstateId={realEstateId}
                      user={user}
                      currentTokens={currentTokens}
                    />
                  </PDFViewer>
                  <UserAgreementSub
                    setActivateDownloadBtn={setActivateDownloadBtn}
                    contractType={'sub'}
                    activateDownloadBtn={activateDownloadBtn}
                    realEstateId={realEstateId}
                    handleInitAgreement={handleInitAgreement}
                  />

                  {activateDownloadBtn ? (
                    <DialogActions className="dialogactions">
                      <PDFDownloadLink
                        document={
                          <SubscriptionAgreement
                            realEstate={
                              allReData &&
                              allReData.getRealEstateInfoById.address.formatted_street_address
                            }
                            realEstateId={realEstateId}
                            closingDate={allReData?.getRealEstateInfoById.closingDate}
                            companyName={allReData?.getRealEstateInfoById.companyName}
                            memoDate={allReData?.getRealEstateInfoById.offeringMemorandumDate}
                            user={user}
                            currentTokens={currentTokens}
                          />
                        }
                        fileName={`Subscription Agreement - ${
                          allReData &&
                          allReData.getRealEstateInfoById.address.formatted_street_address
                        }.pdf`}
                      >
                        {({ loading }) =>
                          loading ? (
                            'Loading document...'
                          ) : (
                            <Button
                              variant="primary"
                              handleClick={handleInitAgreement}
                              label="Download &amp; Continue"
                            />
                          )
                        }
                      </PDFDownloadLink>
                    </DialogActions>
                  ) : null}
                </DialogContent>
              </>
            }
          </Dialog>
        </div>
      ) : (
        <ThreeBlocksLoader />
      )}
    </>
  )
}

export default SubContract
