import { aboutProperty, additionalPerks } from 'src/utils/constants'
import CardPropertyAbout from '../ui/card/CardPropertyAbout'
import CardPropertyPerks from '../ui/card/CardPropertyPerks'

interface IPropertyMisc {
  realEstateId: string
  allReData: any
}
const PropertyMisc = ({ allReData }: IPropertyMisc) => {
  return (
    <div className="property -misc">
      {allReData && (
        <>
          <CardPropertyAbout heading={aboutProperty} variant="about" propertyData={allReData} />
          <CardPropertyPerks heading={additionalPerks} variant="about" propertyData={allReData} />
        </>
      )}
    </div>
  )
}

export default PropertyMisc
