import { Outlet } from 'react-router-dom'
import { useAuth } from 'src/context/useAuth'

function ProfilePage() {
  const user = useAuth()
  return (
    <div className={user ? '' : 'authpage'}>
      <Outlet />
    </div>
  )
}

export default ProfilePage
