import REInput from '../input/REInput'
import RESelect from '../select/RESelect'
import RECheckbox from '../checkbox/RECheckbox'
import RETextarea from '../textarea/RETextarea'

import REInputNumber from '../input/REInputNumber'
import REInputDate from '../input/REInputDate'
import { IField, IFieldCheckbox, IFieldSelect, IFieldTextarea } from 'src/types/relisting'
import RERadio from '../radio/RERadio'

interface IREFormProps {
  field: IField | IFieldSelect | IFieldTextarea | IFieldCheckbox
  handleChange: Function
  readonly?: boolean | null
}

const REForm = ({
  field: {
    id,
    label,
    required,
    maxlength,
    placeholder,
    type,
    variant,
    value,
    // @ts-ignore
    options,
  },
  handleChange,
  readonly,
}: IREFormProps) => {
  switch (type) {
    case 'select':
      return (
        <RESelect
          id={id}
          label={label}
          placeholder={placeholder}
          options={options}
          variant={variant}
          required={required}
          value={value}
          handleChange={handleChange}
        />
      )
    case 'checkbox':
      return (
        <RECheckbox
          id={id}
          label={label}
          value={value}
          variant={variant}
          type={type}
          required={required}
          handleChange={handleChange}
        />
      )

    case 'textarea':
      return (
        <RETextarea
          id={id}
          label={label}
          value={value}
          variant={variant}
          required={required}
          type={type}
          // @ts-ignore
          maxlength={maxlength}
          handleChange={handleChange}
        />
      )
    case 'float':
    case 'number':
      return (
        <REInputNumber
          id={id}
          label={label}
          placeholder={placeholder}
          value={value}
          variant={variant}
          required={required}
          handleChange={handleChange}
          // @ts-ignore
          readonly={readonly}
          // @ts-ignore
          type={type}
        />
      )

    case 'date':
      return (
        <REInputDate
          id={id}
          label={label}
          placeholder={placeholder}
          value={value}
          variant={variant}
          required={required}
          handleChange={handleChange}
          // @ts-ignore
          readonly={readonly}
          // @ts-ignore
          type={type}
        />
      )
    case 'radio':
      return (
        <RERadio
          id={id}
          label={label}
          value={value}
          required={required}
          handleChange={handleChange}
        />
      )

    default:
      return (
        <REInput
          id={id}
          label={label}
          placeholder={placeholder}
          value={value}
          variant={variant}
          required={required}
          handleChange={handleChange}
          // @ts-ignore
          readonly={readonly}
          // @ts-ignore
          type={type}
        />
      )
  }
}

export default REForm
