export const config = {
  cognito: {
    REGION: process.env.REACT_APP_REGION,
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    USER_POOL_WEB_CLIENT_ID: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
    DOMAIN: process.env.REACT_APP_DOMAIN,
    REDIRECT: process.env.REACT_APP_REDIRECT,
    REDIRECT_SIGN_IN: process.env.REACT_APP_REDIRECT_SIGN_IN,
    REDIRECT_SIGN_OUT: process.env.REACT_APP_REDIRECT_SIGN_OUT,
  },
  s3: {
    IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
    BUCKET_IMG: process.env.REACT_APP_BUCKET_IMG,
  },
}
