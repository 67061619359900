import React, { useState, useEffect } from 'react'
import { reListingManual } from 'src/utils/data/relisting'
import { useForm, FormProvider } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import REForm from '../../components/ui/form/REForm'
import { AnimatePresence } from 'framer-motion'
import Toast from 'src/components/ui/toast/Toast'
import { BsArrowLeftCircle, BsArrowRightCircle } from 'react-icons/bs'
import { useMutation } from '@apollo/client'
import { toInt } from 'src/utils/relisting'
import { ThreeBlocksLoader } from 'src/components/ui/loader/Loader'
import { CREATE_REAL_ESTATE_FINANCE } from 'src/client/mutations/relisting'
import { IField } from 'src/types/relisting'
import dayjs from 'dayjs'

const message = `Fill out the following fields and Click Next`
const variant = `info`

export default function REListingPage3() {
  const realEstateId = localStorage?.getItem('realEstateId')
  const reId = realEstateId
  const navigate = useNavigate()
  const [fields, setFields] = useState<IField[]>([])
  const [trigger, setTrigger] = useState(true)
  const [createRealEstateFinance] = useMutation(CREATE_REAL_ESTATE_FINANCE)

  useEffect(() => {
    setFields(reListingManual)
  }, [fields])

  const handleChange = (idx: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const newFields = [...fields]
    newFields.forEach((field) => {
      const { id, type, value } = field
      if (idx === id) {
        switch (type) {
          case 'checkbox':
            field.value = event.target.checked
            break
          case 'date':
            field.value = value
            break

          default:
            field.value = event.target.value
            break
        }
      }
      setFields(newFields)
    })
  }

  const onSubmit = (stats: any) => {
    let { estimatedRentStartDate, ...otherStats } = stats
    toInt(otherStats)
    const finalStats = {
      estimatedRentStartDate: dayjs(stats.estimatedRentStartDate).format('MM-DD-YYYY'),
      reId,
      ...otherStats,
    }

    createRealEstateFinance({
      variables: finalStats,
      onCompleted: (data) => {
        navigate('/relisting/financials')
      },
    })
  }

  const methods = useForm({
    criteriaMode: 'all',
  })

  if (localStorage.getItem('realEstateId') === null) {
    return <ThreeBlocksLoader />
  }

  return (
    <>
      <AnimatePresence>
        <Toast toastMessage={message} trigger={trigger} setTrigger={setTrigger} variant={variant} />
      </AnimatePresence>
      <div className="relisting-form">
        <FormProvider {...methods}>
          <form className="form" onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="header">
              <div className="dir">
                <Link to="/relisting/images">
                  <BsArrowLeftCircle className="dir -listing " />
                  <button className="button -listing">Previous</button>
                </Link>
              </div>
              <div>
                <h5>Real Estate Listing (3/5)</h5>
              </div>
              <div className="dir">
                <button className="button -listing" onClick={methods.handleSubmit(onSubmit)}>
                  Next
                </button>
                <BsArrowRightCircle
                  className="dir -listing"
                  onClick={methods.handleSubmit(onSubmit)}
                />
              </div>
            </div>
            <div className="formcontent">
              {fields
                ? fields.map((field) => (
                    <REForm key={field.id} field={field} handleChange={handleChange} />
                  ))
                : null}
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  )
}
