interface IAddressInput {
  formatted_street_address: string
  city: string
  state: string
  zip_code: number
}

interface IStructreInput {
  construction_type: string
  baths: number
  beds_count: number
  foundation_type: string
  air_conditioning_type: string
  architecture_type: string
  basement_type: string
  condition: string
  exterior_wall_type: string
  fireplaces: string
  flooring_types: string
  heating_type: string
  other_features: string
  parking_spaces_count: number
  parking_type: string
  partial_baths_count: number
  roof_material_type: string
  roof_style_type: string
  stories: number
  units_count: number
  year_built: number
  updatedAt: number
}
interface IParcelInput {
  municipality: string
  area_acres: string
  area_sq_ft: number
  building_count: number
  county_land_use_code: string
  type_of_build: string
  county_land_use_description: string
  county_name: string
  frontage_ft: number
  subdivision: string
  zoning: string
}
interface ITaxesInput {
  amount: number
  year: number
}

export interface ICreateRealEstateBasicInfoInputs {
  address: IAddressInput
  structure: IStructreInput
  parcel: IParcelInput
  taxes: ITaxesInput
  user_id: string

}
export interface IFieldSelect {
  id: string
  label: string
  required: boolean
  maxlength?: number
  placeholder?: string
  type?: string
  variant: string
  value?: boolean
  options: IOption[]
}

export interface IOption {
  option_label?: string
  option_value?: string
  selected?: boolean
}
export interface IFieldTextarea {
  id: string
  label: string
  required: boolean
  maxlength: number
  placeholder?: string
  type: string
  variant: string
  value: boolean
}
export interface IFieldCheckbox {
  id: string
  label: string
  required: boolean
  maxlength: number
  placeholder: string
  type: string
  variant: string
  value: boolean
}

export interface IValidation {
  [dynamic: string]: any
}
export interface IField {
  id: string
  label: string
  required: boolean
  maxlength?: number
  placeholder?: string
  type: string
  variant: string
  value: any
  options?: IOption[]
  validationType?: string
  validations?: IValidation[]
}

export interface IREListing {
  address1: string
  address2: string
  city: string
  zip: number
  state: string
  country: string
  googleCoordinates: string
  propertyTaxes: number
  typeOfBuild: string
  propertyType: string
  totalUnits: number
  numberOfRooms: number
  numberOfBathrooms: number
  squareFeet: number
  roofType: string
  parking: string
  foundation: string
  heating: string
  cooling: string
  neighborhood: string
}

export interface AddressType {
  carrier_code: string
  census_tract: string
  city: string
  formatted_street_address: string
  geocoding_accuracy: string
  latitude: string
  longitude: string
  state: string
  street_name: string
  street_number: number
  street_post_direction: string
  street_pre_direction: string
  street_suffix: string
  unit_number: string
  unit_type: string
  zip_code: string
  zip_plus_four_code: string
}
export interface MetadataType {
  publishing_date: string
}
export interface OwnerType {
  city: string
  formatted_street_address: string
  name: string
  owner_occupied: string
  second_name: string
  state: string
  unit_number: string
  unit_type: string
  zip_code: string
  zip_plus_four_code: string
}
export interface ParcelType {
  apn_original: string
  apn_previous: string
  apn_unformatted: string
  area_acres: number
  area_sq_ft: number
  building_count: number
  county_land_use_code: string
  county_land_use_description: string
  county_name: string
  depth_ft: number
  fips_code: string
  frontage_ft: number
  legal_description: string
  location_descriptions: [string]
  lot_code: number
  lot_number: string
  municipality: string
  section_township_range: string
  standardized_land_use_category: string
  standardized_land_use_type: string
  subdivision: string
  tax_account_number: string
  zoning: string
}

export interface OtherAreaType {
  sq_ft: string
  type: string
}
export interface StructureType {
  air_conditioning_type: string
  amenities: [string]
  architecture_type: string
  basement_type: string
  baths: number
  beds_count: number
  condition: string
  construction_type: string
  effective_year_built: number
  exterior_wall_type: string
  fireplaces: string
  flooring_types: [string]

  foundation_type: string
  heating_fuel_type: string
  heating_type: string
  interior_wall_type: string
  other_areas: OtherAreaType
  other_features: [string]
  other_improvements: [string]
  other_rooms: [number]
  parking_spaces_count: number
  parking_type: string
  partial_baths_count: number
  plumbing_fixtures_count: number
  pool_type: string
  quality: string
  roof_material_type: string
  roof_style_type: string
  rooms_count: number
  sewer_type: string
  stories: string
  total_area_sq_ft: number
  units_count: number
  water_type: string
  year_built: number
}
export interface TaxesType {
  amount: number
  exemptions: [string]
  rate_code_area: string
  year: number
}
export interface ValuationType {
  date: string
  forecast_standard_deviation: number
  high: number
  low: number
  value: number
}
export interface RealEstateBasicInfoType {
  address: AddressType
  assessments: AssessmentType
  boundary: BoundaryType
  createdAt: string
  deeds: DeedType
  market_assessments: MarketAssessmentType
  message: string
  metadata: MetadataType
  owner: OwnerType
  parcel: ParcelType
  re_id: string
  structure: StructureType
  taxes: TaxesType
  updatedAt: Date
  valuation: ValuationType
}

export interface AssessmentType {
  improvement_value: number
  land_value: number
  total_value: number
  year: number
}

export interface GeojsonType {
  coordinates: number
  type: string
}

export interface BoundaryType {
  geojson: GeojsonType
  wkt: string
}

export interface DeedType {
  buyer2_first_name: string
  buyer2_last_name: string
  buyer_address: string
  buyer_city: string
  buyer_first_name: string
  buyer_last_name: string
  buyer_state: string
  buyer_unit_number: string
  buyer_unit_type: string
  buyer_zip_code: string
  buyer_zip_plus_four_code: string
  deed_book: string
  deed_page: string
  distressed_sale: boolean
  document_id: string
  document_type: string
  lender_name: string
  lender_type: string
  loan_amount: number
  loan_due_date: string
  loan_finance_type: string
  loan_interest_rate: string
  loan_type: string
  original_contract_date: string
  real_estate_owned: string
  recording_date: string
  sale_price: number
  sale_price_description: string
  seller2_first_name: string
  seller2_last_name: string
  seller_address: string
  seller_city: string
  seller_first_name: string
  seller_last_name: string
  seller_state: string
  seller_unit_number: string
  seller_zip_code: string
  seller_zip_plus_four_code: string
  transfer_tax: number
}

export interface MarketAssessmentType {
  improvement_value: number
  land_value: number
  total_value: number
  year: number
}

export enum ROLE {
  Admin = 'Admin',
  Buyer = 'Buyer',
  Visitor = 'Visitor',
}

export enum PERMISSIONS {
  blockchain_address = 'blockchain_address',
  firstname = 'firstname',
  home = 'home',
  kyc_status = 'kyc_status',
  lastname = 'lastname',
  marketplace = 'marketplace',
  profile = 'profile',
  re_detail = 're_detail',
  re_listing = 're_listing',
}
