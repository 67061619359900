// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3Csvg width%3D%2249%22 height%3D%2232%22 fill%3D%22none%22 xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E  %3Cpath d%3D%22M18.596 27.255a38.016 38.016 0 0 1-.53-.081l.32-.265.21.346Zm.54-.073-.362-.595.22-.181.315.043v.904l-.04-.005a.832.832 0 0 1-.132-.166Zm.673-.953a.245.245 0 0 0-.004-.043.792.792 0 0 1 .414.28.88.88 0 0 1 .066.963.809.809 0 0 1-.476.145v-1.345Zm.498-.356a1.304 1.304 0 0 0-1.453.008 37.451 37.451 0 0 1-.562-.086l-2.306-3.789a.884.884 0 0 1 .268-1.19.801.801 0 0 1 1.132.259l2.921 4.798Zm-2.323.378.138.227-.706.585c-4.488-.804-8.406-2.354-11.312-4.403C2.735 20.286.75 17.262.75 13.965c0-3.782 2.613-7.205 6.924-9.697C11.979 1.78 17.934.25 24.5.25c6.566 0 12.521 1.53 16.826 4.018 4.31 2.492 6.924 5.915 6.924 9.697 0 3.153-1.815 6.055-4.92 8.38-3.043 2.279-7.307 3.985-12.227 4.8v-.909c4.645-.79 8.665-2.393 11.544-4.507 2.919-2.145 4.691-4.84 4.691-7.764 0-3.551-2.608-6.76-6.724-9.067-4.125-2.311-9.816-3.752-16.114-3.752S12.51 2.586 8.386 4.898c-4.116 2.307-6.724 5.516-6.724 9.067 0 3.07 1.95 5.884 5.133 8.078 2.86 1.971 6.74 3.462 11.19 4.208Zm1.077 1.574c.167.122.358.2.558.232l-4.227 3.501a.803.803 0 0 1-1.155-.118.882.882 0 0 1 .14-1.211l3.18-2.634c.495.086.996.163 1.504.23Z%22 fill%3D%22%23FCFCFD%22 stroke%3D%22%23F7F9FA%22 stroke-width%3D%22.5%22 stroke-linecap%3D%22round%22 stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-DocumentMultiFrame-controls{-webkit-box-flex:1;-webkit-flex:1;flex:1;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end}.onfido-sdk-ui-DocumentMultiFrame-icon{display:none}@media(min-height: 568px){.onfido-sdk-ui-DocumentMultiFrame-icon{display:block;height:4em;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_animation_duration": "200ms",
	"controls": "onfido-sdk-ui-DocumentMultiFrame-controls",
	"icon": "onfido-sdk-ui-DocumentMultiFrame-icon"
};
export default ___CSS_LOADER_EXPORT___;
