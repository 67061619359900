import { gql } from '@apollo/client'

export const FETCH_POSTS_BY_CURSOR = gql`
  query FetchPosts($first: Int, $after: String) {
    postsConnection(first: $first, after: $after, orderBy: publishedAt_DESC) {
      edges {
        node {
          id
          title
          coverImage {
            url
          }
          slug
          content {
            raw
            text
          }
          excerpt
          publishedAt
          updatedAt
          createdAt
          tags
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        pageSize
      }
    }
  }
`
