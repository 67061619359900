import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { AnimatePresence } from 'framer-motion'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import AuthLayout from 'src/components/layouts/AuthLayout'
import Toast from 'src/components/ui/toast/Toast'
import { ThreeBlocksLoader } from 'src/components/ui/loader/Loader'
import { countryList } from 'src/utils/data/kyc'
import {
  variantDetails,
  variantVerify,
  countryInfo,
  kycErrorMessage,
  revokeMessage,
} from 'src/utils/constants'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { AlertSimple } from '../../components/ui/alert/Alert'
import { CREATE_APPLICANT } from 'src/client/mutations/verify'
import Button from 'src/components/ui/button/Button'
import { useAuth } from 'src/context/useAuth'

interface IAddressInput {
  line1: string
  line2?: string
  state: string
  town: string
  postcode: string
  country: string
}
interface IVerifyUserInputs {
  firstName: string
  lastName: string
  dob: string
  address: IAddressInput
}

const verifyUserSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  dob: yup.string().required(),
})

export default function VerifyUserPage1() {
  const [disableSubmit, activateDisableSubmit] = useState(false)
  const [trigger, setTrigger] = useState(true)
  const [createApplicant, { loading, error }] = useMutation(CREATE_APPLICANT)
  //@ts-ignore
  const { user } = useAuth()
  const verifyValues = {
    firstName: user.name,
    lastName: user.family_name,
    dob: user.dob,
    address: { line1: '', line2: '', state: '', town: '', postcode: '', country: '' },
  }
  const {
    register,
    formState: { errors },
    resetField,
    handleSubmit,
  } = useForm<IVerifyUserInputs>({
    defaultValues: verifyValues,
    resolver: yupResolver(verifyUserSchema),
  })

  const navigate = useNavigate()

  const onSubmit: SubmitHandler<IVerifyUserInputs> = (inputs: any) => {
    const currentUserId = localStorage?.getItem('userId')
    inputs.user_id = currentUserId
    const bannedList = [
      'USA',
      'BLR',
      'MMR',
      'CIV',
      'CUB',
      'COG',
      'COD',
      'PRK',
      'IRN',
      'IRQ',
      'LBR',
      'ALB',
      'SDN',
      'SYR',
      'ZWE',
    ]
    if (bannedList.includes(inputs.address.country)) {
      activateDisableSubmit(!disableSubmit)
      resetField('address.country')
    } else {
      createApplicant({
        variables: { inputs },
        onCompleted: (data) => {
          const { id } = data.createApplicant
          localStorage.setItem('applicantData', id)
          navigate('/orch')
        },
      })
    }
  }
  if (loading) {
    return <ThreeBlocksLoader />
  }
  return (
    <>
      {disableSubmit && (
        <AnimatePresence>
          <Toast
            toastMessage={`${revokeMessage}`}
            trigger={trigger}
            setTrigger={setTrigger}
            variant={variantDetails.error}
          />
        </AnimatePresence>
      )}
      {error && (
        <AnimatePresence>
          <Toast
            toastMessage={`${kycErrorMessage}`}
            trigger={trigger}
            setTrigger={setTrigger}
            variant={variantDetails.error}
          />
        </AnimatePresence>
      )}
      <AuthLayout variant={variantVerify}>
        <div className="auth-container">
          <div className="auth-panel">
            <form className="authform" onSubmit={handleSubmit(onSubmit)}>
              <div className="details">
                <label className="label" htmlFor="firstName">
                  First Name
                </label>
                <div className="inputcontainer">
                  <input className="input" type="text" {...register('firstName')} />
                </div>
                <p>{errors.firstName?.message}</p>
              </div>
              <div className="details">
                <label className="label" htmlFor="lastName">
                  Last Name
                </label>
                <div className="inputcontainer">
                  <input className="input" type="text" {...register('lastName')} />
                </div>
                <p>{errors.lastName?.message}</p>
              </div>
              <div className="details">
                <label className="label" htmlFor="dob">
                  Date of Birth
                </label>
                <div className="inputcontainer">
                  <input className="input" type="date" {...register('dob')} />
                </div>
                <p>{errors.dob?.message}</p>
              </div>

              <div className="details">
                <label className="label" htmlFor="line1">
                  Address 1
                </label>
                <div className="inputcontainer">
                  <input className="input" type="text" {...register('address.line1')} />
                </div>
              </div>
              <div className="details">
                <label className="label" htmlFor="line2">
                  Address 2
                </label>
                <div className="inputcontainer">
                  <input className="input" type="text" {...register('address.line2')} />
                </div>
              </div>

              <div className="details">
                <label className="label" htmlFor="postcode">
                  Zip Code
                </label>
                <div className="inputcontainer">
                  <input className="input" type="text" {...register('address.postcode')} />
                </div>
              </div>
              <div className="details">
                <label className="label" htmlFor="state">
                  State
                </label>
                <div className="inputcontainer">
                  <input className="input" type="text" {...register('address.state')} />
                </div>
              </div>
              <div className="details">
                <label className="label" htmlFor="town">
                  City
                </label>
                <div className="inputcontainer">
                  <input className="input" type="text" {...register('address.town')} />
                </div>
              </div>

              <div className="details">
                <label className="label" htmlFor="country">
                  Country
                </label>
                {/* <div className="inputcontainer"> */}
                <select className="select" {...register('address.country')}>
                  {countryList.map((country, id) => (
                    <option key={id} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </select>
                {/* </div> */}
              </div>

              <div className="submit -verify">
                <Button label="Verify your identity" variant="primary" />
              </div>
            </form>
            <AlertSimple alertMessage={countryInfo} variant="info" />
          </div>
        </div>
      </AuthLayout>
    </>
  )
}
