import { Fragment, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useAuth } from 'src/context/useAuth'
import Button from '../button/Button'
import { Auth, Hub } from 'aws-amplify'
import Skeleton from 'react-loading-skeleton'
// import { perm } from 'src/utils/profile'
import { AccessControl } from 'src/utils/config/routes'

export default function Sidebar({ isOpen, closeSidebar, variant }: any): JSX.Element {
  const styles = isOpen ? 'sidebar sidebar--open' : 'sidebar sidebar--closed'
  const [loader, setLoader] = useState(false)

  //@ts-ignore
  const { user, rbac } = useAuth()
  async function signOut() {
    try {
      setLoader(true)
      await Auth.signOut({
        global: true,
      })
      setLoader(false)
      window.location.reload()
    } catch (error) {
      setLoader(false)
    }
    localStorage.clear()
    Hub.listen('auth', (data) => {
      const { payload } = data
      if (payload.event === 'signOut') {
        window.location.reload()

        setLoader(false)
      }
    })
  }

  return (
    <Fragment>
      {isOpen ? (
        <div className="sidebar__backdrop" onClick={closeSidebar}></div>
      ) : (
        <Fragment></Fragment>
      )}
      {user.userId && user.userId.length > 0 ? (
        <nav className={`${styles}`}>
          <div className="container">
            <ul className="container__list">
              <li className="container__list__item" onClick={closeSidebar}>
                <NavLink className="sidebar__link" to={`/market/`}>
                  Marketplace
                </NavLink>
              </li>
              <li className="container__list__item" onClick={closeSidebar}>
                <NavLink className="sidebar__link" to={`/affiliate/`}>
                  Affiliate Program
                </NavLink>
              </li>
              <AccessControl
                filteredUserPermissions={rbac}
                allowedPermissions={['re_listing']}
                renderNoAccess={() => null}
              >
                <li className="container__list__item" onClick={closeSidebar}>
                  <NavLink className="sidebar__link" to={`/relisting/`}>
                    RE Listing
                  </NavLink>
                </li>
              </AccessControl>

              <li className="container__list__item" onClick={closeSidebar}>
                <NavLink className="sidebar__link" to={`/me/details`}>
                  Profile
                </NavLink>
              </li>
              <li className="container__list__item" onClick={closeSidebar}>
                <NavLink className="sidebar__link" to={`/me/portfolio`}>
                  Portfolio
                </NavLink>
              </li>

              <li className="container__list__item" onClick={signOut}>
                {loader ? (
                  <NavLink className="sidebar__link" to="#">
                    <Button label="Signing Out" variant="disabled" />
                  </NavLink>
                ) : (
                  <NavLink className="sidebar__link" to="/">
                    <Button label="Sign Out" variant="reverse" />
                  </NavLink>
                )}
              </li>
            </ul>
          </div>
        </nav>
      ) : (
        <nav className={`${styles}`}>
          <div className="container">
            <ul className="container__list">
              <li className="container__list__item" onClick={closeSidebar}>
                <NavLink className="sidebar__link" to={`/market/`}>
                  Marketplace
                </NavLink>
              </li>
              <li className="container__list__item" onClick={closeSidebar}>
                <NavLink className="sidebar__link" to={`/auth/`}>
                  Affiliate Program
                </NavLink>
              </li>
              {loader ? (
                <Skeleton />
              ) : (
                <li className="container__list__item">
                  <NavLink to="/auth" className="container__link">
                    <Button label="Sign In / Sign Up" variant="reverse" />
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        </nav>
      )}
    </Fragment>
  )
}
