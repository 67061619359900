/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { FETCH_POSTS_BY_CURSOR } from 'src/client/queries/blogs'
import { hygraphClient } from 'src/client'
import { addBlogs, pageInfo } from 'src/state/slices/blogsSlice'

export const useFetchBlogs = () => {
  const [blogsData, setBlogsData] = useState<any>([])
  const [blogsPageInfo, setBlogsPageInfo] = useState<any>([])
  const dispatch = useDispatch()

  const [fetchBlogs, { loading }] = useLazyQuery(FETCH_POSTS_BY_CURSOR, {
    client: hygraphClient,
    onCompleted: (data: any) => {
      setBlogsData(data.postsConnection.edges)
      setBlogsPageInfo(data.postsConnection.pageInfo)
    },
  })

  useEffect(() => {
    if (blogsData.length > 0) {
      dispatch(addBlogs(blogsData))
    }
  }, [blogsPageInfo.endCursor])
  useEffect(() => {
    if (blogsData.length > 0) {
      dispatch(pageInfo(blogsPageInfo))
    }
  }, [blogsPageInfo.endCursor])

  return {
    fetchBlogs,
    loading,
  }
}
