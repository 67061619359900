import { BiLinkExternal } from 'react-icons/bi'
import { currencyFormatter, toFixedSimple } from 'src/utils/relisting'
import Skeleton from 'react-loading-skeleton'
import { useQuery } from '@apollo/client'
import { GET_AGREEMENT_DOC_LINK } from 'src/client/queries/relisting'
import { CircularProgress } from '@mui/material'

export default function TokenInfo({ allReData, realEstateId }: any) {
  const { data, loading, error } = useQuery(GET_AGREEMENT_DOC_LINK, {
    variables: {
      reId: realEstateId,
    },
  })

  if (loading) {
  }

  return (
    <div className="info-tokens">
      <div className="sc">
        <div>
          View PPM Agreement{' '}
          {data?.getAgreementDocLink.ppmLink && (
            <span>
              <a
                href={data?.getAgreementDocLink.ppmLink}
                rel="noopener noreferrer"
                target={'_blank'}
              >
                <BiLinkExternal />
              </a>
            </span>
          )}
          {loading && (
            <span>
              <CircularProgress size={8} />
            </span>
          )}
          {error && null}
        </div>
      </div>
      <div className="sc">
        <div>
          View Operating Agreement{' '}
          {data?.getAgreementDocLink.operatingLink && (
            <span>
              <a
                href={data?.getAgreementDocLink.operatingLink}
                rel="noopener noreferrer"
                target={'_blank'}
              >
                <BiLinkExternal />
              </a>
            </span>
          )}
          {loading && (
            <span>
              <CircularProgress size={8} />
            </span>
          )}
          {error && null}
        </div>
      </div>
      <div className="sc">
        <div>
          View Subscription Agremeent{' '}
          {data?.getAgreementDocLink.subscriptionLink && (
            <span>
              <a
                href={data?.getAgreementDocLink.subscriptionLink}
                rel="noopener noreferrer"
                target={'_blank'}
              >
                <BiLinkExternal />
              </a>
            </span>
          )}
          {loading && (
            <span>
              <CircularProgress size={8} />
            </span>
          )}
          {error && null}
        </div>
      </div>
      <div className="sc">
        <div>
          View RE Smart Contract Token{' '}
          {allReData && allReData.getRealEstateInfoById.tokenSmartContractUrl ? (
            <span>
              <a
                href={allReData.getRealEstateInfoById.tokenSmartContractUrl}
                rel="noopener noreferrer"
                target={'_blank'}
              >
                <BiLinkExternal />
              </a>
            </span>
          ) : (
            <span>
              <CircularProgress size={8} />
            </span>
          )}
        </div>
      </div>
      <div className="sc">
        <div>
          View Youtube Link{' '}
          {allReData && allReData.getRealEstateInfoById.youtubeLink ? (
            <span>
              <a
                href={allReData.getRealEstateInfoById.youtubeLink}
                rel="noopener noreferrer"
                target={'_blank'}
              >
                <BiLinkExternal />
              </a>
            </span>
          ) : (
            <span>
              <CircularProgress size={8} />
            </span>
          )}
        </div>
      </div>

      <div className="stats">
        <div>Est. Net ROI</div>
        <div>
          {allReData && allReData.getRealEstateInfoById.expectedNetRoi ? (
            `${toFixedSimple(allReData.getRealEstateInfoById.expectedNetRoi, 2)} %`
          ) : (
            <Skeleton />
          )}
        </div>
      </div>
      <div className="stats">
        <div>Est. Net Rent</div>
        <div>
          {allReData && allReData.getRealEstateInfoById.expectedNetRentPerProperty ? (
            currencyFormatter(allReData.getRealEstateInfoById.expectedNetRentPerProperty)
          ) : (
            <Skeleton />
          )}
        </div>
      </div>
      <div className="stats">
        <div>Est. Net Rent per Token</div>
        <div>
          {allReData && allReData.getRealEstateInfoById.netRentPerToken ? (
            `$${toFixedSimple(allReData.getRealEstateInfoById.netRentPerToken, 2)}`
          ) : (
            <Skeleton />
          )}
        </div>
      </div>
      <div className="stats">
        <div>Listing Price</div>
        <div>
          {allReData && allReData.getRealEstateInfoById.listingPrice ? (
            currencyFormatter(allReData.getRealEstateInfoById.listingPrice)
          ) : (
            <Skeleton />
          )}
        </div>
      </div>
      <div className="stats">
        <div>Asset Price</div>
        <div>
          {allReData && allReData.getRealEstateInfoById.assetPrice ? (
            currencyFormatter(allReData.getRealEstateInfoById.assetPrice)
          ) : (
            <Skeleton />
          )}
        </div>
      </div>
      <div className="stats">
        <div>Closing Cost</div>
        <div>
          {allReData && allReData.getRealEstateInfoById.closingCost ? (
            currencyFormatter(allReData.getRealEstateInfoById.closingCost)
          ) : (
            <Skeleton />
          )}
        </div>
      </div>
      <div className="stats">
        <div>Renovation Costs</div>
        <div>
          {allReData && allReData.getRealEstateInfoById.renovationCosts ? (
            currencyFormatter(allReData.getRealEstateInfoById.renovationCosts)
          ) : (
            <Skeleton />
          )}
        </div>
      </div>
      <div className="stats">
        <div>Total Investment</div>
        <div>
          {allReData && allReData.getRealEstateInfoById.totalInvestments ? (
            currencyFormatter(allReData.getRealEstateInfoById.totalInvestments)
          ) : (
            <Skeleton />
          )}
        </div>
      </div>
      <div className="stats">
        <div>Platform Fees</div>
        <div>
          {allReData && allReData.getRealEstateInfoById.platformListingFees ? (
            currencyFormatter(allReData.getRealEstateInfoById.platformListingFees)
          ) : (
            <Skeleton />
          )}
        </div>
      </div>
      {allReData && allReData.getRealEstateInfoById.estimatedRentStartDate && (
        <div className="stats">
          <div>Est. Rent Start Date</div>
          <div>
            {allReData && allReData.getRealEstateInfoById.estimatedRentStartDate ? (
              allReData.getRealEstateInfoById.estimatedRentStartDate
            ) : (
              <Skeleton />
            )}
          </div>
        </div>
      )}
    </div>
  )
}
