import { gql } from '@apollo/client'

export const CREATE_USER_IN_DYNAMO = gql`
  mutation CreateUserInDynamo {
    createUserInDynamo {
      rolePermission {
        permission {
          buyer_stats
          checkout
          documents
          faq
          home
          jobs
          kyc
          learn
          marketplace
          orders
          profile
          re_statistics
          review_re
          search_engine
          sell_tokens
          upload_re
          users
        }
        role
      }
      user_id
      user {
        email
      }
    }
  }
`

export const UPDATE_ETHEREUM_ADDRESS = gql`
  mutation UpdateEthereumAddress($input: CreateWhiteListAddressInput!) {
    updateEthereumAddress(input: $input) {
      message
    }
  }
`

export const UPDATE_PPM_OP_AGREEMENT = gql`
  mutation UpdatePpmOrOperatingDocAgreement(
    $ppmAccepted: Boolean
    $operatingAccepted: Boolean
    $reId: String!
    $userId: String!
  ) {
    updatePpmOrOperatingDocAgreement(
      ppmAccepted: $ppmAccepted
      operatingAccepted: $operatingAccepted
      re_id: $reId
      user_id: $userId
    ) {
      operatingAgreement
      operatingAgreementTimestamp
      ppmAgreement
      ppmAgreementTimestamp
      re_id
      user_id
    }
  }
`

export const UPDATE_SUBSCRIPTION_AGREEMENT = gql`
  mutation CreateSubscriptionAgreement(
    $reId: String!
    $subscriptionAccepted: Boolean!
    $tokenPrice: Float!
    $tokensInThisOrder: Float!
    $totalNumberOfTokens: Float!
    $userId: String!
  ) {
    createSubscriptionAgreement(
      re_id: $reId
      subscriptionAccepted: $subscriptionAccepted
      tokenPrice: $tokenPrice
      tokensInThisOrder: $tokensInThisOrder
      totalNumberOfTokens: $totalNumberOfTokens
      user_id: $userId
    ) {
      amount
      re_id
      subscriptionAccepted
      subscriptionId
      subscriptionTimestamp
      tokenPrice
      tokensInThisOrder
      totalNumberOfTokens
      user_id
    }
  }
`