import { createContext, useContext, useState } from 'react'

interface IRefreshContext {
  reInit: boolean
  unInit: any
  refreshPage: any
}
const RefreshContext = createContext<IRefreshContext | undefined>(undefined)

export const useRefresh = () => {
  const context = useContext(RefreshContext)
  if (!context) {
    throw new Error(`useRefresh must be used within RefreshProvider`)
  }
  return context
}

export const RefreshProvider = (props: any) => {
  const [reInit, setReInit] = useState(false)

  const refreshPage = () => {
    setReInit(!reInit)
  }
  const unInit = () => {
    setReInit(false)
  }

  return <RefreshContext.Provider value={{ reInit, refreshPage, unInit }} {...props} />
}
