import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export function MarketplaceSkeleton() {
  return (
    <div className="re-card">
      <div className={'imgbox'}>
        <div className="banner">
          <div className="info">
            <Skeleton />
          </div>
        </div>
        <Skeleton height={50} />
      </div>
      <div className="content">
        <div className="address">
          <Skeleton />
          <div className="address">
            <Skeleton />
          </div>
        </div>
        <div className="tokens">
          <Skeleton />
          <Skeleton />
        </div>
        <div className="inv">
          <Skeleton />
          <Skeleton />
        </div>

        <div className="yield">
          <Skeleton />
          <Skeleton />
        </div>
      </div>
    </div>
  )
}

export function REListingSkeleton() {
  return (
    <div className="relisting-input">
      <div className="details">
        <Skeleton height={40} />
      </div>
    </div>
  )
}
export function REListingReviewSkeleton() {
  return (
    <div className="relisting-input">
      <div className="details">
        <Skeleton highlightColor="#eef4fc" baseColor="#fcfcfd" height={80} />
      </div>
    </div>
  )
}
export function HeaderSkeleton() {
  return (
    <div className="relisting-input">
      <div className="details">
        <Skeleton highlightColor="#5691f0;" baseColor="#fcfcfd" height={30} />
      </div>
    </div>
  )
}
export function REListingSectionSkeleton() {
  return (
    <div className="fieldsection">
      <Skeleton height={40} />
    </div>
  )
}

export function VerifySkeleton() {
  return (
    <div className="auth -skeleton">
      <div className="skeleton">
        <Skeleton width={512} height={60} count={8} />
      </div>
    </div>
  )
}
