import React, { useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Marketplace } from './pages/marketplace'
import { Coinify, PaymentOptions, PaymentPage } from './pages/payment'
import Stripe from './pages/payment/Stripe'
import { Profile } from './pages/profile'
import { REListingPage, REListingTokens } from './pages/relisting'
import { VerifyUserOrch, VerifyUserPage, VerifyUserPage1 } from './pages/verify'
import { PERMISSIONS } from './types/relisting'
import { RequireAuth } from './utils/config/routes'
import { ThreeBlocksLoader } from './components/ui/loader/Loader'
import Portfolio from './pages/portfolio/Portfolio'
import ReactGA from 'react-ga4'
import BlogsPage from './pages/blogs/BlogsPage'
import { Blog } from './pages/blogs/Blog'
import Privacy from './pages/homepage/Privacy'
import Cookie from './pages/homepage/Cookie'
import FAQ from './pages/homepage/FAQ'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Affiliate from './pages/affiliate'

const HomePage = React.lazy(() => import('./pages/homepage/HomePage'))
const AboutPage = React.lazy(() => import('./pages/homepage/AboutPage'))
const Terms = React.lazy(() => import('./pages/homepage/Terms'))
const REListingPage1 = React.lazy(() => import('./pages/relisting/REListingDetails'))
const REListingPage2 = React.lazy(() => import('./pages/relisting/REListingImgs'))
const REListingPage3 = React.lazy(() => import('./pages/relisting/REListingStats'))
const REListingPage4 = React.lazy(() => import('./pages/relisting/REListingFinancials'))
const ProfilePage = React.lazy(() => import('./pages/profile'))
const AuthForm = React.lazy(() => import('./pages/auth'))
const NotFound = React.lazy(() => import('./pages/main'))
const RealEstatePublic = React.lazy(() => import('./pages/marketplace/RealEstatePublic'))
const RealEstate = React.lazy(() => import('./pages/marketplace'))
const MEASUREMENT_ID = process.env.REACT_APP_G_ANALYTICS

function App() {
  const location = useLocation()
  const navigate = useNavigate()
  //google analytics GA
  useEffect(() => {
    if (MEASUREMENT_ID !== undefined) {
      ReactGA.initialize(MEASUREMENT_ID)
    }
  }, [])

  useEffect(() => {
    if (location.pathname === '/') {
      ReactGA.send({ hitType: 'pageview', page: '/home', title: 'HomePage' })
    } else if (location.pathname.includes('re')) {
      ReactGA.send({
        hitType: 'pageview',
        page: '/re',
        title: 'RealEstateDetailsPage',
      })
    } else if (location.pathname.includes('realestate')) {
      ReactGA.send({
        hitType: 'pageview',
        page: '/realestate',
        title: 'Public RealEstateDetailsPage',
      })
    } else if (location.pathname.includes('market')) {
      ReactGA.send({
        hitType: 'pageview',
        page: '/market',
        title: 'Marketplace',
      })
    } else if (location.pathname.includes('pay')) {
      ReactGA.send({
        hitType: 'pageview',
        page: '/pay',
        title: 'Payment',
      })
    } else if (location.pathname.includes('/defi')) {
      navigate('/')
    }
  }, [location.pathname])

  return (
    <React.Suspense fallback={<ThreeBlocksLoader />}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/terms-of-use" element={<Terms />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/affiliate" element={<Affiliate />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/cookie-policy" element={<Cookie />} />
        <Route path="/auth" element={<AuthForm />} />
        <Route path="/me" element={<ProfilePage />}>
          <Route path="details" element={<Profile />} />
          <Route path="portfolio" element={<Portfolio />} />
        </Route>
        <Route path="/verify" element={<VerifyUserPage />}>
          <Route index element={<VerifyUserPage1 />} />
          <Route path="details" element={<VerifyUserPage1 />} />
        </Route>
        <Route path="orch" element={<VerifyUserOrch />} />
        <Route
          path="/relisting"
          element={
            <RequireAuth permissions={PERMISSIONS.re_listing}>
              <REListingPage />
            </RequireAuth>
          }
        >
          <Route index element={<REListingPage1 />} />
          <Route path="details" element={<REListingPage1 />} />
          <Route path="images" element={<REListingPage2 />} />
          <Route path="tokens" element={<REListingTokens />} />
          <Route path="stats" element={<REListingPage3 />} />
          <Route path="financials" element={<REListingPage4 />} />
        </Route>

        <Route path="market" element={<Marketplace />} />
        <Route path="realestate/:reId" element={<RealEstatePublic />} />

        <Route path="re/:reId" element={<RealEstate />} />

        <Route path="/pay" element={<PaymentPage />}>
          <Route index element={<PaymentOptions />} />
          <Route path=":reId" element={<PaymentOptions />} />
          <Route path="options" element={<PaymentOptions />} />
          <Route path="coinify" element={<Coinify />} />
          <Route path="stripe" element={<Stripe />} />
        </Route>
        <Route path="*" element={<NotFound />} />
        <Route path="/blogs" element={<BlogsPage />} />
        <Route path="/blogs/:slug" element={<Blog />} />
      </Routes>
      <ToastContainer />
    </React.Suspense>
  )
}

export default App

//trigger deployment
