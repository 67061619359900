import { motion } from 'framer-motion'
import Marquee from 'react-fast-marquee'

interface IMarqueeProps {
  variant?: string
  info?: string[]
  message?: string
}

const ThreeMarquee = ({ variant, info, message }: IMarqueeProps) => {
  return (
    <div>
      <div className={`threeMarquee -${variant}`}>
        <motion.div className="track" animate="animate">
          <small>{info}</small>
          <small>{message}</small>
        </motion.div>
      </div>
    </div>
  )
}

export default ThreeMarquee

export const ProfileMarquee = ({ message }: IMarqueeProps) => {
  return (
    <div className="newProfileMarquee">
      <Marquee
        gradient={false}
        speed={40}
        style={{
          position: 'relative',
          height: '4vh',
          overflowX: 'hidden',
          backgroundColor: 'var(--success-40)',
          transition: '1000ms all ease-in-out',
          color: 'white',
        }}
      >
        <small>{message}</small>
      </Marquee>
    </div>
  )
}
