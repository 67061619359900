import { useEffect, useState, useRef, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import FooterLandingPage from 'src/components/sections/FooterLandingPage'
import Header from 'src/components/sections/Header'
import { BlogPostCard } from 'src/components/ui/blogPost/BlogPostCard'
import { useFetchBlogs } from 'src/hooks/useFetchBlogs'
import { formatDate } from 'src/utils/commonUtils'
import '../../assets/loader/loader.css'

const BlogsPage = () => {
  const { fetchBlogs, loading } = useFetchBlogs()
  const allBlogs: any[] = useSelector((store: any) => store.blogsData.blogs)
  const blogsPageInfo: any = useSelector((store: any) => store.blogsData.pageInfo)
  const [latestBlog, ...prevBlogs] = allBlogs
  const [page, setPage] = useState<number>(0)

  const FETCH_LIMIT = 10

  useEffect(() => {
    if (allBlogs.length > 0) return
    fetchBlogs({
      variables: { first: FETCH_LIMIT, after: null },
    })
  }, [])
  useEffect(() => {
    if (blogsPageInfo.hasNextPage && blogsPageInfo.endCursor) {
      fetchBlogs({
        variables: { first: FETCH_LIMIT, after: blogsPageInfo.endCursor },
      })
    }
  }, [page])

  const intObserver: React.MutableRefObject<IntersectionObserver | undefined> =
    useRef<IntersectionObserver>()

  const lastPostRef = useCallback(
    (post) => {
      if (loading) return

      if (intObserver.current) intObserver.current.disconnect()

      intObserver.current = new IntersectionObserver((posts) => {
        if (posts[0].isIntersecting) {
          setPage((prev: number) => prev + 1)
        }
      })
      if (post) intObserver?.current?.observe(post)
    },
    [loading, allBlogs]
  )
  useEffect(() => {
    if (!blogsPageInfo.hasNextPage) {
      if (intObserver.current) {
        intObserver.current.disconnect()
      }
    }
    return () => {
      if (intObserver.current) {
        intObserver.current.disconnect()
      }
    }
  }, [])
  const blogList = prevBlogs.map(({ node }: any, i: number) => {
    if (prevBlogs.length === i + 1) {
      return <BlogPostCard ref={lastPostRef} blog={node} key={i} />
    }
    return <BlogPostCard blog={node} key={i} />
  })

  return (
    <>
      <Header variant="landingpage" />
      <div className="blog_page">
        <h1>Blogs</h1>
        {loading && allBlogs.length === 0 ? (
          <div className="loader_div">
            <div className="loadingio-spinner-ripple-67ewwazwocp">
              <div className="ldio-dg66tpfuqso">
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <Link to={`/blogs/${latestBlog?.node?.slug}`}>
              <div className="main_blog">
                <div className="left_box">
                  <img src={latestBlog?.node?.coverImage?.url} alt="blog" />
                </div>
                <div className="right_box">
                  <div className="tags">
                    {latestBlog?.node.tags.map((tag: string, i: number) => (
                      <span key={i}>{tag}</span>
                    ))}
                  </div>
                  <h3>
                    {latestBlog?.node?.title.length > 60
                      ? `${latestBlog?.node?.title.slice(0, 60)}...`
                      : latestBlog?.node?.title}
                  </h3>
                  <p className="mobile">
                    {latestBlog?.node?.excerpt.length > 140
                      ? `${latestBlog?.node?.excerpt.slice(0, 140)}...`
                      : latestBlog?.node?.excerpt}
                  </p>
                  <p className="desktop">
                    {latestBlog?.node?.excerpt.length > 140
                      ? `${latestBlog?.node?.excerpt.slice(0, 200)}...`
                      : latestBlog?.node?.excerpt}
                  </p>
                  <div className="content_footer">
                    <span className="timestamp">{formatDate(latestBlog?.node?.publishedAt)}</span>
                    <span className="link">Read Post</span>
                  </div>
                </div>
              </div>
            </Link>
            <div className="blogs_list">{blogList}</div>
            {loading && allBlogs.length > 0 && (
              <div className="loader_div">
                <div className="loadingio-spinner-ripple-67ewwazwocp">
                  <div className="ldio-dg66tpfuqso">
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <FooterLandingPage />
    </>
  )
}

export default BlogsPage
