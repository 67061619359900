import Skeleton from 'react-loading-skeleton'
import { currencyFormatter, toFixedSimple } from 'src/utils/relisting'
import TokenForm from '../ui/form/TokenForm'

export default function BuyTokens({ realEstateId, allReData, userRole, rbacPerm }: any) {
  const tokens = allReData?.getRealEstateInfoById.tokensRemaining

  return (
    <>
      {tokens === 0 ? (
        <div className="buy-tokens -soldout">
          <div className="financials">
            <div className="header">
              <div className="title">
                {allReData && allReData.getRealEstateInfoById.address.formatted_street_address ? (
                  allReData.getRealEstateInfoById.address.formatted_street_address
                ) : (
                  <Skeleton width={150} className="title" />
                )}
              </div>
              {allReData && allReData.getRealEstateInfoById.tokensRemaining === 0 ? (
                <div className="status -soldout">Sold Out</div>
              ) : allReData && allReData.getRealEstateInfoById.tokensRemaining > 0 ? (
                <div className="status">Available</div>
              ) : (
                <Skeleton />
              )}
            </div>
            <div className="details">
              <div className="price">
                <div className="tag">
                  {allReData && allReData.getRealEstateInfoById.totalNumberOfTokens ? (
                    allReData.getRealEstateInfoById.totalNumberOfTokens
                  ) : (
                    <Skeleton />
                  )}
                </div>
                <div className="fig">Total Tokens</div>
              </div>
              <div className="price -token">
                <div className="tag">
                  {allReData && allReData.getRealEstateInfoById.tokenPrice ? (
                    currencyFormatter(toFixedSimple(allReData.getRealEstateInfoById.tokenPrice, 2))
                  ) : (
                    <Skeleton />
                  )}
                </div>
                <div className="fig">Token Price</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="buy-tokens">
          <div className="financials">
            <div className="header">
              <div className="title">
                {allReData && allReData.getRealEstateInfoById.address.formatted_street_address ? (
                  allReData.getRealEstateInfoById.address.formatted_street_address
                ) : (
                  <Skeleton width={150} className="title" />
                )}
              </div>
              {allReData && allReData.getRealEstateInfoById.tokensRemaining === 0 ? (
                <div className="status -soldout">Sold Out</div>
              ) : allReData && allReData.getRealEstateInfoById.tokensRemaining > 0 ? (
                <div className="status">Available</div>
              ) : (
                <Skeleton />
              )}
            </div>
            <div className="details">
              <div className="price">
                <div className="tag">
                  {allReData && allReData.getRealEstateInfoById.totalNumberOfTokens ? (
                    allReData.getRealEstateInfoById.totalNumberOfTokens
                  ) : (
                    <Skeleton />
                  )}
                </div>
                <div className="fig">Total Tokens</div>
              </div>
              <div className="price -token">
                <div className="tag">
                  {allReData && allReData.getRealEstateInfoById.tokenPrice ? (
                    currencyFormatter(toFixedSimple(allReData.getRealEstateInfoById.tokenPrice, 2))
                  ) : (
                    <Skeleton />
                  )}
                </div>
                <div className="fig">Token Price</div>
              </div>
            </div>
          </div>
          <TokenForm
          rbacPerm={rbacPerm} 
            tokenPrice={allReData && allReData.getRealEstateInfoById.tokenPrice}
            totalNumberOfTokens={allReData && allReData.getRealEstateInfoById.totalNumberOfTokens}
            tokensRemaining={allReData && allReData.getRealEstateInfoById.tokensRemaining}
            realEstateId={realEstateId}
          />
        </div>
      )}
    </>
  )
}
