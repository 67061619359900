import Storage from '@aws-amplify/storage'
import Auth from '@aws-amplify/auth'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { config } from 'src/utils/config/amplify'
import { CognitoIdentity } from '@aws-sdk/client-cognito-identity'
import { Hub } from 'aws-amplify'

interface IAuthUtilsProps {
  username: string
  password: string
  email: string
  family_name: string
  name: string
  code: string
  captcha: any
}

export const initUpdated = () => {
  const d = new Date()
  const seconds = Math.round(d.getTime() / 1000)
  return seconds
}

export const fetchUserToken = async () => {
  try {
    const cognitoUserTokens = await Auth.currentSession()
    const idToken = cognitoUserTokens.getIdToken().getJwtToken()
    return idToken
  } catch (error) {}
}
export const initEnhancedAuthFlow = async () => {
  let test = await fetchUserToken()

  const clientLogins = {
    'cognito-idp.us-east-1.amazonaws.com/us-east-1_oUSrvMj6K': test || '',
  }

  const cognitoParams = {
    IdentityPoolId: 'us-east-1:061a074c-9916-4326-a219-c4b00e2cd449',
  }
  const identity = new CognitoIdentity({
    region: config.cognito.REGION,
  })

  identity.getId(cognitoParams, function (err: any, identityData: any) {
    if (err) {
      return console.error(err)
    }
    const identityParams = {
      IdentityId: identityData.IdentityId,
      Logins: clientLogins,
    }

    identity.getCredentialsForIdentity(identityParams, function (errCred: any, data: any) {
      if (errCred) {
        return console.error(errCred)
      }
    })
  })
}

export const uploadConfigure = () => {
  Auth.configure({
    Auth: {
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      userPoolWebClientId: config.cognito.USER_POOL_WEB_CLIENT_ID,
      mandatorySignIn: false,
      oauth: {
        domain: config.cognito.DOMAIN,
        scope: ['email', 'openid', 'profile'],
        redirectSignIn: config.cognito.REDIRECT_SIGN_IN,
        redirectSignOut: config.cognito.REDIRECT_SIGN_OUT,
        responseType: 'code',
      },
      federationTarget: '',
    },
    Analytics: {
      disabled: true,
    },
  })

  Storage.configure({
    AWSS3: {
      bucket: config.s3.BUCKET_IMG,
      region: config.cognito.REGION,
      identityPoolId: config.s3.IDENTITY_POOL_ID,
    },
  })
}

export const SetS3Config = (bucket: any, key: any) => {
  Storage.configure({
    bucket: bucket,
    key: key,
    region: config.cognito.REGION,
    download: true,
    identityPoolId: config.s3.IDENTITY_POOL_ID,
  })
}

export async function signUp(
  { username, password, name, family_name }: IAuthUtilsProps,

  updateFormType: (arg0: string) => void,
  updateErrorMessage: Function,
  updateSuccessMessage: Function,
  refCode: string
) {
  const latestRefCode =
    localStorage.getItem('referrer_code') || (refCode.length > 0 && refCode) || null
  try {
    updateSuccessMessage(false)
    // eslint-disable-next-line
    const signupRes = await Auth.signUp({
      username,
      password,
      attributes: {
        name,
        family_name,
        'custom:referrer_code': latestRefCode,
      },
    })

    updateFormType('signIn')
    updateSuccessMessage(true)
  } catch (error) {
    updateErrorMessage(error)
    console.error(JSON.stringify(error))
  }
}

export async function signIn(
  { username, password, captcha }: IAuthUtilsProps,
  updateErrorMessage: Function
) {
  try {
    const user = await Auth.signIn(username, password, captcha)
    const userInfo = {
      username: user.username,
      ...user.attributes,
    }
    localStorage.setItem('currentUser', userInfo.username)
  } catch (error) {
    updateErrorMessage(error)
  }
}

export async function forgotPassword(
  { username }: IAuthUtilsProps,
  updateErrorMessage: Function,
  updateSuccessMgsPwdReset: Function,
  updateFormType: (arg0: string) => void
) {
  try {
    updateSuccessMgsPwdReset(false)
    await Auth.forgotPassword(username)
    updateFormType('forgotPasswordSubmit')
    updateSuccessMgsPwdReset(true)
  } catch (error) {
    updateErrorMessage(error)
  }
}

export async function forgotPasswordSubmit(
  { username, code, password }: IAuthUtilsProps,
  updateErrorMessage: Function,
  updateSuccessMgsPwd: Function,
  updateFormType: (arg0: string) => void
) {
  try {
    updateSuccessMgsPwd(false)
    await Auth.forgotPasswordSubmit(username, code, password)
    updateFormType('signIn')
    updateSuccessMgsPwd(true)
  } catch (error) {
    updateErrorMessage(error)
  }
}

export async function signOut() {
  try {
    await Auth.signOut({
      global: true,
    })
  } catch (error) {}
  localStorage.clear()
}

export async function hardSignOut() {
  try {
    await Auth.signOut({
      global: true,
    })
  } catch (error) {}
  window.localStorage.clear()
  ;(function () {
    var cookies = document.cookie.split('; ')
    for (var c = 0; c < cookies.length; c++) {
      var d = window.location.hostname.split('.')
      while (d.length > 0) {
        var cookieBase =
          encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
          '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
          d.join('.') +
          ' ;path='
        // eslint-disable-next-line no-restricted-globals
        var p = location.pathname.split('/')
        document.cookie = cookieBase + '/'
        while (p.length > 0) {
          document.cookie = cookieBase + p.join('/')
          p.pop()
        }
        d.shift()
      }
    }
  })()
}
export const getIdToken = async () => {
  const currentUserId = await (await Auth.currentSession()).getIdToken().payload.sub
  const result = (await Auth.currentSession()).getIdToken().getJwtToken()
  localStorage.removeItem('accessId')
  localStorage?.setItem('accessId', result)
  localStorage.removeItem('userId')
  localStorage?.setItem('userId', currentUserId)
  return currentUserId
}

export const getUserId = async () => {
  try {
    const dataMax = await Auth.currentAuthenticatedUser()
    const data = dataMax.signInUserSession.idToken.payload
    const userId = data.sub
    return userId
  } catch (error) {}
}

const getUserDetails = async () => {
  try {
    const dataMax = await Auth.currentAuthenticatedUser()
    // console.log('userObject', dataMax)
    const data = dataMax.signInUserSession.idToken.payload
    const userId = data.sub
    localStorage.removeItem('userId')
    localStorage.setItem('userId', userId)
  } catch (error) {}
}
const updateUserDetails = async (refCode: string) => {
  try {
    const dataMax = await Auth.currentAuthenticatedUser()
    await Auth.updateUserAttributes(dataMax, {
      'custom:referrer_code': refCode,
    })
    const data = dataMax.signInUserSession.idToken.payload
    const userId = data.sub
    localStorage.removeItem('userId')
    localStorage.setItem('userId', userId)
  } catch (error) {}
}

export async function googleSignIn(): Promise<void> {
  try {
    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    })
    Hub.listen('auth', (data) => {
      const { payload } = data
      if (payload.event === 'signIn') {
        getUserDetails()
      }
    })
  } catch (error) {
    console.error('Error during sign-in:', error)
  }
}
export function stringAvatar(name: string) {
  return {
    sx: {
      bgColor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  }
}

function stringToColor(string: string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

export default function listenForOutsideClicks(
  listening: any,
  setListening: any,
  menuRef: any,
  setOpen: any
) {
  return () => {
    if (listening) return
    if (!menuRef.current) return
    setListening(true)
    ;[`click`, `touchstart`].forEach((type) => {
      document.addEventListener(`click`, (evt) => {
        const cur = menuRef.current
        const node = evt.target
        if (cur?.contains(node)) return
        setOpen(false)
      })
    })
  }
}

//accessToken && refreshToken

export async function getAccessTokenPromise() {
  try {
    // checks if token is expired and refreshes with Cognito if needed automatically
    const session = await Auth.currentSession()
    return session.getAccessToken().getJwtToken()
  } catch (e) {
    signOut()
  }
}
