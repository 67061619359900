import { Storage } from 'aws-amplify'
import _ from 'lodash'
import React from 'react'
import { useAuth } from 'src/context/useAuth'
import { IField, ROLE } from 'src/types/relisting'
import { SetS3Config } from '../auth'
import { config } from '../config/amplify'

export const sentenceCase = (str: string) => {
  const result = str.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}
export const toInt = (s: { [key: string]: any }) => {
  Object.keys(s).forEach((k) => {
    s[k] = parseInt(s[k])
  })
  return s
}

export const updateFields = (data: IField[], newData: { [key: string]: any }, category: string) => {
  return data.map((field) => {
    const { type, options } = field
    if (type === 'select') {
      // @ts-ignore
      for (let i = 0; i < options.length; i++) {
        //@ts-ignore
        if (options[i].option_value === newData[field.id]) {
          // @ts-ignore
          options[i].selected = true
        }
      }
      options?.push({
        //@ts-ignore
        option_value: newData[field.id],
        option_label: newData[field.id],
        selected: true,
      })

      return {
        ...field,
        id: `${category}.${[field.id]}`,
        value: newData[field.id],
      }
    }

    return {
      ...field,
      id: `${category}.${[field.id]}`,
      value: newData[field.id],
    }
  })
}

export const normalizeData = (fetchedData: any) => {
  const newDataAddress: { [key: string]: any } = {
    latitude: '',
    longitude: '',
  }
  const newDataStructure: { [key: string]: any } = {
    construction_type: '',
    baths: 0,
    beds_count: 0,
    foundation_type: '',
    amenities: [],
    air_conditioning_type: '',
    architecture_type: '',
    basement_type: '',
    condition: '',
    exterior_wall_type: '',
    fireplaces: '',
    flooring_types: [],
    heating_type: '',
    parking_spaces_count: 0,
    other_features: [],
    parking_type: '',
    roof_material_type: '',
    roof_style_type: '',
    rooms_count: 0,
    stories: 0,
    units_count: 0,
    partial_baths_count: 0,
    year_built: 0,
  }

  const newDataParcel: { [key: string]: any } = {
    municipality: '',
    area_acres: 0,
    area_sq_ft: 0,
    building_count: 0,
    county_land_use_code: '',
    county_land_use_description: '',
    county_name: '',
    frontage_ft: 0,
    subdivision: '',
    zoning: '',
  }

  const newDataTaxes: { [key: string]: any } = {
    amount: 0,
    tax_year: 0,
  }
  // address
  newDataAddress.latitude = fetchedData.address.latitude
  newDataAddress.longitude = fetchedData.address.longitude

  let newData = {
    ...newDataAddress,
    ...newDataStructure,
    ...newDataParcel,
    ...newDataTaxes,
  }
  return newData
}

export const renameKey = (obj: any) => {
  let clonedObj = _.mapKeys(obj, (value, key) => sentenceCase(key))
  return clonedObj
}

export function toFixed(x: any) {
  if (Math.abs(x) < 1.0) {
    let e = parseInt(x.toString().split('e-')[1])
    if (e) {
      x *= Math.pow(10, e - 1)
      x = '0.' + new Array(e).join('0') + x.toString().substring(2)
    }
  } else {
    let e = parseInt(x.toString().split('+')[1])
    if (e > 20) {
      e -= 20
      x /= Math.pow(10, e)
      x += new Array(e + 1).join('0')
    }
  }
  return x
}

export function toFixedTrunc(x: any, n: any) {
  x = toFixed(x)

  const v = (typeof x === 'string' ? x : x.toString()).split('.')
  if (n <= 0) return v[0]
  let f = v[1] || ''
  if (f.length > n) return `${v[0]}.${f.substr(0, n)}`
  while (f.length < n) f += '0'
  return `${v[0]}.${f}`
}

export function toFixedSimple(x: any, n: number) {
  if (typeof x !== 'number' || isNaN(x)) {
    return NaN
  }
  return Number(x.toFixed(n))
}

export const currencyFormatter = (x: number | bigint) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return formatter.format(x)
}

export function downloadBlob(blob: any, filename: any) {
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = filename || 'download'
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url)
      a.removeEventListener('click', clickHandler)
    }, 150)
  }
  a.addEventListener('click', clickHandler, false)
  a.click()
  return a
}

export async function downloadContract(filename: string, docTitle: string, reLink: string) {
  SetS3Config(config.s3.BUCKET_IMG, 'public')

  let contract: Array<any> = []
  try {
    await Storage.list(`reId/${reLink}`).then((res) => {
      contract = res
    })
    const contractUrl = await Storage.get(contract[0].key, { download: true })
    downloadBlob(contractUrl.Body, `${docTitle} - ${filename}`)
  } catch (error) {}
}
export const withRole = (roles: Array<ROLE>) => (Component: React.ElementType) => (props: any) => {
  //@ts-ignore
  const { user } = useAuth()
  const userHasRequiredRole = user.userId.length > 0 && roles.includes(user.role) ? true : false
  if (userHasRequiredRole) {
    return <Component {...props} />
  }
  return null
}

export const urlSubString = (url: string) => {
  return url.substring(4, url.length)
}
export const urlSubStringPublic = (url: string) => {
  return url.substring(12, url.length)
}
