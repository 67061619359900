import mini_logo from '../../../assets/mini_logo.svg'

export const ThreeBlocksLoader = () => {
  return (
    <div className="box">
      <div className="cube">
        <div className="face top">
          <img src={mini_logo} alt="three blocks mini logo" />
        </div>
        <div className="face bottom">
          <img src={mini_logo} alt="three blocks mini logo" />
        </div>
        <div className="face left">
          <img src={mini_logo} alt="three blocks mini logo" />
        </div>
        <div className="face right">
          <img src={mini_logo} alt="three blocks mini logo" />
        </div>
        <div className="face front">
          <img src={mini_logo} alt="three blocks mini logo" />
        </div>
        <div className="face back"><img src={mini_logo} alt="three blocks mini logo"/></div>
      </div>
    </div>
  )
}
