import { useEffect, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ColumnApi, GridReadyEvent, GridApi, RowClassParams, RowStyle } from 'ag-grid-community'
import Button from 'src/components/ui/button/Button'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'

import { RiFilterLine } from 'react-icons/ri'
import { RiFilterOffLine } from 'react-icons/ri'
import { BsLayoutThreeColumns } from 'react-icons/bs'
import { BiExport } from 'react-icons/bi'
import { IconButton, Popover, Switch, Tooltip, useMediaQuery } from '@mui/material'
import { Link } from 'react-router-dom'
import { BiLinkExternal } from 'react-icons/bi'
import { AiOutlineDownload } from 'react-icons/ai'

const PortfolioGrid = ({ rowData, columnDefs, wide }: any) => {
  const isMobile = useMediaQuery('(max-width: 900px)')

  const [showFilters, setShowFilters] = useState(true)
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      filter: showFilters,
      resizable: true,

      cellClass: ['common_class'],
    }
  }, [showFilters])

  const exportToCSV = () => {
    const params = {
      skipHeader: false,
      skipFooters: true,
      skipGroups: true,
      fileName: 'export.csv',
    }
    gridApi?.exportDataAsCsv(params)
  }

  const LinkCellRenderer = (params: any, onClick: (value: any) => void) => {
    if (!params.value) return 'NA'

    return (
      <Link to={`/re/${params.data.re_id}`} tabIndex={0}>
        {params.data["Real Estate Address"]}
        <BiLinkExternal />
      </Link>
    )
  }

  const [columnStates, setColumnStates] = useState(() =>
    columnDefs.map((column: any) => ({
      field: column.field,
      checked: true,
    }))
  )

  const [columnApi, setColumnApi] = useState<ColumnApi | null>(null)
  const [gridApi, setGridApi] = useState<GridApi>()

  useEffect(() => {
    if (wide && gridApi && columnApi && gridRef.current && !isMobile) {
      columnApi.autoSizeAllColumns() // resize columns to fit content
      // gridApi.sizeColumnsToFit() // resize columns to fit available grid width
    }

    if (!wide && gridApi && columnApi && gridRef.current && !isMobile) {
      // columnApi.autoSizeAllColumns() // resize columns to fit content
      gridApi.sizeColumnsToFit() // resize columns to fit available grid width
    }

    // }
  }, [gridApi, columnApi, rowData, isMobile])

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api)
    setColumnApi(params.columnApi)
    if (rowData.length === 0) params.api.showLoadingOverlay()
  }
  const hideColumn = (columnName: string, isChecked: boolean) => {
    columnApi?.setColumnVisible(columnName, isChecked)
  }

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (field: string, isChecked: boolean) => {
    setColumnStates((prevColumnStates: any) =>
      prevColumnStates.map((columnState: any) =>
        columnState.field === field
          ? { ...columnState, checked: !columnState.checked }
          : columnState
      )
    )
    hideColumn(field, isChecked)
  }

  const getRowStyle = (params: RowClassParams<any>): RowStyle | undefined => {
    if (params.node && params.node.rowIndex !== null && params.node.rowIndex % 2 === 0) {
      return { background: 'rgba(0, 82, 204, 0.05)', border: 'none' }
    }
    if (params.node.rowIndex) {
      return {
        border: 'none',
      }
    }
    return undefined
  }
  const gridRef = useRef<AgGridReact>(null)

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const gridOptions = {
    suppressMenuHide: true,
    suppressCellFocus: true,
    suppressDragLeaveHidesColumns: true,
    enableBrowserTooltips: true,
  }
  const gridLoader =
    '<div class="loadingio-spinner-ripple-67ewwazwocp"><div class="ldio-dg66tpfuqso"><div></div><div></div></div></div>'
  return (
    <div className="regrid">
      <div className="regrid_options">
        <Button
          label="Export Csv"
          handleClick={exportToCSV}
          icon={<AiOutlineDownload style={{ marginRight: '5px' }} />}
        />

        <Tooltip title="Show/Hide filters">
          <IconButton onClick={() => setShowFilters((prev) => !prev)} className="iconButton">
            {showFilters ? <RiFilterLine size={20} /> : <RiFilterOffLine size={20} />}
          </IconButton>
        </Tooltip>
        <Tooltip title="Show/Hide Columns">
          <IconButton onClick={handleClick} aria-describedby={id} className="iconButton">
            <BsLayoutThreeColumns size={20} />
          </IconButton>
        </Tooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div className="regrid_column_toggle">
            {columnStates.map((columnState: any) => (
              <div className="toggle" key={columnState.field}>
                <label>
                  <Switch
                    checked={columnState.checked}
                    onChange={(e) => {
                      handleChange(columnState.field, e.target.checked)
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <span>{columnState.field}</span>
                </label>
              </div>
            ))}
          </div>
        </Popover>
      </div>
      {rowData.length > 0 ? (
        <div
          className="ag-theme-alpine"
          style={{ height: '85vh', width: '100%', border: 0, borderColor: 'none ' }}
        >
          <AgGridReact
            tabIndex={0}
            rowHeight={70}
            ref={gridRef}
            onGridReady={onGridReady}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            components={{
              LinkCellRenderer,
            }}
            gridOptions={gridOptions}
            overlayLoadingTemplate={gridLoader}
            overlayNoRowsTemplate={
              '<span className="ag-overlay-loading-center">No data found to display.</span>'
            }
            getRowStyle={getRowStyle}
          ></AgGridReact>
        </div>
      ) : (
        <div className="ag-theme-alpine" style={{ height: '85vh', width: '100%', border: 'none' }}>
          <AgGridReact
            tabIndex={0}
            rowHeight={40}
            ref={gridRef}
            onGridReady={onGridReady}
            rowData={['n/a']}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            components={{
              LinkCellRenderer,
            }}
            gridOptions={gridOptions}
            overlayLoadingTemplate={gridLoader}
            overlayNoRowsTemplate={
              '<span className="ag-overlay-loading-center">No data found to display.</span>'
            }
            getRowStyle={getRowStyle}
          ></AgGridReact>
        </div>
      )}
    </div>
  )
}

export default PortfolioGrid
