export const normalizePermissions = (permObj: { [key: string]: any }, newArr: Array<string>) => {
  if (permObj) {
    // eslint-disable-next-line array-callback-return
    Object.entries(permObj).flatMap((entry: any) => {
      if (entry[0] !== '__typename' && entry[1] === true) {
        newArr.push(entry[0])
      }
    })
  }
  return newArr
}
const permissions = JSON.parse(localStorage?.getItem('rbac') || '{}')
const userPermissions: Array<string> = []
export const perm = normalizePermissions(permissions, userPermissions)

export const formatGridData = (gridData: any) => {
  return gridData.map((item: any) => ({
    re_id: item?.re_id,
    'Real Estate Address': item?.add,
    'No of Tokens': item?.total_tokens_owned_by_buyer,
    'Est ROI': item?.est_roi,
    'Platform Fees': item?.platFormFees,
    'Total': item?.total_investment,
    'Initial RE Value': item?.initial_real_estate_value,
    Price: item?.current_token_price,
  }))
}





export function calculateSum(array: any[], property: string | number) {
  const total = array.reduce((accumulator, object) => {
    return accumulator + object[property];
  }, 0);

  return total;
}
