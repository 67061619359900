interface IBannerProps {
  bg: string
  info: string
  item: any
}

export default function Banner({ bg, info, item }: IBannerProps): JSX.Element {
  return (
    <div className={`banner -${bg}`}>
      <div className="info">
        <p>{info}</p>
      </div>
    </div>
  )
}
