import { FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

interface IRERadioProps {
  id: string
  label: string
  required: boolean
  value: boolean
  handleChange: Function
}
export default function RERadio({ id, value, required, handleChange }: IRERadioProps) {
  const { register, control } = useFormContext()
  return (
    <div>
      <FormLabel id={id}>Indicate Property Status</FormLabel>
      <Controller
        name={id}
        control={control}
        rules={{ required: required }}
        defaultValue={false}
        render={({ field }) => (
          <RadioGroup
            value={value}
            {...register(id, {
              onChange: (event: any) => handleChange(id, event),
            })}
          >
            <FormControlLabel value={true} control={<Radio />} label="Available" />
            <FormControlLabel value={false} control={<Radio />} label="Is Coming" />
          </RadioGroup>
        )}
      />
    </div>
  )
}
