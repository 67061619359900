import { useForm, SubmitHandler, Controller } from 'react-hook-form'

import Button from 'src/components/ui/button/Button'
import { TextField } from '@mui/material'
import { Link } from 'react-router-dom'
import { useState } from 'react'

interface IUserAgreementInputs {
  firstname: string
  lastname: string
}

const userAgreementValues = {
  firstname: '',
  lastname: '',
}

const myHelper: { [key: string]: any } = {
  firstname: {
    required: 'Firstname and Lastname is Required',
    type: 'Required',
  },
  lastname: {
    required: 'Firstname and Lastname is Required',
    type: 'Required',
  },
}

export default function UserAgreementOp({
  setActivateDownloadBtn,
  activateDownloadBtn,
}: {
  handleNextDownload?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined
  handleCloseDownload?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined

  setActivateDownloadBtn: React.Dispatch<React.SetStateAction<boolean>>
  activateDownloadBtn: boolean

  contractType: string
}) {
  // eslint-disable-next-line
  const [freezeForm, setFreezeForm] = useState(false)

  const {
    handleSubmit,
    control,
  } = useForm<IUserAgreementInputs>({
    defaultValues: userAgreementValues,
  })

  const onSubmit: SubmitHandler<IUserAgreementInputs> = (data) => {
    setActivateDownloadBtn(true)
  }

  if (freezeForm) {
    return (
      <div className="agreementform">
        <form className="authform">
          <div
            style={{
              backgroundColor: 'var(--light-3)',
            }}
          >
            <label className="label" style={{ textAlign: 'center', padding: 4 }}>
              User Details Accepted
            </label>
          </div>
        </form>
      </div>
    )
  }
  return (
    <div className="agreementform">
      <form className="authform" onSubmit={handleSubmit(onSubmit)}>
        <div className="formdetails">
          <div>
            <small>I</small>
          </div>

          <div>
            <Controller
              control={control}
              name="firstname"
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="firstname"
                  fullWidth
                  label="Firstname"
                  size="small"
                  error={error !== undefined}
                  helperText={error ? myHelper.firstname[error.type] : ''}
                />
              )}
            />
          </div>

          <div>
            <Controller
              control={control}
              name="lastname"
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="lastname"
                  fullWidth
                  label="Lastname"
                  size="small"
                  error={error !== undefined}
                  helperText={error ? myHelper.lastname[error.type] : ''}
                />
              )}
            />
          </div>

          <div>
            <small>
              agree to the <b>Terms and Conditions</b> mentioned in the Operating
              agreement.{' '}
            </small>
          </div>
        </div>

        <div className="formactions">
          <div>
            <Link to={`/market`}>
              <Button label="Cancel" />
            </Link>
          </div>

          {activateDownloadBtn ? (
            <div>
              <Button type="submit" disabled variant="disabled" label="Submit" />
            </div>
          ) : (
            <div>
              <Button type="submit" label="Submit" />
            </div>
          )}
        </div>
      </form>
    </div>
  )
}
