import { createContext, useContext, useState } from 'react'

interface IPaymentContext {
  paymentData: any
  setValues: any
}
const PaymentContext = createContext<IPaymentContext | undefined>(undefined)

export const usePayment = () => {
  const context = useContext(PaymentContext)
  if (!context) {
    throw new Error(`usePayment must be used within PaymentProvider`)
  }
  return context
}

export const PaymentProvider = (props: any) => {
  const [paymentData, setPaymentData] = useState({})

  const setValues = (values: any) => {
    setPaymentData(() => values)
  }

  return <PaymentContext.Provider value={{ paymentData, setValues }} {...props} />
}
