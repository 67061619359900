import { createSlice } from '@reduxjs/toolkit'

// eslint-disable-next-line @typescript-eslint/naming-convention
interface blogsDataState {
  blogs: any[]
  pageInfo: any
}

const initialState: blogsDataState = {
  blogs: [],
  pageInfo: {},
}

const blogsSlice = createSlice({
  name: 'blogsData',
  initialState,
  reducers: {
    addBlogs: (state, action) => {
      state.blogs = [...state.blogs, ...action.payload]
    },
    pageInfo: (state, action) => {
      state.pageInfo = action.payload
    },
  },
})

export const { addBlogs, pageInfo } = blogsSlice.actions
export default blogsSlice.reducer
