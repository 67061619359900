import { useState } from 'react'
import { reListingFinancials } from 'src/utils/data/relisting'
import { Link, useNavigate } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import Toast from 'src/components/ui/toast/Toast'
import { BsArrowLeftCircle, BsArrowRightCircle } from 'react-icons/bs'
import { useQuery } from '@apollo/client'
import { REListingReviewSkeleton } from 'src/components/ui/loader/Skeleton'
import { marqueeFinInfo, messageFin, variantFin } from 'src/utils/constants'
import { currencyFormatter, renameKey } from 'src/utils/relisting'
import Marquee from 'src/components/ui/marquee/Marquee'
import { ThreeBlocksLoader } from 'src/components/ui/loader/Loader'
import { GET_REAL_ESTATE_FINANCE_BY_RE_ID, GET_RE_BASIC_TKN } from 'src/client/queries/relisting'
export default function REListingPage4() {
  const navigate = useNavigate()
  const [formatData, setFormatData] = useState<any>({})
  const [trigger, setTrigger] = useState(true)
  const [marquee, showMarquee] = useState(false)

  const realEstateId = localStorage?.getItem('realEstateId')
  const reId = realEstateId

  const { data: addData, loading: addLoading } = useQuery(GET_RE_BASIC_TKN, {
    variables: { reId: reId },
  })
  const {
    data: finData,
    error: finError,
    loading: finLoading,
  } = useQuery(GET_REAL_ESTATE_FINANCE_BY_RE_ID, {
    variables: {
      input: { re_id: reId },
    },
    onCompleted: () => {
      const newData = finData && renameKey(finData.getRealEstateFinanceByReId)
      setFormatData(newData)
      showMarquee(!marquee)
    },
  })

  if (finError) {
    navigate('/relisting/stats')
  }
  if (finLoading || addLoading) {
    return <ThreeBlocksLoader />
  }
  if (localStorage.getItem('realEstateId') === null) {
    return <ThreeBlocksLoader />
  }

  return (
    <>
      {Object.keys(formatData).length ? (
        <>
          <AnimatePresence>
            <Toast
              toastMessage={`${messageFin.info} ${addData?.getRealEstateBasicInfoById.address.formatted_street_address}`}
              trigger={trigger}
              setTrigger={setTrigger}
              variant={variantFin.info}
            />
          </AnimatePresence>
          {marquee && <Marquee info={marqueeFinInfo} />}
          <div className="relisting-form">
            <div className="form">
              <div className="header">
                <div className="dir">
                  <Link to="/relisting/stats">
                    <BsArrowLeftCircle className="dir -listing " />
                    <button className="button -listing">Previous</button>
                  </Link>
                </div>
                <div>
                  <h5>Real Estate Listing (4/5)</h5>
                </div>
                <div className="dir">
                  <Link to="/relisting/tokens">
                    <button className="button -listing">Next</button>
                    <BsArrowRightCircle className="dir -listing" />
                  </Link>
                </div>
              </div>
              <div className="formcontent">
                {finLoading
                  ? reListingFinancials.map((field) => <REListingReviewSkeleton key={field.id} />)
                  : formatData &&
                    Object.keys(formatData).flatMap((key, id) =>
                      key !== 'Re_id' && key !== 'User_id' && key !== '__typename' ? (
                        <div className="relisting-input" key={id}>
                          <div className="details re">
                            <h3> {currencyFormatter(formatData[key])}</h3>
                            <div style={{ marginBottom: '20px ' }}>
                              <small>{key}</small>
                            </div>
                          </div>
                        </div>
                      ) : (
                        []
                      )
                    )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <ThreeBlocksLoader />
      )}
    </>
  )
}
