import { useState } from 'react'
import AuthLayout from 'src/components/layouts/AuthLayout'
import { variantWhitelist, verifyAddress, whitelistMessage } from 'src/utils/constants'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import Button from '../button/Button'
import { useMutation } from '@apollo/client'
import { UPDATE_ETHEREUM_ADDRESS } from 'src/client/mutations/auth'
import { useAuth } from 'src/context/useAuth'
import { AlertSimple } from '../alert/Alert'
import { ThreeBlocksLoader } from '../loader/Loader'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const WhitelistForm = ({ setCheckStatus, whitelistedAddress }: any) => {
  const [address, showAddress] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [disabled, setDisabled] = useState(false)
  // eslint-disable-next-line
  const [message, updateMessage] = useState('')
  const [generalLoading, setGeneralLoading] = useState(false)

  const [updateEthereumAddress, { loading: blckLoading }] = useMutation(UPDATE_ETHEREUM_ADDRESS)

  //@ts-ignore
  const { user } = useAuth()
  interface IWhitelistAddressInput {
    blockchainAddress: string
    blockchainAddressReadOnly: string
    userId: string
  }

  const whitelistValues = {
    blockchainAddress: '',
    blockchainAddressReadOnly: '',
    userId: '',
  }

  const whitelistSchema = yup.object().shape({
    blockchainAddress: yup
      .string()
      .required('Polygon Blockchain Wallet Address is a required field'),
  })

  const displayToast = (msg: any) => {
    if (!msg.includes('successfully')) {
      return toast(msg, {
        position: 'top-center',
        autoClose: 8000,
        hideProgressBar: true,
        theme: 'colored',
        type: 'error',
      })
    }
    return toast(msg, {
      position: 'top-center',
      autoClose: 8000,
      hideProgressBar: true,
      theme: 'colored',
      type: 'info',
    })
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IWhitelistAddressInput>({
    defaultValues: whitelistValues,
    mode: 'all',
    resolver: yupResolver(whitelistSchema),
  })

  const onSubmit: SubmitHandler<IWhitelistAddressInput> = (data: any) => {
    data.userId = user.sub
    setGeneralLoading(true)
    updateEthereumAddress({
      variables: { input: { user_id: data.userId, whitelist_address: data.blockchainAddress } },
      onCompleted: (_data) => {
        updateMessage(_data?.updateEthereumAddress?.message)
        displayToast(_data?.updateEthereumAddress?.message)
        if (
          _data?.updateEthereumAddress?.message ===
          'Polygon Blockchain Wallet Address successfully saved.'
        ) {
          setDisabled(true)
          setCheckStatus(true)
          setGeneralLoading(false)
        }
        if (
          _data?.updateEthereumAddress?.message ===
          'Provided Polygon blockchain wallet address is not correct, please enter the correct Address.'
        ) {
          setGeneralLoading(false)
        }
      },
      onError: () => {
        toast(
          'Provided Polygon blockchain wallet address is not correct, please enter the correct Address.',
          {
            position: 'top-center',
            autoClose: 8000,
            hideProgressBar: true,
            theme: 'colored',
            type: 'error',
          }
        )
      },
    })
    setIsDirty(false)
  }

  const toggleAddressVisibility = () => {
    showAddress(address ? false : true)
  }

  if (whitelistedAddress?.length > 0) {
    return (
      <AuthLayout variant={variantWhitelist}>
        <div className="auth-container -whitelist">
          <div className="auth-panel">
            <form className="authform -whitelist">
              <div className="details -whitelist">
                <label className="label -whitelist " htmlFor="blockchainAddress">
                  Polygon Blockchain Wallet Address
                </label>
                <div className="inputcontainer">
                  {whitelistedAddress &&
                    (address ? (
                      <input
                        className="input -whitelist"
                        placeholder={whitelistedAddress}
                        type={'text'}
                        disabled
                      />
                    ) : (
                      <input
                        className="input -whitelist"
                        placeholder="*******************************************"
                        type={'text'}
                        disabled
                      />
                    ))}
                  {address ? (
                    <AiFillEyeInvisible
                      size={16}
                      className="icon-pwd -whitelist"
                      onClick={toggleAddressVisibility}
                    />
                  ) : (
                    <AiFillEye
                      size={16}
                      className="icon-pwd -whitelist"
                      onClick={toggleAddressVisibility}
                    />
                  )}
                </div>
                <small className="helpers -info -whitelist">
                  {
                    'Polygon Blockchain wallet address is already created, please contact 3Blocks customer support to change the address.'
                  }
                </small>
              </div>
              <div className="submit -profile">
                <Button variant="blocked" disabled label="Whitelisted" />
              </div>
            </form>
          </div>
        </div>
      </AuthLayout>
    )
  }
  if (blckLoading && generalLoading) {
    return (
      <AuthLayout variant={variantWhitelist}>
        <AlertSimple alertMessage={whitelistMessage} variant="error" />
        <div className="auth-container -whitelist">
          <div className="auth-panel">
            <ThreeBlocksLoader />
            <form className="authform -whitelist" onSubmit={handleSubmit(onSubmit)}>
              <div className="details -whitelist">
                <label className="label -whitelist" htmlFor="blockchainAddress">
                  Whitelisting your Polygon Blockchain Wallet Address.
                </label>
                <div className="inputcontainer">
                  <input
                    className="input -whitelist"
                    type={address ? 'text' : 'password'}
                    {...register('blockchainAddress', {
                      onChange: () => setIsDirty(true),
                    })}
                  />
                  {address ? (
                    <AiFillEyeInvisible
                      size={16}
                      className="icon-pwd -whitelist"
                      onClick={toggleAddressVisibility}
                      color="var(--success-40)"
                    />
                  ) : (
                    <AiFillEye
                      size={16}
                      className="icon-pwd -whitelist"
                      onClick={toggleAddressVisibility}
                      color="var(--success-40)"
                    />
                  )}{' '}
                </div>
                <small className="helpers -error -whhitelist">
                  {errors && errors.blockchainAddress?.message}
                </small>
                {isDirty && <small className="helpers">{verifyAddress}</small>}
              </div>
            </form>
          </div>
        </div>
      </AuthLayout>
    )
  }
  return (
    <AuthLayout variant={variantWhitelist}>
      <AlertSimple alertMessage={whitelistMessage} variant="error" />
      <div className="auth-container -whitelist">
        <div className="auth-panel">
          <form className="authform -whitelist" onSubmit={handleSubmit(onSubmit)}>
            <div className="details -whitelist">
              <label className="label -whitelist" htmlFor="blockchainAddress">
                Whitelist your Blockchain Address
              </label>
              <div className="inputcontainer">
                <input
                  className="input -whitelist"
                  type={address ? 'text' : 'password'}
                  {...register('blockchainAddress', {
                    onChange: () => setIsDirty(true),
                  })}
                />
                {address ? (
                  <AiFillEyeInvisible
                    size={16}
                    className="icon-pwd -whitelist"
                    onClick={toggleAddressVisibility}
                    color="var(--success-40)"
                  />
                ) : (
                  <AiFillEye
                    size={16}
                    className="icon-pwd -whitelist"
                    onClick={toggleAddressVisibility}
                    color="var(--success-40)"
                  />
                )}{' '}
              </div>
              <small className="helpers -error -whitelist">
                {errors && errors.blockchainAddress?.message}
              </small>
              {isDirty && <small className="helpers -whitelist">{verifyAddress}</small>}
            </div>
            <div className="submit -profile">
              {blckLoading ? (
                blckLoading && <Button variant="success" label="Whitelisting" />
              ) : disabled ? (
                <Button variant="blocked" disabled={disabled} label="Whitelisted" />
              ) : (
                <Button variant="success" label="Whitelist" />
              )}
            </div>
          </form>
        </div>
      </div>
    </AuthLayout>
  )
}

export default WhitelistForm
