import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { FileInput } from 'src/components/ui/input/REFileInput'
import { Link, useNavigate } from 'react-router-dom'
import { BsArrowLeftCircle, BsArrowRightCircle } from 'react-icons/bs'
import { useMutation } from '@apollo/client'
import { ThreeBlocksLoader } from 'src/components/ui/loader/Loader'
import { AnimatePresence } from 'framer-motion'
import { messageImg, variantDetails } from 'src/utils/constants'
import Toast from 'src/components/ui/toast/Toast'
import { getAccessTokenPromise, SetS3Config } from 'src/utils/auth'
import Storage from '@aws-amplify/storage'
import { config } from 'src/utils/config/amplify'
import { UPLOAD_SINGLE, COMPRESS_IMG } from 'src/client/mutations/relisting'
import axios from 'axios'

export default function REListingPage2() {
  const [trigger, setTrigger] = useState(true)

  const navigate = useNavigate()

  const [uploadSingle, { data: singleData, loading: singleLoading, error: singleError }] =
    useMutation(UPLOAD_SINGLE)

  const [compressImages] = useMutation(COMPRESS_IMG)

  const realEstateId = localStorage?.getItem('realEstateId')

  async function s3UploadMany(files: File[]) {
    const results: any = []
    let stored: any = []
    try {
      files.map(async (file) => {
        stored = Storage.vault.put(`reId/${realEstateId}/${file.name}`, file, {
          contentType: file.type,
          level: 'public',
        })
      })

      results.push((await stored).key)
    } catch (error) {}
    return results
  }

  async function s3UploadManyCompress() {
    const reIdPath = `public/reId/${realEstateId}`
    const accessToken = await getAccessTokenPromise()
    try {
      await axios.post(
        `${process.env.REACT_APP_URI_REST}/ip?key=${reIdPath}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${accessToken}` || '',
          },
        }
      )
    } catch (e) {}
  }

  if (singleError) {
    navigate('/relisting/images')
  }

  const methods = useForm({
    mode: 'onBlur',
  })

  const onSubmit = methods.handleSubmit((data) => {
    const isHomePhoto = true
    const files = data.files

    const id = realEstateId

    const homeNumber = localStorage?.getItem('homeNumber')
    //@ts-ignore
    const file = data.files[homeNumber]
    try {
      uploadSingle({
        variables: { file, id, isHomePhoto },
        onCompleted: (_data) => {
          compressImages({
            variables: {
              reId: realEstateId,
            },
            onCompleted(__data) {},
          })
        },
      })

      SetS3Config(config.s3.BUCKET_IMG, 'public')
      s3UploadMany(files)
        .then((res: any) => {
          s3UploadManyCompress()
        })
        .catch((err: any) => {})
      navigate('/relisting/stats')
    } catch (error) {
      navigate('/relisting/images')
    }
  })

  if (singleLoading) {
    return <ThreeBlocksLoader />
  }

  if (localStorage.getItem('realEstateId') == null) {
    return <ThreeBlocksLoader />
  }

  return (
    <>
      <div className="refilelisting-form">
        <FormProvider {...methods}>
          <form className="file">
            <div className="header">
              <div className="dir">
                <Link to="/relisting/details">
                  <button className="button -listing">Previous</button>
                  <BsArrowLeftCircle className="dir -listing " />
                </Link>
              </div>
              <div>
                <h5>Real Estate Listing (2/5)</h5>
              </div>
              <div className="dir">
                <Link to="/relisting/stats">
                  <button className="button -listing" onClick={onSubmit}>
                    Next
                  </button>
                  <BsArrowRightCircle className="dir -listing" onClick={onSubmit} />
                </Link>
              </div>
            </div>
            <div className="filecontent">
              {singleError && (
                <>
                  <AnimatePresence>
                    <Toast
                      toastMessage={messageImg.error}
                      trigger={trigger}
                      setTrigger={setTrigger}
                      variant={variantDetails.error}
                    />
                  </AnimatePresence>
                </>
              )}
              {singleData && (
                <>
                  <AnimatePresence>
                    <Toast
                      toastMessage={messageImg.success}
                      trigger={trigger}
                      setTrigger={setTrigger}
                      variant={variantDetails.success}
                    />
                  </AnimatePresence>
                </>
              )}
              <div className="filedata">
                <FileInput
                  name="files"
                  accept="image/png,
                 image/jpeg,
                 image/tiff,
                 image/webp,
                 video/webm,
                 video/mp4,
                 video/mpeg"
                  multiple
                  mode="append"
                />
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  )
}
