import React, { useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { useAuth } from '../../context/useAuth'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { ThreeBlocksLoader } from 'src/components/ui/loader/Loader'
import { NotPermitted } from 'src/pages/main'
import { GET_USER_PERMISSIONS, GET_USER_ROLE } from 'src/client/queries/auth'

export const protectedRoute =
  (Comp: any, route = '/auth') =>
  (props: any) => {
    const navigate = useNavigate()
    async function checkAuthState() {
      try {
        await Auth.currentAuthenticatedUser()
      } catch (error) {
        navigate(route)
      }
    }

    useEffect(() => {
      checkAuthState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return <Comp {...props} />
  }

export function RequireAuth({
  children,
  permissions,
}: {
  children: JSX.Element
  permissions: any
}) {
  let location = useLocation()
  //@ts-ignore
  const { user, loader } = useAuth()

  const { loading } = useQuery(GET_USER_ROLE, {
    variables: {
      userId: user.userId,
    },
  })

  const { data: accessData, loading: accessLoading } = useQuery(GET_USER_PERMISSIONS, {
    variables: { userId: user.userId },
  })

  let filteredNormalizedUserPermissions: string | string[] = []
  if (accessData) {
    const currentUserPermissions = accessData?.getUserAccess?.rolePermission?.permission

    const normalizedUserPermissions = Object.keys(currentUserPermissions)
      .filter((key) => key !== '__typename' && currentUserPermissions[key])
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: currentUserPermissions[key],
        })
      }, {})

    filteredNormalizedUserPermissions = Object.keys(normalizedUserPermissions)
  }
  if (accessLoading) {
    return <ThreeBlocksLoader />
  }

  const userHasRequiredPermissions =
    user.userId.length > 0 && filteredNormalizedUserPermissions.includes(permissions) ? true : false

  const userHasReDetailPermission =
    user.userId.length > 0 && filteredNormalizedUserPermissions.includes('re_detail') ? true : false

  const userHasKycPermission =
    user.userId.length > 0 && filteredNormalizedUserPermissions.includes('kyc_status')
      ? true
      : false
  if (loader || loading) {
    return <ThreeBlocksLoader />
  }
  if (user.userId.length > 0 && !userHasRequiredPermissions) {
    return <NotPermitted />
  }

  if (user.userId.length > 0 && !userHasKycPermission) {
    return <Navigate to="/me/details/" />
  }
  if (user.userId.length > 0 && userHasKycPermission && !userHasReDetailPermission) {
    return <Navigate to="/me/details/" />
  }

  if (user.userId.length > 0 && !userHasKycPermission && userHasReDetailPermission) {
    return <Navigate to="/me/details/" />
  }

  if (user.userId.length > 0 && !userHasKycPermission && !userHasReDetailPermission) {
    return <Navigate to="/me/details/" />
  }

  return user.userId.length > 0 ? (
    children
  ) : (
    <Navigate to="/auth" replace state={{ path: location.pathname }} />
  )
}

export const checkPermissions = (filteredUserPermissions: Array<any>, allowedPermissions: Array<any>) => {
  if (allowedPermissions.length === 0) {
    return true
  }

  return allowedPermissions.some((permission) => filteredUserPermissions.includes(permission))
}
export const checkAllPermissions = (filteredUserPermissions: Array<any>, allowedPermissions: Array<any>) => {
  if (allowedPermissions.length === 0) {
    return true
  }

  return allowedPermissions.every((permission) => filteredUserPermissions.includes(permission))
}

export function AccessControl({
  children,
  filteredUserPermissions,
  allowedPermissions,
  renderNoAccess,
}: {
  children: React.ReactNode
  filteredUserPermissions: Array<any>
  allowedPermissions: Array<any>
  renderNoAccess: Function
}) {
  const permitted = checkPermissions(filteredUserPermissions, allowedPermissions)
  if (permitted) {
    return children
  }
  
  return renderNoAccess()
}

export function AccessControlAll({
  children,
  filteredUserPermissions,
  allowedPermissions,
  renderNoAccess,
}: {
  children: React.ReactNode
  filteredUserPermissions: Array<any>
  allowedPermissions: Array<any>
  renderNoAccess: Function
}) {
  const permitted = checkAllPermissions(filteredUserPermissions, allowedPermissions)
  if (permitted) {
    return children
  }
  
  return renderNoAccess()
}



