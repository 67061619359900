import { Fragment, useState } from 'react'
import Navbar from '../ui/nav/Navbar'
import Sidebar from '../ui/nav/Sidebar'
import Hero from './Hero'

export default function Header({ variant }: any): JSX.Element {
  const [showSidebar, setShowSidebar] = useState(false)

  const closeSidebar = () => {
    setShowSidebar(false)
  }

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar)
  }

  return (
    <>
      <Navbar variant={variant} toggleSidebar={toggleSidebar} showSidebar={showSidebar} />
      <Sidebar variant={variant} isOpen={showSidebar} closeSidebar={closeSidebar} />
    </>
  )
}
