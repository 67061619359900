import { gql } from '@apollo/client'

export const GET_APPLICANT_BY_ID = gql`
  query GetApplicantById($applicantId: String!) {
    getApplicantById(applicantId: $applicantId)
  }
`
export const GET_WORKFLOW_RUN_ID = gql`
  query GetWorkFlowRunId($applicantId: String!, $workflowId: String!, $userId: String!) {
    getWorkFlowRunId(applicant_id: $applicantId, user_id: $userId, workflow_id: $workflowId) {
      id
      applicant_id
    }
  }
`