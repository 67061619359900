import { AiFillInfoCircle } from 'react-icons/ai'

interface IAlertProps {
  alertMessage?: string
  variant?: string
  modifier?: string
}

const Alert = ({ alertMessage, variant, modifier }: IAlertProps) => {
  return (
    <div className={`alert -${variant} -${modifier}`}>
      <div className="content">
        <AiFillInfoCircle className="icon" size={16} />
        <div>{alertMessage}</div>
      </div>
    </div>
  )
}
export const AlertSimple = ({ alertMessage, variant, modifier }: IAlertProps) => {
  return (
    <div className={`alert -${variant} -${modifier}`}>
      <div className="content">
        <div>{alertMessage}</div>
      </div>
    </div>
  )
}

export const AlertBanner = ({ alertMessage, variant, modifier }: IAlertProps) => {
  return (
    <div className={`alertbanner -${variant} -${modifier}`}>
      <div className="content">
        <div>{alertMessage}</div>
      </div>
    </div>
  )
}

export default Alert
