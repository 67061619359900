import { useLocation } from 'react-router-dom'
import RealEstateLayout from 'src/components/layouts/RealEstateLayout'
import Header from 'src/components/sections/Header'
import PropertyDetails from 'src/components/sections/PropertyDetails'
import PropertyMisc from 'src/components/sections/PropertyMisc'
import TokenDialog from 'src/components/sections/TokenDialog'
import Carousel from 'src/components/ui/carousel/Carousel'
// import { useAuth } from 'src/context/useAuth'
import { useQuery } from '@apollo/client'
import { GET_ALL_RE_BY_ID } from 'src/client/queries/relisting'
import { ThreeBlocksLoader } from 'src/components/ui/loader/Loader'
import { urlSubStringPublic } from 'src/utils/relisting'
import FooterLandingPage from 'src/components/sections/FooterLandingPage'
import { useRefCode } from 'src/context/useRefCode'
import { useEffect } from 'react'

const RealEstatePublic = () => {
  //@ts-ignore
  // const { user } = useAuth()
  const location = useLocation()
  const { pathname }: any = location
  const realEstateId = urlSubStringPublic(pathname)

  const { data: allReData, loading: allReLoading } = useQuery(GET_ALL_RE_BY_ID, {
    variables: { reId: realEstateId },
  })

  const { refCode, setRefCodeInLocalStorage } = useRefCode()

  useEffect(() => {
    if (refCode && refCode.length > 0) setRefCodeInLocalStorage(refCode)
  }, [refCode])

  // const { data: ppmData, loading: ppmLoading } = useQuery(GET_PPM_OP_AGREEMENT, {
  //   variables: { reId: realEstateId, userId: user.userId },
  // })

  // const { loading: accessLoading } = useQuery(GET_USER_PERMISSIONS_AND_ROLE, {
  //   variables: {
  //     userId: user.userId,
  //   },
  // })

  if (allReLoading) return <ThreeBlocksLoader />

  return (
    <>
      <RealEstateLayout>
        <Header variant="standard" />
        <TokenDialog realEstateId={realEstateId} allReData={allReData} />
        <Carousel realEstateId={realEstateId} />
        <PropertyDetails realEstateId={realEstateId} allReData={allReData} />
        <PropertyMisc realEstateId={realEstateId} allReData={allReData} />
      </RealEstateLayout>
      <FooterLandingPage />
    </>
  )
}

export default RealEstatePublic
