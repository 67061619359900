import { BiLinkExternal } from 'react-icons/bi'
import { Link } from 'react-router-dom'

type Props = {}
const NotPermitted = (props: Props) => {
  return (
    <>
      <Link to="/me/details/">
        <div
          className="notfoundpage"
          style={{
            textAlign: 'center',
          }}
        >
          <div className="access">
            <h5>
              In order to buy a Real Estate, please complete KYC and whitelist POLYGON blockchain
              address
            </h5>
            <div>
              Verify your Account{' '}
              <span>
                <BiLinkExternal />
              </span>{' '}
            </div>
          </div>
        </div>
      </Link>
    </>
  )
}

export default NotPermitted
