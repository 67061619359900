import { financials, propertyDetails } from 'src/utils/constants'
import Accordion from '../ui/accordion/Accordion'
import CardPropertyDetails from '../ui/card/CardPropertyDetails'

interface IPropertyDetails {
  realEstateId: string
  allReData: any
}

const PropertyDetails = ({ allReData }: IPropertyDetails) => {
  return (
    <div className="property">
      {allReData && <CardPropertyDetails heading={propertyDetails} propertyData={allReData} />}
      {allReData && <Accordion heading={financials} variant="success" propertyData={allReData} />}
    </div>
  )
}

export default PropertyDetails
