import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

interface IButtonInterface {
  label?: string
  variant?: string
  type?: 'button' | 'submit' | 'reset' | undefined
  disabled?: boolean
  handleClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  icon?: React.ReactNode
  size?: string
  radius?: string
  children?: React.ReactNode
  loading?: boolean
}
const Button = ({
  label,
  type,
  variant,
  size,
  radius,
  handleClick,
  icon,
  disabled,
  children,
  loading,
}: IButtonInterface) => {
  const btnClass = `button -${variant} ${size} ${radius}`
  return (
    <button type={type} className={btnClass} onClick={handleClick} disabled={disabled}>
      {loading && <CircularProgress sx={{ color: 'white' }} size={14} />}
      {icon && icon}
      {!loading && label}
      {children}
    </button>
  )
}

export default Button
