export interface INavListProps {
  label: string
  class?: string
  href?: string
}

export const LANDING_LIST : Array<INavListProps> =[
  { 
    label: 'View Properties', href: '/market/'
  }
]
export const NAV_LIST: Array<INavListProps> = [
  {
    label: 'Marketplace',
    href: '/market',
  },
]
export const NAV_LIST_USER: Array<INavListProps> = [
  {
    label: 'Marketplace',
    href: '/market/',
  },
  {
    label: 'RE Listing',
    href: '/relisting/',
  },
]
export const NAV_LIST_SUB_USER: Array<INavListProps> = [
  {
    label: 'Marketplace',
    href: '/market/',
  },

  {
    label: 'RE Listing',
    href: '/relisting/',
  },
  {
    label: 'Profile',
    href: '/me/details',
  },
]
export const NAV_LIST_SUB: Array<INavListProps> = [
  {
    label: 'Marketplace',
    href: '/market/',
  },
]

export const USER_LIST: Array<INavListProps> = [
  {
    label: 'Profile',
    href: '/me/details',
  },
  {
    label: 'Portfolio',
    href: '/me/portfolio',
  },
]

export const ONBOARD_LIST: Array<INavListProps> = [
  {
    label: 'Twitter',
    class: 'sign',
  },
  {
    label: 'LInkedIn',
    class: 'sign',
  },
  {
    label: 'Login',
    class: 'SignUp',
  },
]

export const RE_DETAILS = [
  {
    label: 'MetaverseInfo',
    href: '#metaverseinfo',
  },
  { label: 'Address', href: '#address' },
  { label: 'Structure', href: '#structure' },
  { label: 'Parcel', href: '#parcel' },
  { label: 'Taxes', href: '#taxes' },
  { label: 'Restart', href: '#address' },
]
