import { useEffect, useState } from 'react'
import ProfileLayout from 'src/components/layouts/ProfileLayout'
import { Link } from 'react-router-dom'
import { USER_LIST } from 'src/utils/data/header'
import { Avatar } from '@mui/material'
import { BiLinkExternal } from 'react-icons/bi'
import { signOut, stringAvatar } from 'src/utils/auth'
import Header from 'src/components/sections/Header'
import { useAuth } from 'src/context/useAuth'
import WhitelistForm from '../../components/ui/form/WhitelistForm'
import { verifyMsg } from 'src/utils/constants'
import { GET_USER_PERMISSIONS } from 'src/client/queries/auth'
import { useLazyQuery } from '@apollo/client'
import { ThreeBlocksLoader } from 'src/components/ui/loader/Loader'
import FooterLandingPage from 'src/components/sections/FooterLandingPage'
import { AlertBanner } from 'src/components/ui/alert/Alert'
export interface IProfileProps {}

export default function Profile(props: IProfileProps) {
  const [checkStatus, setCheckStatus] = useState(false)
  const [whitelistedAddress, setWhitelistedAddress] = useState('')
  const [checkKyc, setCheckKyc] = useState(false)

  //@ts-ignore
  const { user } = useAuth()

  const [getUserAccess, { loading: newAccessLoading }] = useLazyQuery(GET_USER_PERMISSIONS, {
    variables: {
      userId: localStorage?.getItem('currentUser') || localStorage?.getItem('userId'),
    },
    onCompleted: (_data) => {
      setWhitelistedAddress(_data?.getUserAccess?.whitelist_address)
      setCheckKyc(_data?.getUserAccess?.rolePermission?.permission.kyc_status)
      localStorage?.removeItem('rbac')
      localStorage.setItem('rbac', JSON.stringify(_data?.getUserAccess?.rolePermission?.permission))
    },
  })
  useEffect(() => {
    getUserAccess()
  }, [getUserAccess, checkStatus, checkKyc])

  if (newAccessLoading) {
    return <ThreeBlocksLoader />
  }

  return (
    <>
      <Header user={user} />
      <AlertBanner alertMessage={verifyMsg} modifier="sm" />

      <ProfileLayout>
        <div className="menu">
          <ul className="menu__list">
            {USER_LIST.map((nav: any) => (
              <li key={nav.label} onClick={nav.handler} className="menu__list__item">
                <Link key={nav.label} to={`${nav.href}`}>
                  {nav.label}
                </Link>
              </li>
            ))}
            <li key="signout" className="menu__list__item" onClick={signOut}>
              <Link to="/">Signout</Link>
            </li>
          </ul>
        </div>
        <div className="personal">
          <div className="status">
            <div className="status__avatar">
              <Link to="/">
                <Avatar
                  {...stringAvatar(`${user.name} ${user.family_name}`)}
                  sx={{ width: 50, height: 50 }}
                ></Avatar>
              </Link>
              {checkKyc ? (
                <div className="tag -verified">Verified</div>
              ) : (
                <Link to="/verify">
                  <div className="status__verify">
                    Verify your Account{' '}
                    <span>
                      <BiLinkExternal />
                    </span>{' '}
                  </div>
                </Link>
              )}
            </div>
            <div className="status__data">
              <div>{user?.name}</div>
              <div>{user?.family_name}</div>
              <div>{user?.email}</div>
            </div>
          </div>
          <WhitelistForm setCheckStatus={setCheckStatus} whitelistedAddress={whitelistedAddress} />
        </div>
      </ProfileLayout>
      <FooterLandingPage />
    </>
  )
}
