import { createContext, useContext, useState } from 'react'

const RefCodeContext = createContext<{
  refCode: string
  setAndStoreRefCode: (code: string) => void
  setRefCodeInLocalStorage: (code: string) => void
}>({
  refCode: '',
  setAndStoreRefCode: () => {},
  setRefCodeInLocalStorage: () => {},
})

function RefCodeProvider(props: any) {
  const [refCode, setRefCode] = useState<string>('')

  const setAndStoreRefCode = (code: string) => {
    if (code && code.length > 0) {
      setRefCode(code)
      // localStorage.setItem('referrer_code', code)
    }
  }

  const setRefCodeInLocalStorage = (code: string) => {
    if (code && code.length > 0) {
      localStorage.setItem('referrer_code', code)
    }
  }

  return (
    <RefCodeContext.Provider value={{ refCode, setAndStoreRefCode, setRefCodeInLocalStorage }}>
      {props.children}
    </RefCodeContext.Provider>
  )
}

function useRefCode() {
  const context = useContext(RefCodeContext)
  if (context === undefined) {
    throw new Error('useRefCode must be used within a RefCodeProvider')
  }
  return context
}

export { RefCodeProvider, useRefCode }
