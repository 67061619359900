import { useEffect, useState } from 'react'
import { init } from 'onfido-sdk-ui'
import { useLazyQuery, useMutation } from '@apollo/client'
import { ThreeBlocksLoader } from 'src/components/ui/loader/Loader'
import { GENERATE_SDK_TOKEN } from 'src/client/mutations/verify'
import { onfidoConfig } from 'src/utils/config/onfido'
import { GET_WORKFLOW_RUN_ID } from 'src/client/queries/verify'
import { useNavigate } from 'react-router-dom'
import { kycSuccess, ONFIDO_TIMEOUT } from 'src/utils/constants'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

interface IVerifyUserOrchProps {}

const VerifyUserOrch = (props: IVerifyUserOrchProps) => {
  const [loader, setLoader] = useState(false)
  const [onfidoInstance, setOnfidoInstance] = useState()
  const [generateSdkToken] = useMutation(GENERATE_SDK_TOKEN)

  const applicantId = localStorage?.getItem('applicantData')
  const currentUserId = localStorage?.getItem('userId')

  const navigate = useNavigate()
  const [getWorkFlowRunId] = useLazyQuery(GET_WORKFLOW_RUN_ID, {
    variables: {
      applicantId: applicantId,
      userId: currentUserId,
      workflowId: onfidoConfig.workflowId,
    },
  })

  useEffect(() => {
    generateSdk()
    initWorkflow()

    return () => {
      //@ts-ignore
      onfidoInstance && onfidoInstance.tearDown()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const displayKycSuccess = () => {
    return toast(kycSuccess, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      theme: 'colored',
      type: 'info',
    })
  }

  const generateSdk = () => {
    generateSdkToken({
      variables: {
        inputs: {
          applicantId: applicantId,
          referrer: '*',
        },
      },
      onCompleted: (data) => {
        const applicantToken = data.generateSdkToken
        localStorage.setItem('applicantToken', applicantToken)
      },
    })
  }

  const initWorkflow = async () => {
    const workflowData = (await getWorkFlowRunId()).data
    const workFlowRunId = workflowData?.getWorkFlowRunId.id
    if (localStorage.getItem('applicantToken') !== null) {
      const appToken = localStorage.getItem('applicantToken')
      const instance = init({
        //disable web-sdk-analytics cookie
        disableAnalyticsCookies: true,
        disableAnalytics: true,
        useModal: false,
        //@ts-ignore
        token: appToken,

        //@ts-ignore
        workflowRunId: workFlowRunId,
        customUI: {
          colorBackgroundButtonPrimary: '#0052cc',
          colorBorderButtonPrimary: '#0052cc',
          colorBackgroundButtonPrimaryHover: '#1a63d1',
          colorBackgroundButtonPrimaryActive: '#004ab8',
          borderWidthSurfaceModal: '2px',
        },
        onComplete: (data: any) => {
          displayKycSuccess()
          setTimeout(() => {
            instance.tearDown()
            navigate('/market')
          }, ONFIDO_TIMEOUT)
        },
        onUserExit: (userExitCode) => {},
        onError: (err) => {},
        steps: [
          {
            type: 'welcome',
            options: {
              title: 'In order to complete KYC, please prepare as suggested below.',
              descriptions: [
                'This process should only take a few minutes',
                'Use your device to take a photograph of the following:',
                '• Your identity document (e.g passport, driving licence, identity card, etc)',
                '• Selfie (i.e your face - please remove hat, sunglasses - anything that might obscure your photo)',
              ],
            },
          },

          'document',
          'face',
          {
            type: 'complete',
            options: {
              message: 'Congratulations',
              submessage: `Your document and photo were successfully uploaded.
                `,
            },
          },
        ],
      })

      //@ts-ignore
      setOnfidoInstance(instance)
    } else {
      setLoader(true)
    }
  }

  return (
    <>
      <div className="orch">
        <div id="onfido-mount">{loader && <ThreeBlocksLoader />}</div>
      </div>
    </>
  )
}

export default VerifyUserOrch
