import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

export default function Portal(props: any) {
  const [node] = useState(document.createElement('div'))

  const removeNode = () => {
    if (document.querySelector('#portal')?.children.length) {
      document.querySelector('#portal')?.childNodes[0].remove()
    }
  }

  useEffect(() => {
    if (props.isModalOpen) {
      document.querySelector('#portal')?.appendChild(node).classList.add('portal')
    } else {
      removeNode()
    }
    return () => removeNode()
  }, [node, props])

  return ReactDOM.createPortal(props.children, node)
}
