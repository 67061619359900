// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-CountrySelector-container{-webkit-box-flex:1;-webkit-flex:1 1 auto;flex:1 1 auto;padding-top:1em}.onfido-sdk-ui-CountrySelector-descriptionText,.onfido-sdk-ui-CountrySelector-fallbackText,.onfido-sdk-ui-CountrySelector-label{font-size:var(--onfido-font-size-small)}.onfido-sdk-ui-CountrySelector-errorContainer{margin-top:.5em;display:-webkit-box;display:-webkit-flex;display:flex}.onfido-sdk-ui-CountrySelector-fallbackText{font-weight:bold}.onfido-sdk-ui-CountrySelector-fallbackLink{display:inline-block;font-size:inherit}.onfido-sdk-ui-CountrySelector-icon{display:inline-block;height:.8125em;width:.8125em;margin-right:.5em}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_animation_duration": "200ms",
	"container": "onfido-sdk-ui-CountrySelector-container",
	"descriptionText": "onfido-sdk-ui-CountrySelector-descriptionText",
	"fallbackText": "onfido-sdk-ui-CountrySelector-fallbackText",
	"label": "onfido-sdk-ui-CountrySelector-label",
	"errorContainer": "onfido-sdk-ui-CountrySelector-errorContainer",
	"fallbackLink": "onfido-sdk-ui-CountrySelector-fallbackLink",
	"icon": "onfido-sdk-ui-CountrySelector-icon"
};
export default ___CSS_LOADER_EXPORT___;
