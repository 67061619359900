import React, { useState, useEffect } from 'react'
import { reListingToken } from 'src/utils/data/relisting'
import { useForm, FormProvider } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import REForm from '../../components/ui/form/REForm'
import { BsArrowLeftCircle, BsArrowRightCircle } from 'react-icons/bs'
import { IField } from 'src/types/relisting'
import { useMutation } from '@apollo/client'
import { UPDATE_SMART_CONTRACT } from 'src/client/mutations/relisting'
import Portal from 'src/components/ui/portal/Portal'
import Marquee from 'src/components/ui/marquee/Marquee'
import { marqueeInfo, marqueeSuccess } from 'src/utils/constants'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import dayjs from 'dayjs'
import { GET_RE_INFO_MKT } from 'src/client/queries/relisting'

export default function REListingTokens() {
  const navigate = useNavigate()
  const [tokenFields, setTokenFields] = useState<IField[]>([])
  const [isModalOpen, setModalState] = useState(false)
  const [isSuccessModalOpen, setSuccessModalState] = useState(false)
  const showModal = () => {
    setModalState(!isModalOpen)
  }
  const showSuccessModal = () => setSuccessModalState(!isSuccessModalOpen)
  const hideModal = () => setModalState(false)
  const [updateSmartContract] = useMutation(
    UPDATE_SMART_CONTRACT,
    {
      refetchQueries: [
        {
          query: GET_RE_INFO_MKT,
        },
      ],
    }
  )
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const reId = localStorage?.getItem('realEstateId')

  useEffect(() => {
    setTokenFields(reListingToken)
  }, [tokenFields])

  const handleChange = (idx: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const newTokenFields = [...tokenFields]
    newTokenFields.forEach((field) => {
      const { id, type, value } = field
      if (idx === id) {
        switch (type) {
          case 'date':
            field.value = value
            break

          default:
            field.value = event.target.value
            break
        }
      }
      setTokenFields(newTokenFields)
    })
  }

  const onSubmit = (tokenData: any) => {
    tokenData.reId = reId
    tokenData.closingDate = dayjs(tokenData.closingDate).format('MM-DD-YYYY HH:mm:ss')
    tokenData.offeringMemorandumDate = dayjs(tokenData.offeringMemorandumDate).format(
      'MM-DD-YYYY HH:mm:ss'
    )
    updateSmartContract({
      variables: tokenData,
      onCompleted: (data) => {
        showModal()
      },
      onError: (error) => {
        navigate('/relisting/tokens')
      },
    })
  }

  const methods = useForm({
    criteriaMode: 'all',
  })

  return (
    <>
      <Marquee info={marqueeInfo} />
      <div className="relisting-form">
        <FormProvider {...methods}>
          <form className="form" onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="header">
              <div className="dir">
                <Link to="/relisting/financials">
                  <BsArrowLeftCircle className="dir -listing " />
                  <button className="button -listing">Previous</button>
                </Link>
              </div>
              <div>
                <h5>Real Estate Listing (5/5)</h5>
              </div>
              <div className="dir">
                <button className="button -listing" onClick={methods.handleSubmit(onSubmit)}>
                  Submit
                </button>
                <BsArrowRightCircle
                  className="dir -listing"
                  onClick={methods.handleSubmit(onSubmit)}
                />
              </div>
            </div>
            <div className="formcontent">
              {tokenFields.map((field) => (
                <REForm key={field.id} field={field} handleChange={handleChange} />
              ))}
            </div>
          </form>
        </FormProvider>

        <Portal
          isModalOpen={isModalOpen}
          hideModal={hideModal}
          isSuccessModalOpen={isSuccessModalOpen}
        >
          <div className={`modal ${isModalOpen ? 'open' : ''}`}>
            <div className={`content -large -${isSuccessModalOpen ? 'success' : ''}`}>
              <div className="info">
                <div>
                  <p>{marqueeInfo[0]}</p>
                  <p>{marqueeInfo[1]}</p>
                </div>
                <div className="remove-icon">
                  <AiOutlineCloseCircle onClick={hideModal} />
                </div>
              </div>
              <div className="options">
                <button className="button -success" onClick={hideModal}>
                  Review
                </button>
                <button className="button -success" onClick={showSuccessModal}>
                  Submit
                </button>
              </div>
            </div>
            <div></div>
            <div className={`content -large -${isSuccessModalOpen ? 'success' : ''}`}>
              <div className="info">
                <div>
                  <p>{marqueeSuccess}</p>
                </div>
              </div>
              <div className="options">
                <Link
                  to="/
                 "
                >
                  <button className="button -success" onClick={hideModal}>
                    Okay
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </Portal>
      </div>
    </>
  )
}
