import { useState } from 'react'
import Button from '../button/Button'
import { MdExpandLess, MdExpandMore } from 'react-icons/md'
import Skeleton from 'react-loading-skeleton'
import { currencyFormatter, toFixedTrunc } from 'src/utils/relisting'

interface IAccordionProps {
  heading: string
  variant?: string
  propertyData?: any
}

const Accordion = ({ heading, variant, propertyData }: IAccordionProps) => {
  const [expanded, setExpanded] = useState(false)
  const [expandedExp, setExpandedExp] = useState(false)

  const toggleExpanded = () => {
    setExpanded((prevExpanded) => !prevExpanded)
  }
  const toggleExpandedExp = () => {
    setExpandedExp((prevExpandedExp) => !prevExpandedExp)
  }
  const accordionClass = `accordion -${variant}`
  return (
    <div className={accordionClass}>
      <div className="title">
        <h4>{heading}</h4>
      </div>
      <div className="info-property">
        <div className="subtotal">
          <div>
            Total Investments
            {expanded ? (
              <span>
                <Button
                  handleClick={toggleExpanded}
                  variant="simple"
                  icon={<MdExpandLess color="var(--bg)" />}
                />
              </span>
            ) : (
              <span>
                <Button
                  handleClick={toggleExpanded}
                  variant="simple"
                  icon={<MdExpandMore color="var(--bg)" />}
                />
              </span>
            )}
          </div>
          <div>
            {propertyData && propertyData.getRealEstateInfoById.totalInvestments ? (
              currencyFormatter(propertyData.getRealEstateInfoById.totalInvestments)
            ) : (
              <Skeleton />
            )}
          </div>
        </div>

        {expanded && (
          <>
            <div className="stats">
              <div>Asset Price</div>
              <div>
                {propertyData && propertyData.getRealEstateInfoById.assetPrice ? (
                  currencyFormatter(propertyData.getRealEstateInfoById.assetPrice)
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
            <div className="stats">
              <div>Reserve Funds</div>
              <div>
                {propertyData && propertyData.getRealEstateInfoById.expectedReserveFunds ? (
                  currencyFormatter(propertyData.getRealEstateInfoById.expectedReserveFunds)
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
            <div className="stats">
              <div>Renovation Costs</div>
              <div>
                {propertyData && propertyData.getRealEstateInfoById.renovationCosts ? (
                  currencyFormatter(propertyData.getRealEstateInfoById.renovationCosts)
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
            <div className="stats">
              <div>Closing Cost</div>
              <div>
                {propertyData && propertyData.getRealEstateInfoById.closingCost ? (
                  currencyFormatter(propertyData.getRealEstateInfoById.closingCost)
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
          </>
        )}
        <div className="stats">
          <div>Platform Fees</div>
          <div>
            +{' '}
            {propertyData && propertyData.getRealEstateInfoById.platformListingFees ? (
              currencyFormatter(propertyData.getRealEstateInfoById.platformListingFees)
            ) : (
              <Skeleton />
            )}
          </div>
        </div>
        <div className="total">
          <div>Listing Price</div>
          <div>
            {propertyData && propertyData.getRealEstateInfoById.listingPrice ? (
              currencyFormatter(propertyData.getRealEstateInfoById.listingPrice)
            ) : (
              <Skeleton />
            )}
          </div>
        </div>
      </div>
      <div className="info-property">
        <div className="subtotal">
          <div>
            Expenses
            {expandedExp ? (
              <span>
                <Button
                  handleClick={toggleExpandedExp}
                  variant="simple"
                  icon={<MdExpandLess color="var(--bg)" />}
                />
              </span>
            ) : (
              <span>
                <Button
                  handleClick={toggleExpandedExp}
                  variant="simple"
                  icon={<MdExpandMore color="var(--bg)" />}
                />
              </span>
            )}
          </div>
          <div>
            {propertyData && propertyData.getRealEstateInfoById.expenses ? (
              currencyFormatter(propertyData.getRealEstateInfoById.expenses)
            ) : (
              <Skeleton />
            )}
          </div>
        </div>

        {expandedExp && (
          <>
            <div className="stats">
              <div>Property Taxes</div>
              <div>
                {propertyData && propertyData.getRealEstateInfoById.propertyTaxes ? (
                  currencyFormatter(propertyData.getRealEstateInfoById.propertyTaxes)
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
            <div className="stats">
              <div>Insurance</div>
              <div>
                {propertyData && propertyData.getRealEstateInfoById.insurance ? (
                  currencyFormatter(propertyData.getRealEstateInfoById.insurance)
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
            <div className="stats">
              <div>Utilities</div>
              <div>
                {propertyData && propertyData.getRealEstateInfoById.utilities ? (
                  currencyFormatter(propertyData.getRealEstateInfoById.utilities)
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>

            <div className="stats">
              <div>Property Management Fees</div>
              <div>
                {propertyData && propertyData.getRealEstateInfoById.propertyMgtFees ? (
                  currencyFormatter(propertyData.getRealEstateInfoById.propertyMgtFees)
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
            <div className="stats">
              <div>AirBnb/VRBO fees</div>
              <div>
                {propertyData && propertyData.getRealEstateInfoById.maintenanceExpense ? (
                  currencyFormatter(propertyData.getRealEstateInfoById.maintenanceExpense)
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
            <div className="stats">
              <div>Rent Processing Fees</div>
              <div>
                {propertyData && propertyData.getRealEstateInfoById.platformFees ? (
                  currencyFormatter(propertyData.getRealEstateInfoById.platformFees)
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
          </>
        )}
      </div>

      <div className="info-property">
        <div className="subtotal">
          <div>Est. Gross Rent per Property</div>
          <div>
            {propertyData && propertyData.getRealEstateInfoById.expectedGrossRentPerProperty ? (
              currencyFormatter(propertyData.getRealEstateInfoById.expectedGrossRentPerProperty)
            ) : (
              <Skeleton />
            )}
          </div>
        </div>
        <div className="subtotal">
          <div>Est. Net Rent per Property</div>
          <div>
            {propertyData && propertyData.getRealEstateInfoById.expectedNetRentPerProperty ? (
              currencyFormatter(propertyData.getRealEstateInfoById.expectedNetRentPerProperty)
            ) : (
              <Skeleton />
            )}
          </div>
        </div>

        <div className="subtotal">
          <div>Est. Net Rent Per Token</div>
          <div>
            {propertyData && propertyData.getRealEstateInfoById.netRentPerToken ? (
              `$${toFixedTrunc(propertyData.getRealEstateInfoById.netRentPerToken, 2)}`
            ) : (
              <Skeleton />
            )}
          </div>
        </div>
        <div className="total">
          <div>Est. Net ROI</div>
          <div>
            {propertyData && propertyData.getRealEstateInfoById.expectedNetRoi ? (
              `${toFixedTrunc(propertyData.getRealEstateInfoById.expectedNetRoi, 2)}%
             `
            ) : (
              <Skeleton />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Accordion
