import HomePageLayout from 'src/components/layouts/HomepageLayout'
import FooterLandingPage from 'src/components/sections/FooterLandingPage'
import Header from 'src/components/sections/Header'
import {  ArrowUp,  } from 'src/components/ui/icon/Icon'
import { scrollToTop } from 'src/utils/commonUtils'





export default function FAQ(): JSX.Element {
  return (
    <HomePageLayout variant="about">
      <Header variant="aboutpage" />

      <div className="terms">
        <div className="pointer" onClick={scrollToTop}>
          <ArrowUp color="var(--main-1)" size={30} />
        </div>

        <div className="info ">
          <div className="docblock">
            <div className="doctitle -left">How will I get the ownership documents?</div>
            <div className="docdetail">
              As the real estate is held in a limited liability company, you will receive a digital
              document stating the fractional ownership in this limited liability company.
            </div>
          </div>
          <div className="docblock">
            <div className="doctitle -left">
              Should I be a citizen of the United States to invest in the real estate?
            </div>
            <div className="docdetail">
              No, you should be a resident of legally approved countries to invest in United States
              real estate through limited liability companies.
            </div>
          </div>
          <div className="docblock">
            <div className="doctitle -left">
              Can I make an investment without having any idea about digital currencies?
            </div>
            <div className="docdetail">
              Yes! You can make your investment in dollars as well. However, you need to have crypto
              wallet to receive rents.
            </div>
          </div>
          <div className="docblock">
            <div className="doctitle -left">
              {' '}
              Can I withdraw the funds from my wallet to a bank account?
            </div>
            <div className="docdetail">
              Yes, you can convert crypto to fiat using exchanges and withdraw to your bank account.
            </div>
          </div>
          <div className="youtubelink">
            <div className="youtube-embed">
              <iframe
                height="390"
                src="https://www.youtube.com/embed/yakkPYesnA8?si=dItZ1kh2RcoVMjLI"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <FooterLandingPage />
    </HomePageLayout>
  )
}
