import { useFormContext, Controller } from 'react-hook-form'
import { TextField } from '@mui/material'

interface IREInputProps {
  id: string
  label: string
  required: boolean
  maxlength?: number
  type: string
  variant: string
  value: number
  handleChange: Function
  readonly: boolean
}

export default function REInputNumber({
  id,
  label,
  value,
  required,
  handleChange,
}: IREInputProps) {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext()

  return (
    <>
      <div className="relisting-input">
        <Controller
          name={id}
          control={control}
          rules={{
            required: required,
          }}
          render={() => (
            <TextField
              id={id}
              className="details"
              label={label}
              size="small"
              value={value ?? 0}
              type="number"
              error={errors[id]?.type === 'required' || errors[id]?.type === 'min'}
              helperText={
                errors[id]?.type === 'required'
                  ? `${label} is required`
                  : errors[id]?.type === 'min'
                  ? `${label} can't be negative`
                  : ''
              }
              inputProps={{
                type: 'number',
                min: 0,
              }}
              {...register(id, {
                onChange: (event) => handleChange(id, event),
                min: 0,
              })}
            />
          )}
        />
      </div>
    </>
  )
}
