import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { GET_REFERRAL_INFO } from 'src/client/queries/profile'
import HomePageLayout from 'src/components/layouts/HomepageLayout'
import FooterLandingPage from 'src/components/sections/FooterLandingPage'
import Header from 'src/components/sections/Header'
import Button from 'src/components/ui/button/Button'
import { MdDone } from 'react-icons/md'
import { BiCopy } from 'react-icons/bi'
import { IconButton, Tooltip } from '@mui/material'

export default function Affiliate(): JSX.Element {
  const { data, loading } = useQuery(GET_REFERRAL_INFO, {
    variables: {
      userId: localStorage?.getItem('currentUser') || localStorage?.getItem('userId'),
    },
  })
  const [copied, setCopied] = useState(false)
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>()

  const handleCopyClick = (value: string) => {
    navigator.clipboard.writeText(value)
    setCopied(true)
    const id = setTimeout(() => setCopied(false), 2000)
    setTimeoutId(id)
  }

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [timeoutId])

  return (
    <HomePageLayout>
      <Header />
      <div className="affiliate">
        <div className="affiliate_share">
          <div className="heading">Share your affiliate link.</div>
          <div>
            Invite your friends and colleagues to 3Blocks and win a ton of exciting earnings and
            cashbacks.
          </div>
          <div className="details">
            {loading ? (
              <div className="link_box">
                <Skeleton
                  style={{
                    width: '200px',
                    backgroundColor: 'rgba(43, 171, 225, 0.1)',
                  }}
                />
              </div>
            ) : (
              <div className="link_box">
                {`${process.env.REACT_APP_REDIRECT}?ref=${data?.getReferralInfo?.referralCode}`}
                <span>
                  {!copied ? (
                    <Tooltip title="Click to copy" placement="top-end">
                      <IconButton size="small">
                        <BiCopy
                          className="copyIcon"
                          onClick={() =>
                            handleCopyClick(
                              `${process.env.REACT_APP_REDIRECT}?ref=${data?.getReferralInfo?.referralCode}`
                            )
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Copied to clipboard" placement="top-end">
                      <IconButton size="small">
                        <MdDone className="copyIcon" />
                      </IconButton>
                    </Tooltip>
                  )}
                </span>
              </div>
            )}
            {!copied ? (
              <Tooltip title="Click to copy" placement="top-end">
                <Button
                  variant="signup"
                  label="Refer to earn"
                  size="sm"
                  handleClick={() =>
                    handleCopyClick(
                      `${process.env.REACT_APP_REDIRECT}?ref=${data?.getReferralInfo?.referralCode}`
                    )
                  }
                />
              </Tooltip>
            ) : (
              <Tooltip title="Copied to clipboard" placement="top-end">
                <Button variant="signup" label="Refer to earn" size="sm" />
              </Tooltip>
            )}
          </div>
        </div>

        <div className="affiliate_plan">
          <div className="affiliate_process">
            <div className="heading">How it works?</div>
            <div className="details">
              <div className="row_left">
                <div className="row">
                  <div className="header">Referral complete KYC:</div>

                  <div className="value">25 Points</div>
                </div>
                <div className="row">
                  <div className="header">Referral invests in a TOKEN:</div>

                  <div className="value">100 Points</div>
                </div>
                <div className="row">
                  <div className="header">Referral dollar earnings:</div>

                  <div className="value">2% of the Invested Amount</div>
                </div>
              </div>
            </div>
          </div>
          <div className="affiliate_process">
            <div className="heading">Points utilities:</div>
            <div className="details">
              <div className="row_left">
                <div className="row">
                  <div className="header">Redeem towards real estate tokens.</div>
                </div>
                <div className="row">
                  <div className="header">Redeem towards Airbnb free nights.</div>
                </div>
                <div className="row">
                  <div className="header">Redeem towards platform fees, and more.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="affiliate_stats">
          <div className="heading">My referral earnings</div>
          <div className="stats">
            <div className="row_left">
              <div className="row">
                <div className="header">Total points earned:</div>
                {loading ? (
                  <div className="value">
                    <Skeleton />
                  </div>
                ) : (
                  <div className="value">
                    {data?.getReferralInfo?.referralStats?.totalPointsEarned}
                  </div>
                )}
              </div>
              <div className="row">
                <div className="header">Total commission earned:</div>
                {loading ? (
                  <div className="value">
                    <Skeleton />
                  </div>
                ) : (
                  <div className="value">
                    $ {data?.getReferralInfo?.referralStats?.totalCommissionEarned}
                  </div>
                )}
              </div>
              <div className="row">
                <div className="header">Total commission distributed:</div>
                {loading ? (
                  <div className="value">
                    <Skeleton />
                  </div>
                ) : (
                  <div className="value">
                    $ {data?.getReferralInfo?.referralStats?.totalCommissionDistributed}
                  </div>
                )}
              </div>
              <div className="row">
                <div className="header">Total commission remaining:</div>
                {loading ? (
                  <div className="value">
                    <Skeleton />
                  </div>
                ) : (
                  <div className="value">
                    $ {data?.getReferralInfo?.referralStats?.totalCommissionRemaining}
                  </div>
                )}
              </div>
              <div className="row">
                <div className="header">Total # of Tokens purchased by referral:</div>
                {loading ? (
                  <div className="value">
                    <Skeleton />
                  </div>
                ) : (
                  <div className="value">
                    {data?.getReferralInfo?.referralStats?.totalTokensPurchasedByReferrals}
                  </div>
                )}
              </div>
            </div>
            <div className="row_right">
              <div className="row">
                <div className="header">Total dollars spent by referrals:</div>
                {loading ? (
                  <div className="value">
                    <Skeleton />
                  </div>
                ) : (
                  <div className="value">
                    ${' '}
                    {Math.round(
                      data?.getReferralInfo?.referralStats?.totalDollarsSpentByReferrals * 100
                    ) / 100}
                  </div>
                )}
              </div>
              <div className="row">
                <div className="header">Number of users registered:</div>
                {loading ? (
                  <div className="value">
                    <Skeleton />
                  </div>
                ) : (
                  <div className="value">
                    {data?.getReferralInfo?.referralStats?.noOfUsersRegistered}
                  </div>
                )}
              </div>
              <div className="row">
                <div className="header">Number of users completed KYC:</div>
                {loading ? (
                  <div className="value">
                    <Skeleton />
                  </div>
                ) : (
                  <div className="value">
                    {data?.getReferralInfo?.referralStats?.noOfUsersCompletedKyc}
                  </div>
                )}
              </div>
              <div className="row">
                <div className="header">Number of users purchased tokens:</div>
                {loading ? (
                  <div className="value">
                    <Skeleton />
                  </div>
                ) : (
                  <div className="value">
                    {data?.getReferralInfo?.referralStats?.noOfUsersPurchasedTokens}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterLandingPage />
    </HomePageLayout>
  )
}
