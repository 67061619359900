import React, { MouseEventHandler } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'

interface IFilePreviewProps {
  img: string
  file: File
  onClick: MouseEventHandler<HTMLDivElement>
  number: number
  handleAddHomeImage: MouseEventHandler<HTMLDivElement>
}

export default function REFilePreview({
  img,
  file,
  onClick,
  number,
  handleAddHomeImage,
}: IFilePreviewProps): JSX.Element {
  return (
    <div className="gallery-box">
      <div className="galleryimg" onClick={handleAddHomeImage}>
        <div className="remove-icon" onClick={onClick}>
          <AiOutlineCloseCircle size={20} />
        </div>
        <img className="reimg" src={img} alt={file.name} />
        <div className="number-icon">{number + 1}</div>
      </div>
      <div className="gallerycontent">
        <small className="contentname">{file.name}</small>
      </div>
    </div>
  )
}
