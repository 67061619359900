import BuyTokens from './BuyTokens'
import TokenInfo from './TokenInfo'

export default function TokenDialog({ realEstateId, allReData, rbacPerm }: any) {
  return (
    <div className="dialog-tokens">
      {allReData && (
        <>
          <BuyTokens rbacPerm={rbacPerm} realEstateId={realEstateId} allReData={allReData} />
          <TokenInfo realEstateId={realEstateId} allReData={allReData} />
        </>
      )}
    </div>
  )
}
