import React, { useState, useEffect } from 'react'
import {
  aboutMetaRevenue,
  reListingDetails,
  reListingEstatedParcel,
  reListingEstatedStructure,
  reListingEstatedTaxes,
} from 'src/utils/data/relisting'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import REForm from '../../components/ui/form/REForm'
import { AnimatePresence } from 'framer-motion'
import Toast from 'src/components/ui/toast/Toast'
import { useNavigate } from 'react-router-dom'
import { BsArrowRightCircle } from 'react-icons/bs'
import { messageDetails, variantDetails } from 'src/utils/constants'
import { useMutation } from '@apollo/client'
import { ICreateRealEstateBasicInfoInputs, IField } from 'src/types/relisting'
import { useRe } from 'src/context/useREListing'
import { useAuth } from 'src/context/useAuth'
import { CREATE_REAL_ESTATE_BASIC_INFO } from 'src/client/mutations/relisting'

function REListingPage1() {
  const [fields, setFields] = useState<IField[]>([])
  const [estatedStructure, setEstatedStructure] = useState<IField[]>([])
  const [estatedParcel, setEstatedParcel] = useState<IField[]>([])
  const [estatedTaxes, setEstatedTaxes] = useState<IField[]>([])
  const [revenueStream, setRevenueStream] = useState<IField[]>([])
  const [trigger, setTrigger] = useState(true)

  const navigate = useNavigate()

  //@ts-ignore
  const { user } = useAuth()

  const [createRealEstateBasicInfo] = useMutation(CREATE_REAL_ESTATE_BASIC_INFO)

  useEffect(() => {
    setFields(reListingDetails)
    setEstatedStructure(reListingEstatedStructure)
    setEstatedParcel(reListingEstatedParcel)
    setEstatedTaxes(reListingEstatedTaxes)
    setRevenueStream(aboutMetaRevenue)
  }, [fields])

  const handleChangeRevenue = (idx: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const newRevenueStream = [...revenueStream]
    newRevenueStream.forEach((field) => {
      const { id, type } = field
      if (idx === id) {
        switch (type) {
          case 'checkbox':
            field.value = event.target.checked

            break

          default:
            field.value = event.target.value
            break
        }
      }
      setRevenueStream(newRevenueStream)
    })
  }
  const handleChange = (idx: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const newFields = [...fields]
    newFields.forEach((field) => {
      const { id, type } = field
      if (idx === id) {
        switch (type) {
          case 'checkbox':
            field.value = event.target.checked
            break

          default:
            field.value = event.target.value
            break
        }
      }
      setFields(newFields)
    })
  }
 
  const handleChangeStructure = (idx: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const newEstatedStructure = [...estatedStructure]

    newEstatedStructure.forEach((field, i) => {
      const { id, type } = field

      if (idx === id) {
        switch (type) {
          case 'checkbox':
            field.value = event.target.checked
            break
          default:
            field.value = event.target.value
        }
        setEstatedStructure(newEstatedStructure)
      }
    })
  }

  const handleChangeParcel = (idx: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const newEstatedParcel = [...estatedParcel]

    newEstatedParcel.forEach((field, i) => {
      const { id, type } = field

      if (idx === id) {
        switch (type) {
          case 'checkbox':
            field.value = event.target.checked
            break
          default:
            field.value = event.target.value
        }
        setEstatedParcel(newEstatedParcel)
      }
    })
  }
  const handleChangeTaxes = (idx: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const newEstatedTaxes = [...estatedTaxes]

    newEstatedTaxes.forEach((field, i) => {
      const { id, type } = field

      if (idx === id) {
        switch (type) {
          case 'checkbox':
            field.value = event.target.checked
            break
          default:
            field.value = event.target.value
        }
        setEstatedTaxes(newEstatedTaxes)
      }
    })
  }
  const methods = useForm<ICreateRealEstateBasicInfoInputs>({
    criteriaMode: 'all',
  })

  const { setValues } = useRe()


  const onSubmit: SubmitHandler<ICreateRealEstateBasicInfoInputs> = (rawData: any) => {
    rawData.user_id = user.sub
    rawData.metaLandRevenueStream = `meta land revenue stream`

    const input = {
      user_id: rawData.user_id,
      metaLink: rawData.metaLink,
      metaLandRevenueStream: rawData.metaLandRevenueStream,
      youtubeLink: rawData.youtubeLink,
      address: {
        zipCode: rawData.zipCode,
        state: rawData.state,
        city: rawData.city,
        streetAddress: rawData.streetAddress,
      },
      structure: {
        construction_type: rawData.construction_type,
        baths: Number(rawData.baths),
        beds_count: Number(rawData.beds_count),
        foundation_type: rawData.foundation_type,
        air_conditioning_type: rawData.air_conditioning_type,
        architecture_type: rawData.architecture_type,
        basement_type: rawData.basement_type,
        condition: rawData.condition,
        exterior_wall_type: rawData.exterior_wall_type,
        fireplaces: rawData.fireplaces,
        flooring_types: rawData.flooring_types,
        heating_type: rawData.heating_type,
        parking_spaces_count: Number(rawData.parking_spaces_count),
        parking_type: rawData.parking_type,
        partial_baths_count: Number(rawData.partial_baths_count),
        roof_material_type: rawData.roof_material_type,
        roof_style_type: rawData.roof_style_type,
        stories: rawData.stories,
        units_count: Number(rawData.units_count),
        year_built: Number(rawData.year_built),
      },
      taxes: {
        amount: Number(rawData.amount),
        year: Number(rawData.year),
      },
      parcel: {
        municipality: rawData.municipality,
        area_acres: Number(rawData.area_acres),
        area_sq_ft: Number(rawData.area_sq_ft),
        building_count: Number(rawData.building_count),
        county_land_use_code: rawData.county_land_use_code,
        county_land_use_description: rawData.county_land_use_description,
        county_name: rawData.county_name,
        frontage_ft: Number(rawData.frontage_ft),
        subdivision: rawData.subdivision,
        zoning: rawData.zoning,
      },
    }

    createRealEstateBasicInfo({
      variables: { input },
      onCompleted: (data) => {
        setValues(data.createRealEstateBasicInfo.re_id)
        localStorage.removeItem('realEstateId')
        localStorage.setItem('realEstateId', data.createRealEstateBasicInfo.re_id)
        navigate('/relisting/images')
      },
      onError: (error) => {
        navigate('/relisting/details')
      },
    })
  }

  return (
    <>
      <div className="relisting-form">
        <FormProvider {...methods}>
          <form className="form">
            <div className="header">
              <div className="dir"></div>
              <div>
                <h5>Real Estate Listing - Details</h5>
              </div>

              <div className="dir">
                <button className="button -listing" onClick={methods.handleSubmit(onSubmit)}>
                  Next
                </button>
                <BsArrowRightCircle
                  className="dir -listing"
                  onClick={methods.handleSubmit(onSubmit)}
                />
              </div>
            </div>

            <div id="metaverseinfo" className="fieldsection">
              <p>Metaverse Info</p>
            </div>
            <section className="formcontent">
              {revenueStream &&
                revenueStream.map((field, id) => (
                  <REForm key={id} field={field} handleChange={handleChangeRevenue} />
                ))}
            </section>
            <div id="address" className="fieldsection">
              <p>Address</p>
            </div>
            <section className="formcontent">
              {fields &&
                fields.map((field, id) => (
                  <REForm key={id} field={field} handleChange={handleChange} />
                ))}
            </section>
            <>
              <div id="structure" className="fieldsection">
                <p>Structure</p>
              </div>

              <section className="formcontent">
                <AnimatePresence>
                  <Toast
                    toastMessage={messageDetails.createRe}
                    trigger={trigger}
                    setTrigger={setTrigger}
                    variant={variantDetails.info}
                  />
                </AnimatePresence>
                {estatedStructure &&
                  estatedStructure.map((field, id) => (
                    <REForm key={id} field={field} handleChange={handleChangeStructure} />
                  ))}
              </section>

              <div id="parcel" className="fieldsection">
                <p>Parcel</p>
              </div>

              <section className="formcontent">
                {estatedParcel &&
                  estatedParcel.map((field, id) => (
                    <REForm key={id} field={field} handleChange={handleChangeParcel} />
                  ))}
              </section>

              <div id="taxes" className="fieldsection">
                <p>Taxes</p>
              </div>

              <section className="formcontent">
                {estatedTaxes &&
                  estatedTaxes.map((field, id) => (
                    <REForm key={id} field={field} handleChange={handleChangeTaxes} />
                  ))}
              </section>
            </>
          </form>
        </FormProvider>
      </div>
    </>
  )
}

export default REListingPage1
