import { gql } from '@apollo/client'

export const GET_LOGGED_IN_USER_INFO = gql`
  query GetLoggedInUserInfo {
    getLoggedInUserInfo {
      user_id
      user {
        email
      }
    }
  }
`

export const GET_ACCESS_TOKEN = gql`
  query Query($password: String!, $username: String!) {
    getAccessToken(password: $password, username: $username) {
      accessToken
      idToken
      refreshToken
    }
  }
`

export const CHECK_USER_IN_COGNITO = gql`
  query Query($userId: String!) {
    checkUserInCognito(user_id: $userId)
  }
`

export const GET_PPM_OP_AGREEMENT = gql`
  query GetPpmOrOperatingDocAgreement($reId: String!, $userId: String!) {
    getPpmOrOperatingDocAgreement(re_id: $reId, user_id: $userId) {
      operatingAgreement
      operatingAgreementTimestamp
      ppmAgreement
      ppmAgreementTimestamp
      re_id
      user_id
    }
  }
`

export const GET_SUBSCRIPTION_AGREEMENT = gql`
  query GetSubscriptionAgreement($reId: String!, $userId: String!) {
    getSubscriptionAgreement(re_id: $reId, user_id: $userId) {
      re_id
      subscriptionAccepted
      subscriptionId
      subscriptionTimestamp
      tokenPrice
      tokensInThisOrder
      totalNumberOfTokens
      user_id
    }
  }
`

export const GET_USER_ROLE = gql`
  query GetUserAccess($userId: String!) {
    getUserAccess(user_id: $userId) {
      rolePermission {
        role
      }
    }
  }
`

export const GET_USER_PERMISSIONS = gql`
  query GetUserAccess($userId: String!) {
    getUserAccess(user_id: $userId) {
      rolePermission {
        permission {
          blockchain_address
          firstname
          home
          kyc_status
          lastname
          marketplace
          password_reset
          profile
          re_detail
          re_listing
        }
      }
      whitelist_address
    }
  }
`
export const GET_USER_PERMISSIONS_AND_ROLE = gql`
  query GetUserAccess($userId: String!) {
    getUserAccess(user_id: $userId) {
      rolePermission {
        permission {
          blockchain_address
          firstname
          home
          kyc_status
          lastname
          marketplace
          password_reset
          profile
          re_detail
          re_listing
        }
      }
      whitelist_address
    }
  }
`

export const GET_PORTFOLIO_INFO_BY_USER_ID = gql`
  query GetPortfolioInfoByUserId($userId: String!) {
    getPortfolioInfoByUserId(userId: $userId) {
      personal_points {
        investment_points
        kyc_wallet_whitelisted_points
        total_tokens_invested
      }
      portfolio_info {
        add
        current_token_price
        est_roi
        initial_real_estate_value
        re_id
        share_of_real_estate
        total_investment
        total_tokens_owned_by_buyer
      }
    }
  }
`
