//generics
export const skeletonData = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
export const ONFIDO_TIMEOUT = 6000
export const MODAL_TIMEOUT = 3000
export const TOKEN_EXPIRY = 10800
export const CONTRACT_EXPIRY = 6000
export const STRIPE_TIMEOUT = 5000

//relisting
export const messageDetails: { [key: string]: string } = {
  info: `Verify the property details and update data if necessary`,
  error: `No data found, verify the address and search again. If the address is correct enter data manually`,
  createRe: `Enter your Real estate property details`,
}

export const messageImg: { [key: string]: string } = {
  success: `Real Estate Images succesfully uploaded`,
  error: `Unable to upload Real Estate Images, try again`,
}
export const messageFin: { [key: string]: string } = {
  info: `Here are the statistics for`,
}
export const variantDetails: { [key: string]: string } = {
  info: 'in',
  success: 'su',
  error: 'er',
  input: 'input',
}

export const variantFin: { [key: string]: string } = {
  info: 'info',
}
export const marqueeFinInfo = [
  'Once submitted Property details cannot be changed, ',
  'please verify before proceeding',
]
export const marqueeInfo = [
  'Once submitted Property details cannot be changed, ',
  'are you sure you want to submit?',
]
export const marqueeSuccess = 'Property successfully added to the Marketplace'
//kyc
export const noticeVerify = 'Personal Verification'
export const actionVerify = 'Verify Now'
export const variantVerify = 'verify'
export const variantStatus = 'status'
export const updateStatus = 'Your verification status is currently'
export const revokeMessage =
  'Currently we are not serving the selected country. Please come back when OFAC sanctions are removed'
export const kycErrorMessage = 'Error verifying user status. Try again!'
export const variantProfile = 'profile'
export const variantWhitelist = 'whitelist'
export const userInfoMessage = 'You can only update your name via ID Verification.'
export const callToActionVerify = 'Verify your kyc status to start payment'
export const kycSuccess = `Redirecting to Marketplace. You will receive an email with your KYC status update in few minutes.`
export const kycSuccessSignout = `Thank you for submitting KYC documents, you will receive an email with the KYC status updates in few minutes after signing back in.`

//orch
export const workflowErrorMessage = 'An error has occured:'
export const countryInfo = `We are not serving the customers who are residents of these countries: United states of America and its territories, Balkans, Belarus, Burma, Cote D'Ivoire (Ivory Coast), Cuba, Democratic Republic of Congo, Iran, Iraq, Liberia, North Korea, Sudan, Syria, and Zimbabwe.

  Please abstain from registering to the 3Blocks platform if you are resident of the above mentioned counties. Your Registration will be auto rejected.
  `

//profile
export const myPortfolio = 'My Portfolio'
export const myDocuments = 'My Documents'
export const whitelistMessage = `Do not provide an exchange address, we will not be able to transfer Real Estate NFTs and rents to an exchange address.`
export const confirmAddress = `Please verify if your wallet address is correct`
export const verifyAddress = `Please verify that the provided Polygon Blockchain wallet address is correct`
export const verifyMsg = `In order to purchase Real Estate NFTs, You are required to complete Personal Account verification and Polygon wallet address whitelisting.`
//cognito
export const userExists = `You already have an account`
export const incorrectDetails = `Incorrect Username or Password`
export const checkEmail = `Sign up is successful. Check your email verify your account. Email may be availabe in Primary, Social, Promotions or spam folders`
export const resetSucessful = `Password reset successful.`
export const checkEmailPwdReset = `If your email address exists in our system, your password reset link will be sent to your email address. Please check primary, social, promotions or spam folders for the link.`
export const generalError = `Operation unsucessful, Please try again later`
export const presignupError = `User already exists sign in directly with Google`
export const unconfirmedError = `Verify your account and try signing in again`
export const attemptError = `Attempt limit exceeded, please try after some time`
export const pwdError = `Password did not conform with policy: Password must have symbol characters`
export const invalidCode = `Invalid verification code provided, please try again`

//propertyDetails
export const propertyHighlights = `Property Highlights`
export const propertyDetails = `Property Details`
export const financials = `Financials`
export const aboutProperty = `About Property`
export const additionalPerks = `Additional Details`

//coinify
export const transactionSuccessful = `Transaction successful`
export const transactionFailed = `Transaction failed`
export const transactionUpdate = `Please complete the order. Once the order is complete, tokens will be sent to whitelisted address.`

//pay
export const inputPaymentDetails = `
tokens available to buy at this moment are 
`
export const enableBuyTokens = `In order to buy tokens, please complete KYC and wallet address whitelisting on your profile screen.`
export const insufficientAvailableTokens = `The previously selected number of tokens has been sold out. Available number of tokens iser`

//terms of service
export const preInfo = {
  date: `Effective Date: February 11, 2021`,
  read: `PLEASE READ THESE TERMS AND CONDITIONS OF USE (SITE TERMS) CAREFULLY. THESE SITE TERMS AND OTHER AGREEMENTS ON THE SITES GOVERN YOUR USE OF OUR WEB SITE OR MOBILE APPLICATION (THE SITES), AND ALSO INCLUDE IMPORTANT DISCLOSURES AND REGULATORY INFORMATION THAT ARE ASSOCIATED WITH THE SERVICES AVAILABLE ON THE SITES, ALL OF WHICH ARE IMPORTANT AND AFFECT YOUR LEGAL RIGHTS, SO PLEASE READ THEM CAREFULLY.`,
  acess: `BY ACCESSING OR USING THE SITES, YOU REPRESENT THAT (A) YOU HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY THE SITE TERMS, (B) YOU ARE OF LEGAL AGE TO FORM A BINDING CONTRACT WITH 3BLOCKS, AND (C) YOU HAVE THE AUTHORITY TO ENTER INTO THE TERMS OF USE PERSONALLY OR ON BEHALF OF THE ENTITY YOU HAVE NAMED AS THE REGISTERED USER, AND TO BIND THAT ENTITY TO THE SITE TERMS. THE TERM YOU REFERS TO THE INDIVIDUAL OR LEGAL ENTITY, AS APPLICABLE, IDENTIFIED AS THE REGISTERED USER WHEN YOU SUBMIT YOUR REGISTRATION INFORMATION ON THE SITES. YOU AGREE TO BE BOUND BY THE SITE TERMS DESCRIBED HEREIN AND ALL TERMS INCORPORATED BY REFERENCE. YOUR ACCESS TO AND USE OF THE SITES CONSTITUTES YOUR ACCEPTANCE OF AND AGREEMENT TO ABIDE BY EACH OF THE SITE TERMS SET FORTH BELOW. IF YOU DO NOT AGREE TO ALL OF THESE SITE TERMS, DO NOT USE OUR WEB SITE OR MOBILE APPLICATION.`,
}
