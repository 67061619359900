import { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Navigation, Thumbs } from 'swiper'
import 'swiper/css'
import 'swiper/css/grid'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import 'swiper/css/bundle'
import Skeleton from 'react-loading-skeleton'
import { useQuery } from '@apollo/client'
import { LIST_FILES } from 'src/client/queries/relisting'

export default function Carousel({ realEstateId }: any) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const [imgLinks, setImgLinks] = useState<Array<any>>([])
  const dummnyImgLinks = [1, 2, 3, 4, 5, 6]

  const {
    loading: imgLoading,
  } = useQuery(LIST_FILES, {
    variables: { listFilesId: `public/reId/${realEstateId}` },
    onCompleted: (data) => {
      const currentUrls = data?.listFiles
      setImgLinks(currentUrls)
    },
  })
  if (imgLoading) {
    return (
      <div className="carousel">
        <Swiper
          style={{
            //@ts-ignore
            '--swiper-navigation-color': 'var(--bg)',
          }}
          className="mySwiper2"
          spaceBetween={10}
          navigation={true}
          modules={[FreeMode, Navigation, Thumbs]}
          thumbs={{ swiper: thumbsSwiper }}
        >
          {dummnyImgLinks.map((dummyImg, idx) => (
            <SwiperSlide key={idx} className="swiper-slide2">
              <Skeleton width={'100%'} height={'80%'} />
            </SwiperSlide>
          ))}
        </Swiper>

        <Swiper
          className="mySwiper"
          //@ts-ignore
          onSwiper={setThumbsSwiper}
          slidesPerView={6}
          modules={[FreeMode, Navigation, Thumbs]}
        >
          {dummnyImgLinks.map((idx) => (
            <SwiperSlide key={idx} className="swiper-slide">
              <Skeleton width={'25%'} height={'100%'} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    )
  }

  return (
    <div className="carousel">
      <Swiper
        style={{
          //@ts-ignore
          '--swiper-navigation-color': 'white',
        }}
        className="mySwiper2"
        spaceBetween={10}
        navigation={true}
        modules={[FreeMode, Navigation, Thumbs]}
        thumbs={{ swiper: thumbsSwiper }}
      >
        {imgLinks && imgLinks ? (
          imgLinks.map((imgLink, idx) => (
            <SwiperSlide key={idx} className="swiper-slide2">
              <img alt="re_img" src={imgLink.imageUrl} />
            </SwiperSlide>
          ))
        ) : (
          <Skeleton />
        )}
      </Swiper>

      <Swiper
        className="mySwiper"
        //@ts-ignore
        onSwiper={setThumbsSwiper}
        slidesPerView={6}
        modules={[FreeMode, Navigation, Thumbs]}
      >
        {imgLinks ? (
          imgLinks.map((imgLink: any, idx: any) => (
            <SwiperSlide key={idx} className="swiper-slide">
              <img src={imgLink.imageUrl} alt="re_img" />
            </SwiperSlide>
          ))
        ) : (
          <Skeleton />
        )}
      </Swiper>
    </div>
  )
}
