import React, { useEffect } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { motion } from 'framer-motion'
interface IToastProps {
  toastMessage: string
  variant?: string
  trigger?: any
  setTrigger?: any
  show?: any
  reinitializer?: any
}

function Toast({ toastMessage, trigger, setTrigger, variant }: IToastProps) {
  useEffect(() => {
    const timeId = setTimeout(() => {
      setTrigger(false)
    }, 50000)

    return () => {
      clearTimeout(timeId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleClick() {
    return setTrigger(false)
  }

  return !trigger ? null : (
    <motion.div
      className={`toast -${variant}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={handleClick}
    >
      <div className="toast-content">
        <small>{toastMessage}</small>
      </div>
      {variant === 'input' ? (
        <></>
      ) : (
        <div className="remove-icon">
          <AiOutlineCloseCircle />
        </div>
      )}
    </motion.div>
  )
}

export function ToastOnTrigger({ toastMessage, trigger, setTrigger, variant, show }: IToastProps) {
  if (show) {
    setTrigger(true)
  }

  function handleClick() {
    return setTrigger(false)
  }
  return !trigger ? null : (
    <motion.div
      className={`toast -${variant}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={handleClick}
    >
      <div className="toast-content">
        <small>{toastMessage}</small>
      </div>
      <div className="remove-icon">
        <AiOutlineCloseCircle />
      </div>
    </motion.div>
  )
}

export function ThreeBlocksToast({ toastMessage, variant }: IToastProps) {
  return (
    <motion.div
      className={`toast -${variant}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="toast-content">
        <small>{toastMessage}</small>
      </div>
    </motion.div>
  )
}

export default Toast
