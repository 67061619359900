/* eslint-disable @typescript-eslint/no-shadow */
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { ThreeBlocksToast } from 'src/components/ui/toast/Toast'

interface IToastContext {
  addToast: (toast: any) => void
}
const ToastContext = createContext<IToastContext | undefined>(undefined)

function ToastProvider({ children }: { children: React.ReactChild }) {
  const [toasts, setToasts] = useState<Array<any>>([])

  useEffect(() => {
    if (toasts.length > 0) {
      const timer = setTimeout(() => setToasts((toasts) => toasts.slice(1)), 4000)
      return () => clearTimeout(timer)
    }
  }, [toasts])

  const addToast = useCallback(
    function (toast) {
      setToasts((toasts) => [...toasts, toast])
    },
    [setToasts]
  )

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <div>
        {toasts.map((toast, index) => (
          <ThreeBlocksToast
            key={`${index}${toast[0]}`}
            variant={toast.slice(-2)}
            toastMessage={toast.slice(0, -2)}
          />
        ))}
      </div>
    </ToastContext.Provider>
  )
}

function useToast() {
  const context = useContext(ToastContext)
  if (context === undefined) {
    throw new Error(`useToast must be used within a ToastProvider`)
  }
  return context
}

export { ToastProvider, useToast }
