import { Outlet } from 'react-router-dom'
import Header from 'src/components/sections/Header'

function PaymentPage() {
  return (
    <>
      <Header variant="standard" />
      <Outlet />
    </>
  )
}

export default PaymentPage