import { useFormContext, Controller } from 'react-hook-form'
import { TextField } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import customParseFormat from "dayjs/plugin/customParseFormat";

import React from 'react'

interface IREInputDateProps {
  id: string
  label: string
  required: boolean
  maxlength?: number
  type: string
  variant: string
  value?: string | null
  handleChange: Function
  readonly: boolean
}

export default function REInputDate({
  id,
  label,
  required,
}: IREInputDateProps) {
  const {
    control,
  } = useFormContext()
  dayjs.extend(customParseFormat)

  const [dateValue, setDateValue] = React.useState<string>(dayjs().format('MM-DD-YYYY HH:mm:ss'))


  return (
    <>
      <div className="relisting-input">
        <Controller
          name={id}
          defaultValue={dateValue}
          control={control}
          rules={{ required: required }}
          render={({ field: { onChange, ...restField } }) => (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label={label}
                inputFormat="MM/DD/YYYY"
                onChange={(event) => {
                  onChange(event)
                  setDateValue(event)
                }}
                renderInput={(params) => <TextField size="small" {...params} />}
                {...restField}
              />
            </LocalizationProvider>
          )}
        />
      </div>
    </>
  )
}

