// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-ActiveVideo-BaseScreen-baseScreen{display:-webkit-box;display:-webkit-flex;display:flex;width:100%;height:100%;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;-webkit-box-align:center;-webkit-align-items:center;align-items:center;border-radius:var(--ods-border-radius-large);background:var(--osdk-color-background-surface-modal);padding:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"baseScreen": "onfido-sdk-ui-ActiveVideo-BaseScreen-baseScreen"
};
export default ___CSS_LOADER_EXPORT___;
