import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import Button from 'src/components/ui/button/Button'
import { ReactComponent as StripeIcon } from 'src/assets/Stripe.svg'
import { ReactComponent as CoinifyIcon } from 'src/assets/coinify.svg'
import PaymentLayout from 'src/components/layouts/PaymentLayout'
import { BounceLoader } from 'react-spinners'
import { CREATE_COINIFY_PAYMENT, CREATE_STRIPE_PAYMENT } from 'src/client/mutations/payment'
import { GET_EXTRA_DATA, GET_ALL_RE_BY_ID } from 'src/client/queries/relisting'
import Skeleton from 'react-loading-skeleton'
import { toFixedSimple } from 'src/utils/relisting'
import { useAuth } from 'src/context/useAuth'
import SubContract from 'src/components/sections/SubContract'
import { ThreeBlocksLoader } from 'src/components/ui/loader/Loader'
import { useToast } from 'src/context/useToast'
import { insufficientAvailableTokens, STRIPE_TIMEOUT } from 'src/utils/constants'
import { sendEventToGA } from 'src/utils/googleAnalytics'

interface PaymentOptionsProps {
  dollar?: number
  tokens?: number
  reId?: string
  totalNumberOfTokens?: number
}

export default function PaymentOptions() {
  const [loading, setLoading] = useState(false)
  const [paymentData, setPaymentData] = useState<PaymentOptionsProps>({})
    // eslint-disable-next-line
  const [revertPayment, setRevertPayment] = useState(false)
  const location = useLocation()
  const { state }: any = location
  const reId = state.reData
  const temp = ''
  const stripeReId = temp.concat(reId)
  const { tokens, totalNumberOfTokens } = paymentData
  const { data: allReData, loading: allReLoading } = useQuery(GET_ALL_RE_BY_ID, {
    variables: { reId: reId },
  })

  // @ts-ignore
  const { user } = useAuth()
  const { sub } = user
  const { addToast } = useToast()

  const navigate = useNavigate()

  useEffect(() => {
    const payData = localStorage?.getItem('payData')
    if (payData) {
      setPaymentData(JSON.parse(payData || '{}'))
    }
  }, [revertPayment])

  const [getRealEstateInfoById] = useLazyQuery(GET_EXTRA_DATA, {
    variables: {
      reId: reId,
    },
  })
  const handleToast = () => {
    addToast(`${insufficientAvailableTokens}`)
  }
  const tokenPrice = allReData?.getRealEstateInfoById.tokenPrice

  const finalTokenPrice = toFixedSimple(Number(tokenPrice), 2)
  const subTotal = toFixedSimple(Number(tokens) * finalTokenPrice, 2)
  const currentTokens = { finalTokenPrice, tokens, totalNumberOfTokens }
  const selectedTokens = Number(tokens)

  const redirectOnTimeOut = () => {
    setTimeout(() => {
      navigate(`/market`)
    }, STRIPE_TIMEOUT)
  }
  const [createCoinifyPayment, { loading: coinifyLoading }] = useMutation(CREATE_COINIFY_PAYMENT)

  const [createStripePaymentIntent, { loading: stripeLoading }] = useMutation(CREATE_STRIPE_PAYMENT)

     //google analytics
     const handleGA = () => {
      sendEventToGA({
        category: `payment_option`,
        action: 'payment_click_coinify',
      })
    }
  const initCoinify = async () => {
    try {
      const dataMax = (await getRealEstateInfoById()).data
      const currentAvailableTokens = dataMax?.getRealEstateInfoById.tokensReamaning
      setLoading(loading)
      if (currentAvailableTokens < selectedTokens) {
        navigate(`/re/${reId}`, {
          state: { reData: reId },
        })
        handleToast()
      } else {
        createCoinifyPayment({
          variables: {
            input: {
              re_id: reId,
              returnUrl: `${process.env.REACT_APP_REDIRECT}re/${stripeReId}`,
              successUrl: `${process.env.REACT_APP_REDIRECT}market`,
              tokenPrice: finalTokenPrice,
              tokensInThisOrder: Number(tokens),
              user_id: sub,
            },
          },
          onCompleted: (data) => {
            localStorage.removeItem('coinifyLink')
            localStorage.setItem('coinifyLink', data.createCoinifyPayment.data.payment_url)
            navigate('/pay/coinify')
            handleGA()
          },
        })
      }
    } catch (error) {
      navigate(`/re/${reId}`)
    }
  }

  const initStripe = async () => {
    try {
      const dataMax = (await getRealEstateInfoById()).data
      const currentAvailableTokens = dataMax?.getRealEstateInfoById.tokensRemaining
      if (currentAvailableTokens < selectedTokens) {
        navigate(`/re/${reId}`, {
          state: { reData: reId },
        })
        handleToast()
      } else {
        createStripePaymentIntent({
          variables: {
            input: {
              re_id: reId,
              returnUrl: `${process.env.REACT_APP_REDIRECT}re/${stripeReId}`,
              successUrl: `${process.env.REACT_APP_REDIRECT}market`,
              tokenPrice: finalTokenPrice,
              tokensInThisOrder: Number(tokens),
              user_id: sub,
            },
          },
          onCompleted: (data: any) => {
            const stripeRedirect = data && data.createStripePaymentIntent.checkoutUrl
            window.open(`${stripeRedirect}`, '_blank')
            redirectOnTimeOut()
          },
        })
      }
    } catch (error) {
      navigate(`/re/${reId}`)
    }
  }


  return (
    <>
      {Object.keys(paymentData).length ? (
        <>
          <PaymentLayout>
            <div className="checkout">
              <table>
                <thead>
                  <tr>
                    <th>&nbsp;</th>

                    <th>Token Price</th>
                    <th>Quantity</th>
                    <th>Subtotal</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    {allReData &&
                    allReData.getRealEstateInfoById.address.formatted_street_address ? (
                      <td>{allReData.getRealEstateInfoById.address.formatted_street_address}</td>
                    ) : (
                      <td>
                        <Skeleton />
                      </td>
                    )}
                    {finalTokenPrice ? (
                      <td>${finalTokenPrice}</td>
                    ) : (
                      <td>
                        <Skeleton />
                      </td>
                    )}
                    {tokens ? (
                      <td>{tokens}</td>
                    ) : (
                      <td>
                        <Skeleton />
                      </td>
                    )}
                    {allReData ? (
                      <td>$ {subTotal}</td>
                    ) : (
                      <td>
                        <Skeleton />
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="checkout -mobile">
              <table>
                <tbody>
                  <tr></tr>
                  <tr>
                    {allReData &&
                    allReData.getRealEstateInfoById.address.formatted_street_address ? (
                      <td>{allReData.getRealEstateInfoById.address.formatted_street_address}</td>
                    ) : (
                      <td>
                        <Skeleton />
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
              <table>
                <thead>
                  <tr>
                    <th>Token Price</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    {finalTokenPrice ? (
                      <td>${finalTokenPrice}</td>
                    ) : (
                      <td>
                        <Skeleton />
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
              <table>
                <thead>
                  <tr>
                    <th>Quantity</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    {tokens ? (
                      <td>{tokens}</td>
                    ) : (
                      <td>
                        <Skeleton />
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
              <table>
                <thead>
                  <tr>
                    <th>Subtotal</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    {allReData ? (
                      <td>$ {subTotal}</td>
                    ) : (
                      <td>
                        <Skeleton />
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="option">
              <h4>Select Payment Option</h4>

              {coinifyLoading ? (
                <div>
                  <Button
                    variant="loading"
                    radius="semisquare"
                    label="Coinify - Crypto (Preferred)"
                    icon={
                      <div>
                        <BounceLoader color="var(--gray-60)" size={20} />
                      </div>
                    }
                  />
                </div>
              ) : (
                <div>
                  <Button
                    variant="pay"
                    radius="semisquare"
                    handleClick={initCoinify}
                    label="Coinify - Crypto (Preferred)"
                    icon={<CoinifyIcon />}
                  />
                </div>
              )}

              {stripeLoading ? (
                <div>
                  {' '}
                  <Button
                    variant="loading"
                    radius="semisquare"
                    label="Stripe (Credit or Debit Card)"
                    icon={
                      <div>
                        <BounceLoader color="var(--gray-60)" size={20} />
                      </div>
                    }
                  />
                </div>
              ) : (
                <div>
                  <Button
                    variant="disabled"
                    disabled
                    radius="semisquare"
                    size="wide"
                    label="(Temporarily Disabled)"
                    handleClick={initStripe}
                    icon={<StripeIcon />}
                  />
                </div>
              )}
            </div>

            <SubContract
              paymentData={paymentData}
              basicLoading={allReLoading}
              allReData={allReData}
              realEstateId={reId}
              user={user}
              currentTokens={currentTokens}
            />
          </PaymentLayout>
        </>
      ) : (
        <ThreeBlocksLoader />
      )}
    </>
  )
}
