import { useFormContext } from 'react-hook-form'

interface IRECheckboxProps {
  id: string
  label: string
  required: boolean
  type: string
  variant: string
  value: boolean
  handleChange: Function
}
export default function RECheckbox({
  type,
  label,
  id,
  value,
  variant,
  required,
  handleChange,
}: IRECheckboxProps) {
  const { register } = useFormContext()

  return (
    <>
      <div className="relisting-input">
        <div className={`checkbox -${variant}`}>
          <label className="label" htmlFor="">
            {label}
            <input
              className="input"
              id={id}
              {...register(id, {
                required: required,
                onChange: (event) => handleChange(id, event),
              })}
              type={type}
              name={id}
              value={id}
            />
          </label>
        </div>
      </div>
    </>
  )
}
