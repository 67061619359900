// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-ActiveVideo-Wrapper-wrapper{display:-webkit-box;display:-webkit-flex;display:flex;width:100%;height:100%;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-align:center;-webkit-align-items:center;align-items:center;padding:0 32px;-webkit-box-sizing:border-box;box-sizing:border-box;overflow-y:auto}.onfido-sdk-ui-ActiveVideo-Wrapper-wrapperBody{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;margin:auto 0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "onfido-sdk-ui-ActiveVideo-Wrapper-wrapper",
	"wrapperBody": "onfido-sdk-ui-ActiveVideo-Wrapper-wrapperBody"
};
export default ___CSS_LOADER_EXPORT___;
