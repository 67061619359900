import { Auth, Hub } from 'aws-amplify'
import { createContext, useContext, useEffect, useState } from 'react'
import { fetchUserPermissions } from 'src/client'
import { normalizePermissions } from 'src/utils/profile'

const AuthContext = createContext({})

const initState = { userId: '' }
function AuthProvider(props: any) {
  const [user, setUser] = useState<{}>(initState)
  const [rbac, setRbac] = useState<Array<string>>([])

  const [loader, setLoader] = useState(true)

  useEffect(() => {
    checkUser()
    Hub.listen('auth', (data) => {
      const { payload } = data
      if (payload.event === 'signIn') {
        setLoader(false)
        checkUser()
      }
      if (payload.event === 'signOut') {
        setLoader(false)
        setUser(initState)
      }
    })
  }, [])

  const checkUser = async () => {
    try {
      setLoader(true)
      const dataMax = await Auth.currentAuthenticatedUser()
      const data = dataMax.signInUserSession.idToken.payload
      const userInfo = {
        userId: data.sub,
        ...data,
      }
      let normPerm: any = []

      const prevPerm = JSON.parse(localStorage?.getItem('rbac') || '{}')

      if (userInfo.userId.length > 0 && Object.keys(prevPerm).length === 0) {
        await fetchUserPermissions(userInfo.userId)
      }
      if (localStorage?.getItem('rbac')) {
        const perms = JSON.parse(localStorage?.getItem('rbac') || '{}')
        const userPermissions: Array<string> = []
        normPerm = normalizePermissions(perms, userPermissions)
      }
      setUser(userInfo)
      setRbac(normPerm)
      setLoader(false)
    } catch (error) {
      setLoader(false)
    }
  }

  return <AuthContext.Provider value={{ user, rbac, loader }} {...props} />
}
function useAuth() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export { AuthProvider, useAuth }
