import { useLocation } from 'react-router-dom'
import RealEstateLayout from 'src/components/layouts/RealEstateLayout'
import Header from 'src/components/sections/Header'
import PropertyDetails from 'src/components/sections/PropertyDetails'
import PropertyMisc from 'src/components/sections/PropertyMisc'
import TokenDialog from 'src/components/sections/TokenDialog'
import Carousel from 'src/components/ui/carousel/Carousel'
import { useAuth } from 'src/context/useAuth'
import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_ALL_RE_BY_ID } from 'src/client/queries/relisting'
import { GET_PPM_OP_AGREEMENT, GET_USER_PERMISSIONS } from 'src/client/queries/auth'
import { ThreeBlocksLoader } from 'src/components/ui/loader/Loader'
import OpContract from 'src/components/sections/OpContract'
import PpmOpContract from 'src/components/sections/PpmOpContract'
import { useEffect, useState } from 'react'
import { urlSubString } from 'src/utils/relisting'
import { normalizePermissions } from 'src/utils/profile'
import FooterLandingPage from 'src/components/sections/FooterLandingPage'

const RealEstate = () => {
  //@ts-ignore
  const { user } = useAuth()
  const location = useLocation()
  const [dialogStatus, setDialogStatus] = useState(true)
  const [opDialogStatus, setOpDialogStatus] = useState(true)
  const { pathname }: any = location
  const realEstateId = urlSubString(pathname)
  const [rbacPerm, updateRbacPerm] = useState<Array<string>>([])

  const { data: allReData, loading: allReLoading } = useQuery(GET_ALL_RE_BY_ID, {
    variables: { reId: realEstateId },
  })

  const { data: ppmData, loading: ppmLoading } = useQuery(GET_PPM_OP_AGREEMENT, {
    variables: { reId: realEstateId, userId: user.userId },
  })

  const [getUserAccess, { loading: accessLoading }] = useLazyQuery(GET_USER_PERMISSIONS, {
    variables: {
      userId: localStorage?.getItem('currentUser') || localStorage?.getItem('userId'),
    },
    onCompleted: (_data) => {
      localStorage?.removeItem('rbac')
      localStorage.setItem('rbac', JSON.stringify(_data?.getUserAccess?.rolePermission?.permission))
      const permissions = JSON.parse(localStorage?.getItem('rbac') || '{}')
      const userPermissions: Array<string> = []
      updateRbacPerm(normalizePermissions(permissions, userPermissions))
    },
  })
  const tokens = allReData?.getRealEstateInfoById.tokensRemaining

  useEffect(() => {
    if (
      ppmData?.getPpmOrOperatingDocAgreement?.ppmAgreement &&
      ppmData?.getPpmOrOperatingDocAgreement?.operatingAgreement
    ) {
      setDialogStatus(false)
    }
    if (
      ppmData?.getPpmOrOperatingDocAgreement?.ppmAgreement &&
      ppmData?.getPpmOrOperatingDocAgreement?.operatingAgreement === null
    ) {
      setOpDialogStatus(true)
    }
    if (user.userId && user.userId.length > 0) {
      getUserAccess()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dialogStatus,
    opDialogStatus,
    ppmData?.getPpmOrOperatingDocAgreement?.ppmAgreement,
    ppmData?.getPpmOrOperatingDocAgreement?.operatingAgreement,
  ])

  if (ppmLoading || accessLoading) return <ThreeBlocksLoader />
  if (ppmData?.getPpmOrOperatingDocAgreement?.operatingAgreement === null) {
    return (
      <RealEstateLayout>
        <Header variant="standard" user={user} />
        <TokenDialog rbacPerm={rbacPerm} realEstateId={realEstateId} allReData={allReData} />
        <Carousel realEstateId={realEstateId} />
        <PropertyDetails realEstateId={realEstateId} allReData={allReData} />
        <PropertyMisc realEstateId={realEstateId} allReData={allReData} />
        <OpContract
          realEstateId={realEstateId}
          user={user}
          allReData={allReData}
          allReLoading={allReLoading}
          opDialogStatus={opDialogStatus}
          setOpDialogStatus={setOpDialogStatus}
        />
      </RealEstateLayout>
    )
  }
  return (
    <>
      {(ppmData?.getPpmOrOperatingDocAgreement?.ppmAgreement &&
        ppmData?.getPpmOrOperatingDocAgreement?.operatingAgreement) ||
      tokens === 0 ? (
        <RealEstateLayout>
          <Header variant="standard" user={user} />
          <TokenDialog rbacPerm={rbacPerm} realEstateId={realEstateId} allReData={allReData} />
          <Carousel realEstateId={realEstateId} />
          <PropertyDetails realEstateId={realEstateId} allReData={allReData} />
          <PropertyMisc realEstateId={realEstateId} allReData={allReData} />
        </RealEstateLayout>
      ) : (
        <RealEstateLayout>
          <Header variant="standard" user={user} />
          <TokenDialog rbacPerm={rbacPerm} realEstateId={realEstateId} allReData={allReData} />
          <Carousel realEstateId={realEstateId} />
          <PropertyDetails realEstateId={realEstateId} allReData={allReData} />
          <PropertyMisc realEstateId={realEstateId} allReData={allReData} />
          <PpmOpContract
            realEstateId={realEstateId}
            user={user}
            allReData={allReData}
            allReLoading={allReLoading}
            dialogStatus={dialogStatus}
            setDialogStatus={setDialogStatus}
          />
        </RealEstateLayout>
      )}
      <FooterLandingPage />
    </>
  )
}

export default RealEstate
