//formating timestamp into "MAY 30, 2023" format for blog posts
export const formatDate = (timeStamp: string) => {
  const inputDate = new Date(timeStamp)
  const monthNames = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ]

  const month = monthNames[inputDate.getMonth()]
  const day = inputDate.getDate()
  const year = inputDate.getFullYear()

  return `${month} ${day}, ${year}`
}

//calculation of reading time for blog post
export const calculateReadingTime = (text: string) => {
  // Average reading speed in words per minute
  const averageReadingSpeed = 200
  // Count the number of words in the text
  const wordCount = text?.split(/\s+/).length
  // Calculate the reading time in minutes
  const readingTimeInMinutes = Math.ceil(wordCount / averageReadingSpeed)
  return readingTimeInMinutes
}


export const scrollToTop = () => {
  window.scrollTo({ top: 10, left: 0, behavior: 'smooth' });
};