import { useAuth } from 'src/context/useAuth'
import RealEstate from './RealEstate'
import RealEstatePublic from './RealEstatePublic'

const RealEstateMain = () => {
  //@ts-ignore
  const { user } = useAuth()

  if (user.userId) {
    return <RealEstate />
  }
  return <RealEstatePublic />
}

export default RealEstateMain
