import React from 'react'
import ReactDOM from 'react-dom'
import './style/index.scss'
import 'swiper/css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import client, { fetchUserPermissions } from './client'
import { getIdToken, getUserId, uploadConfigure } from './utils/auth'
import { AuthProvider } from './context/useAuth'
import { ReListingProvider } from './context/useREListing'
import { PaymentProvider } from './context/usePayment'
import { Hub } from 'aws-amplify'
import { ToastProvider } from './context/useToast'
import { RefreshProvider } from './context/useRefresh'
import { Provider } from 'react-redux'
import { store } from './state/store'
import { RefCodeProvider } from './context/useRefCode'

uploadConfigure()
Hub.listen('auth', (data) => {
  const { payload } = data
  if (payload.event === 'signIn') {
    let threeBlocksUserId = ''
    getUserId().then((res) => {
      threeBlocksUserId = res

      fetchUserPermissions(threeBlocksUserId)
    })
    getIdToken()
  }
})

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ReListingProvider>
        <PaymentProvider>
          <ApolloProvider client={client}>
            <Provider store={store}>
              <AuthProvider>
                <ToastProvider>
                  <RefreshProvider>
                    <RefCodeProvider>
                      <App />
                    </RefCodeProvider>
                  </RefreshProvider>
                </ToastProvider>
              </AuthProvider>
            </Provider>
          </ApolloProvider>
        </PaymentProvider>
      </ReListingProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)
//testdeploymentoct30
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
