import Header from 'src/components/sections/Header'
import MarketplaceGrid from 'src/components/sections/MarketplaceGrid'
import FooterLandingPage from 'src/components/sections/FooterLandingPage'
const Marketplace = () => {
  return (
    <div>
      <Header variant="standard" />
      <MarketplaceGrid variant="main" />
      <FooterLandingPage />
    </div>
  )
}

export default Marketplace
