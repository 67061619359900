import Skeleton from 'react-loading-skeleton'

interface ICardPropertyAboutProps {
  heading: string
  variant?: string
  propertyData?: any
  loading?: any
}

const CardPropertyAbout = ({ heading, variant, propertyData }: ICardPropertyAboutProps) => {
  const cardClass = `cardproperty -${variant}`

  return (
    <div className={cardClass}>
      {' '}
      <div className="title -about">
        <h4>{heading}</h4>
      </div>
      <div className="info-property -about">
        <div className="about">
          <p>
            {propertyData.getRealEstateInfoById.propertyDescription ? (
              propertyData.getRealEstateInfoById.propertyDescription
            ) : (
              <Skeleton />
            )}
          </p>
        </div>
      </div>
    </div>
  )
}

export default CardPropertyAbout
