// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3C%3Fxml version%3D%221.0%22 encoding%3D%22UTF-8%22%3F%3E%3Csvg width%3D%2212%22 height%3D%2212%22 xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22 xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3C%21--Generator%3A Sketch 51.2 %2857519%29 - http%3A%2F%2Fwww.bohemiancoding.com%2Fsketch--%3E%3Cdefs%3E%3Cpath d%3D%22M1.24 3.33h3.1V2H.63C.28 2 0 2.3 0 2.67v4h1.24V3.33zM.62 14h3.73v-1.33h-3.1V9.33H0v4c0 .37.28.67.62.67zM12 13.33v-4h-1.25v3.34h-3.1V14h3.73c.34 0 .62-.3.62-.67zm-4.35-10h3.1v3.34H12v-4c0-.37-.28-.67-.62-.67H7.65v1.33z%22 id%3D%22a%22%2F%3E%3C%2Fdefs%3E%3Cuse fill%3D%22%23FFF%22 fill-rule%3D%22nonzero%22 xlink%3Ahref%3D%22%23a%22 transform%3D%22translate%280 -2%29%22 opacity%3D%22.85%22%2F%3E%3C%2Fsvg%3E", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("data:image/svg+xml,%3C%3Fxml version%3D%221.0%22 encoding%3D%22UTF-8%22%3F%3E%3Csvg width%3D%2212%22 height%3D%2212%22 xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22 xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3C%21--Generator%3A Sketch 51.2 %2857519%29 - http%3A%2F%2Fwww.bohemiancoding.com%2Fsketch--%3E%3Cdefs%3E%3Cpath d%3D%22M.943 13.471l2.114-2.114 1.414 1.414V9H.701l1.414 1.414L0 12.529l.943.942zM11.029 2L8.914 4.115 7.5 2.7v3.77h3.77L9.858 5.057l2.114-2.114L11.03 2zM7.5 9v3.77l1.414-1.413 2.115 2.114.942-.942-2.114-2.115L11.27 9H7.5zM4.471 2.7L3.057 4.116.943 2 0 2.943l2.115 2.114L.7 6.471h3.77v-3.77z%22 id%3D%22a%22%2F%3E%3C%2Fdefs%3E%3Cuse fill%3D%22%23FFF%22 fill-rule%3D%22nonzero%22 xlink%3Ahref%3D%22%23a%22 transform%3D%22translate%280 -2%29%22 opacity%3D%22.85%22%2F%3E%3C%2Fsvg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-EnlargedPreview-expanded{position:absolute;top:0;left:0;width:100%;height:100%;z-index:4;background-color:rgb(var(--ods-color-neutral-white))}.onfido-sdk-ui-EnlargedPreview-imageContainer{position:absolute;top:0;left:0;width:100%;height:100%}.onfido-sdk-ui-EnlargedPreview-image{min-height:100%;min-width:100%;object-fit:contain;object-position:center;display:block;touch-action:none}.onfido-sdk-ui-EnlargedPreview-container .onfido-sdk-ui-EnlargedPreview-button{position:absolute;left:50%;bottom:0%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);padding:.3125em .9625em;border:0}.onfido-sdk-ui-EnlargedPreview-container .onfido-sdk-ui-EnlargedPreview-button::before{content:\"\";display:inline-block;width:.75em;height:.75em;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:contain;vertical-align:middle;margin-top:-0.125em;margin-right:.625em}.onfido-sdk-ui-EnlargedPreview-container .onfido-sdk-ui-EnlargedPreview-button .onfido-sdk-ui-EnlargedPreview-button-text{font-size:var(--onfido-font-size-x-small);position:relative;top:-0.15em}.onfido-sdk-ui-EnlargedPreview-expanded .onfido-sdk-ui-EnlargedPreview-button{top:auto;bottom:1em;font-size:inherit}.onfido-sdk-ui-EnlargedPreview-expanded .onfido-sdk-ui-EnlargedPreview-button::before{width:1.125em;height:1.125em;margin-top:-0.1875em;margin-right:.5em;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.onfido-sdk-ui-EnlargedPreview-button-overlay{background-color:rgba(15,37,54,.85) !important;border-radius:.25em;color:rgb(var(--ods-color-neutral-white));font:inherit;font-weight:600;height:auto !important;line-height:1.4;padding:.7em 1.225em}.onfido-sdk-ui-EnlargedPreview-button-overlay:hover{background-color:rgba(15,37,54,.6)}.onfido-sdk-ui-EnlargedPreview-button-overlay:active{background-color:rgba(15,37,54,.85)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_animation_duration": "200ms",
	"expanded": "onfido-sdk-ui-EnlargedPreview-expanded",
	"imageContainer": "onfido-sdk-ui-EnlargedPreview-imageContainer",
	"image": "onfido-sdk-ui-EnlargedPreview-image",
	"container": "onfido-sdk-ui-EnlargedPreview-container",
	"button": "onfido-sdk-ui-EnlargedPreview-button",
	"button-text": "onfido-sdk-ui-EnlargedPreview-button-text",
	"button-overlay": "onfido-sdk-ui-EnlargedPreview-button-overlay"
};
export default ___CSS_LOADER_EXPORT___;
