import { gql } from '@apollo/client'

export const UPLOAD_SINGLE = gql`
  mutation UploadSingle($file: Upload!, $isHomePhoto: Boolean, $id: String!) {
    uploadSingle(file: $file, id: $id, isHomePhoto: $isHomePhoto) {
      ETag
      Bucket
      Key
      Location
      key
    }
  }
`

export const COMPRESS_IMG = gql`
  mutation CompressImages($reId: String!) {
    compressImages(reId: $reId)
  }
`

export const UPLOAD_MANY = gql`
  mutation UploadMany($files: [Upload!]!, $uploadManyId: String!) {
    uploadMany(files: $files, id: $uploadManyId) {
      key
      Location
      Bucket
      ETag
    }
  }
`

export const CREATE_REAL_ESTATE_BASIC_INFO = gql`
  mutation CreateRealEstateBasicInfo($input: RealEstateListingInput!) {
    createRealEstateBasicInfo(input: $input) {
      address {
        carrier_code
        census_tract
        city
        formatted_street_address
        geocoding_accuracy
        latitude
        longitude
        state
        street_name
        street_number
        street_post_direction
        street_pre_direction
        street_suffix
        unit_number
        unit_type
        zip_code
        zip_plus_four_code
      }
      assessments {
        improvement_value
        land_value
        total_value
        year
      }
      boundary {
        geojson {
          coordinates
        }
        wkt
      }
      created_at
      deeds {
        buyer2_first_name
        buyer2_last_name
        buyer_address
        buyer_city
        buyer_first_name
        buyer_last_name
        buyer_state
        buyer_unit_number
        buyer_unit_type
        buyer_zip_code
        buyer_zip_plus_four_code
        deed_book
        deed_page
        distressed_sale
        document_id
        document_type
        lender_name
        lender_type
        loan_amount
        loan_due_date
        loan_finance_type
        loan_interest_rate
        loan_type
        original_contract_date
        real_estate_owned
        recording_date
        sale_price
        sale_price_description
        seller2_first_name
        seller2_last_name
        seller_address
        seller_city
        seller_first_name
        seller_last_name
        seller_state
        seller_unit_number
        seller_zip_code
        seller_zip_plus_four_code
        transfer_tax
      }
      market_assessments {
        improvement_value
        land_value
        total_value
        year
      }
      message
      metadata {
        publishing_date
      }
      owner {
        city
        formatted_street_address
        name
        owner_occupied
        second_name
        state
        unit_number
        unit_type
        zip_code
        zip_plus_four_code
      }
      parcel {
        apn_original
        apn_previous
        apn_unformatted
        area_acres
        area_sq_ft
        building_count
        county_land_use_code
        county_land_use_description
        county_name
        depth_ft
        fips_code
        frontage_ft
        legal_description
        location_descriptions
        lot_code
        lot_number
        municipality
        section_township_range
        standardized_land_use_category
        standardized_land_use_type
        subdivision
        tax_account_number
        zoning
      }
      re_id
      structure {
        air_conditioning_type
        amenities
        architecture_type
        basement_type
        baths
        beds_count
        condition
        construction_type
        effective_year_built
        exterior_wall_type
        fireplaces
        flooring_types
        foundation_type
        heating_fuel_type
        heating_type
        interior_wall_type
        other_areas {
          sq_ft
        }
        other_features {
          sq_ft
        }
        other_improvements {
          sq_ft
        }
        other_rooms
        parking_spaces_count
        parking_type
        partial_baths_count
        plumbing_fixtures_count
        pool_type
        quality
        roof_material_type
        roof_style_type
        rooms_count
        sewer_type
        stories
        total_area_sq_ft
        units_count
        water_type
        year_built
      }
      taxes {
        amount
        exemptions
        rate_code_area
        year
      }
      updated_at
      valuation {
        date
        forecast_standard_deviation
        high
        low
        value
      }
    }
  }
`

export const CREATE_REAL_ESTATE_FINANCE = gql`
  mutation CreateRealEstateFinance(
    $assetPrice: Int!
    $closingCost: Int!
    $expectedGrossRentPerProperty: Int!
    $expectedReserveFunds: Int!
    $insurance: Int!
    $maintenanceExpenseInPercentage: Int!
    $platformListFeesInPercentage: Int!
    $propertyMgmtFees: Int!
    $propertyTaxes: Int!
    $reId: String!
    $renovationCosts: Int!
    $rentProcessingFees: Int!
    $utilities: Int!
    $estimatedRentStartDate: String!
    $minimumTokensBuy: Int
  ) {
    createRealEstateFinance(
      assetPrice: $assetPrice
      closingCost: $closingCost
      expectedGrossRentPerProperty: $expectedGrossRentPerProperty
      expectedReserveFunds: $expectedReserveFunds
      insurance: $insurance
      maintenanceExpenseInPercentage: $maintenanceExpenseInPercentage
      platformListFeesInPercentage: $platformListFeesInPercentage
      propertyMgmtFees: $propertyMgmtFees
      propertyTaxes: $propertyTaxes
      re_id: $reId
      renovationCosts: $renovationCosts
      rentProcessingFees: $rentProcessingFees
      utilities: $utilities
      estimatedRentStartDate: $estimatedRentStartDate
      minimumTokensBuy: $minimumTokensBuy
    ) {
      assetPrice
      closingCost
      expectedGrossRentPerProperty
      expectedGrossRoi
      expectedNetRentPerProperty
      expectedNetRoi
      expectedReserveFunds
      expenses
      insurance
      listingPrice
      maintenanceExpense
      netRentPerToken
      platformFees
      platformListingFees
      propertyMgtFees
      propertyTaxes
      re_id
      renovationCosts
      tokenPrice
      totalInvestments
      totalNumberOfTokens
      user_id
      utilities
      estimatedRentStartDate
    }
  }
`

export const UPDATE_SMART_CONTRACT = gql`
  mutation UpdateSmartContractAndAdditionalInfo(
    $additionalPerks: String!
    $closingDate: String!
    $companyName: String!
    $offeringMemorandumDate: String!
    $propertyDescription: String!
    $reId: String!
    $tokenId: String!
    $tokenSmartContractUrl: String!
  ) {
    updateSmartContractAndAdditionalInfo(
      additionalPerks: $additionalPerks
      closingDate: $closingDate
      companyName: $companyName
      offeringMemorandumDate: $offeringMemorandumDate
      propertyDescription: $propertyDescription
      re_id: $reId
      tokenId: $tokenId
      tokenSmartContractUrl: $tokenSmartContractUrl
    ) {
      additionalPerks
      closingDate
      companyName
      offeringMemorandumDate
      propertyDescription
      re_id
      tokenId
      tokenSmartContractUrl
    }
  }
`
