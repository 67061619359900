import Alert from 'src/components/ui/alert/Alert'
import { transactionUpdate } from 'src/utils/constants'

const Coinify = () => {

  const coinifyLink = localStorage?.getItem('coinifyLink')

  return (
    <div className="provider">
      <div className="info">
        <Alert variant="pay" alertMessage={transactionUpdate} />
      </div>
      <div className="frame">
        {coinifyLink && <iframe src={coinifyLink} title="coinify" frameBorder={0}></iframe>}
      </div>
    </div>
  )
}

export default Coinify
