// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-ActiveVideo-FaceNotDetected-faceNotDetected{background:#fff;width:100%;height:100%;border-radius:var(--ods-border-radius-large);display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;-webkit-box-align:center;-webkit-align-items:center;align-items:center}.onfido-sdk-ui-ActiveVideo-FaceNotDetected-list{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;row-gap:24px;padding:0;margin:0;width:100%;-webkit-box-sizing:border-box;box-sizing:border-box}.onfido-sdk-ui-ActiveVideo-FaceNotDetected-list .onfido-sdk-ui-ActiveVideo-FaceNotDetected-listItem{list-style:none;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;-webkit-box-align:center;-webkit-align-items:center;align-items:center;-webkit-column-gap:12px;-moz-column-gap:12px;column-gap:12px;text-align:left}.onfido-sdk-ui-ActiveVideo-FaceNotDetected-list .onfido-sdk-ui-ActiveVideo-FaceNotDetected-listItem svg{min-width:32px}.onfido-sdk-ui-ActiveVideo-FaceNotDetected-list .onfido-sdk-ui-ActiveVideo-FaceNotDetected-listItem .onfido-sdk-ui-ActiveVideo-FaceNotDetected-text{font-family:inherit;font-size:1rem;font-weight:400;line-height:1.5rem;text-transform:none;color:rgba(var(--ods-color-content-secondary));font-style:normal;font-family:var(--osdk-font-family-body)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"faceNotDetected": "onfido-sdk-ui-ActiveVideo-FaceNotDetected-faceNotDetected",
	"list": "onfido-sdk-ui-ActiveVideo-FaceNotDetected-list",
	"listItem": "onfido-sdk-ui-ActiveVideo-FaceNotDetected-listItem",
	"text": "onfido-sdk-ui-ActiveVideo-FaceNotDetected-text"
};
export default ___CSS_LOADER_EXPORT___;
