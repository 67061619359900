import Skeleton from 'react-loading-skeleton'

interface ICardPropertyPerksProps {
  heading: string
  variant?: string
  propertyData?: any
}

const CardPropertyPerks = ({ heading, variant, propertyData }: ICardPropertyPerksProps) => {
  const cardClass = `cardproperty -${variant}`

  return (
    <div className={cardClass}>
      {' '}
      <div className="title -about">
        <h4>{heading}</h4>
      </div>
      <div className="info-property -about">
        <div className="about">
          {propertyData.getRealEstateInfoById.additionalPerks ? (
            <ul>
              <li>{propertyData.getRealEstateInfoById.additionalPerks}</li>
            </ul>
          ) : (
            <Skeleton />
          )}
        </div>
      </div>
    </div>
  )
}

export default CardPropertyPerks
