import { IField } from 'src/types/relisting'

export const aboutMetaRevenue: IField[] = [
  {
    id: 'metaLink',
    label: 'Metaverse Link',
    required: false,
    type: 'textarea',
    placeholder: '',
    variant: 'admin',
    value: false,
  },
  {
    id: 'youtubeLink',
    label: 'Youtube Link',
    required: false,
    type: 'textarea',
    placeholder: '',
    variant: 'admin',
    value: '',
  },
]

export const reListingDetails: IField[] = [
  {
    id: 'streetAddress',
    label: 'Address 1',
    required: true,
    placeholder: '',
    type: 'text',
    variant: 'admin',
    value: '',
  },

  {
    id: 'city',
    label: 'City',
    required: true,
    variant: 'admin',
    placeholder: '',
    type: 'text',
    value: '',
  },

  {
    id: 'state',
    label: 'State',
    required: true,
    variant: 'admin',
    value: '',
    options: [
      {
        option_label: 'Alabama',
        option_value: 'AL',
      },
      {
        option_label: '  Alaska',
        option_value: 'AK',
      },
      {
        option_label: 'Arizona',
        option_value: 'AZ',
      },
      {
        option_label: ' Arkansas',
        option_value: 'AR',
      },
      {
        option_label: 'California',
        option_value: 'CA',
      },
      {
        option_label: 'Colorado',
        option_value: 'CO',
      },
      {
        option_label: 'Connecticut',
        option_value: 'CT',
      },
      {
        option_label: 'Delaware',
        option_value: 'DE',
      },
      {
        option_label: 'Florida',
        option_value: 'FL',
      },
      {
        option_label: 'Georgia',
        option_value: 'GA',
      },

      {
        option_label: 'Hawaii',
        option_value: 'HI',
      },
      {
        option_label: 'Idaho',
        option_value: 'ID',
      },
      {
        option_label: 'Illinois',
        option_value: 'IL',
      },
      {
        option_label: 'Idaho',
        option_value: 'ID',
      },
      {
        option_label: 'Iowa',
        option_value: 'IA',
      },
      {
        option_label: 'Kansas',
        option_value: 'KS',
      },
      {
        option_label: 'Kentucky',
        option_value: 'KY',
      },
      {
        option_label: 'Louisiana',
        option_value: 'LA',
      },
      {
        option_label: 'Maine',
        option_value: 'ME',
      },
      {
        option_label: 'Maryland',
        option_value: 'MD',
      },
      {
        option_label: 'Massachusetts',
        option_value: 'MA',
      },
      {
        option_label: 'Michigan',
        option_value: 'MI',
      },
      {
        option_label: 'Minnesota',
        option_value: 'MN',
      },
      {
        option_label: 'Mississippi',
        option_value: 'MS',
      },
      {
        option_label: 'Missouri',
        option_value: 'MO',
      },

      {
        option_label: 'Montana',
        option_value: 'MT',
      },
      {
        option_label: 'Nebraska',
        option_value: 'NE',
      },
      {
        option_label: 'Nevada',
        option_value: 'NV',
      },

      {
        option_label: 'New Hampshire',
        option_value: 'NH',
      },
      {
        option_label: 'New Jersey',
        option_value: 'NJ',
      },
      {
        option_label: 'New Mexico',
        option_value: 'NM',
      },
      {
        option_label: 'New York',
        option_value: 'NY',
      },

      {
        option_label: 'North Carolina',
        option_value: 'NC',
      },
      {
        option_label: 'North Dakota',
        option_value: 'ND',
      },
      {
        option_label: 'Ohio',
        option_value: 'OH',
      },
      {
        option_label: 'Oklahoma',
        option_value: 'OK',
      },
      {
        option_label: 'Oregon',
        option_value: 'OR',
      },
      {
        option_label: 'Pennsylvania',
        option_value: 'PA',
      },
      {
        option_label: 'Rhode Island',
        option_value: 'RI',
      },
      {
        option_label: 'South Carolina',
        option_value: 'SC',
      },
      {
        option_label: 'South Dakota',
        option_value: 'SD',
      },
      {
        option_label: 'Tennessee',
        option_value: 'TN',
      },
      {
        option_label: 'Texas',
        option_value: 'TX',
      },
      {
        option_label: 'Utah',
        option_value: 'UT',
      },
      {
        option_label: 'Vermont',
        option_value: 'VT',
      },
      {
        option_label: 'Virginia',
        option_value: 'VA',
      },
      {
        option_label: 'Washington',
        option_value: 'WA',
      },
      {
        option_label: 'West Virginia',
        option_value: 'WV',
      },
      {
        option_label: 'Wisconsin',
        option_value: 'WI',
      },
      {
        option_label: 'Wyoming',
        option_value: 'WY',
      },
    ],
    type: 'select',
  },
  {
    id: 'zipCode',
    label: 'Zip Code',
    required: true,
    variant: 'admin',
    placeholder: '',
    type: 'float',
    value: 0,
  },
]

export const reListingEstatedStructure: IField[] = [
  {
    id: 'construction_type',
    label: 'Type of Build',
    value: '',
    variant: 'admin',
    required: true,
    type: 'select',
    options: [
      {
        option_label: 'Concrete',
        option_value: 'Concrete',
        selected: false,
      },
      {
        option_label: 'Wood frame',
        option_value: 'Wood frame',
        selected: false,
      },
      {
        option_label: 'Masonry',
        option_value: 'Masonry',
        selected: false,
      },
    ],
  },
  {
    id: 'baths',
    label: 'Number Of Bathrooms',
    required: true,
    placeholder: '',
    variant: 'admin',
    value: 0,
    type: 'float',
  },
  {
    id: 'beds_count',
    label: 'Number Of Rooms',
    required: true,
    placeholder: '',
    variant: 'admin',
    value: 0,
    type: 'float',
  },
  {
    id: 'foundation_type',
    label: 'Foundation',
    required: true,

    variant: 'admin',
    value: '',

    type: 'select',
    options: [
      {
        option_label: 'Concrete Slab',
        option_value: 'Concrete Slab',
        selected: false,
      },
      {
        option_label: 'Wood',
        option_value: 'Wood',
        selected: false,
      },
      {
        option_label: 'Beam',
        option_value: 'Beam',
        selected: false,
      },
    ],
  },
  {
    id: 'air_conditioning_type',
    label: 'Cooling',
    required: true,
    placeholder: '',
    type: 'text',
    variant: 'admin',
    value: '',
  },
  {
    id: 'architecture_type',
    label: 'Architecture',
    required: true,
    placeholder: '',
    type: 'text',
    variant: 'admin',
    value: '',
  },

  {
    id: 'basement_type',
    label: 'Basement',
    required: true,

    variant: 'admin',
    value: '',

    type: 'select',
    options: [
      {
        option_label: 'Has Basement',
        option_value: 'Has Basement',
        selected: false,
      },
      {
        option_label: 'Finished Basement',
        option_value: 'Finished Basement',
        selected: false,
      },
      {
        option_label: 'Unfinished Basement',
        option_value: 'Unfinished Basement',
        selected: false,
      },
      {
        option_label: 'No Basement',
        option_value: 'No Basement',
        selected: false,
      },
    ],
  },
  {
    id: 'condition',
    label: 'Condition',
    required: true,
    placeholder: '',
    type: 'text',
    variant: 'admin',
    value: '',
  },
  {
    id: 'exterior_wall_type',
    label: 'Exterior Wall Type',
    required: true,
    placeholder: '',
    type: 'text',
    variant: 'admin',
    value: '',
  },

  {
    id: 'fireplaces',
    label: 'Fireplaces',
    required: true,
    placeholder: '',
    type: 'text',
    variant: 'admin',
    value: '',
  },
  {
    id: 'flooring_types',
    label: 'Flooring Types',
    required: true,
    placeholder: '',
    type: 'text',
    variant: 'admin',
    value: '',
  },
  {
    id: 'heating_type',
    label: 'Heating',
    required: true,
    placeholder: '',
    type: 'text',
    variant: 'admin',
    value: '',
  },

  {
    id: 'parking_spaces_count',
    label: 'Parking Spaces',
    required: true,
    placeholder: '',
    type: 'float',
    variant: 'admin',
    value: 0,
  },
  {
    id: 'parking_type',
    label: 'Parking',
    required: true,

    variant: 'admin',
    value: '',

    type: 'select',
    options: [
      {
        option_label: 'Parking 1',
        option_value: 'Parking 1',
        selected: false,
      },
    ],
  },
  {
    id: 'partial_baths_count',
    label: 'Partial Baths Count',
    required: true,
    placeholder: '',
    type: 'float',
    variant: 'admin',
    value: 0,
  },
  {
    id: 'roof_material_type',
    label: 'Roof Material',
    required: true,
    placeholder: '',
    type: 'text',
    variant: 'admin',
    value: '',
  },
  {
    id: 'roof_style_type',
    label: 'Roof Material Style',
    required: true,
    placeholder: '',
    type: 'text',
    variant: 'admin',
    value: '',
  },
  {
    id: 'stories',
    label: 'Stories',
    required: true,
    placeholder: '',
    variant: 'admin',
    value: 0,
    type: 'float',
  },
  {
    id: 'units_count',
    label: 'Total Units',
    required: true,
    placeholder: '',
    variant: 'admin',
    type: 'number',
    value: '',
  },
  {
    id: 'year_built',
    label: 'Built Year',
    required: true,
    placeholder: '',
    type: 'float',
    variant: 'admin',
    value: 0,
  },
]
export const reListingEstatedTaxes: IField[] = [
  {
    id: 'amount',
    label: 'Property Taxes',
    required: true,
    placeholder: '',
    type: 'float',
    variant: 'admin',
    value: 0,
  },
  {
    id: 'year',
    label: 'Tax Year',
    required: true,
    placeholder: '',
    type: 'float',
    variant: 'admin',
    value: 0,
  },
]

export const reListingEstatedParcel: IField[] = [
  {
    id: 'municipality',
    label: 'Municipality',
    required: false,
    placeholder: '',
    type: 'text',
    variant: 'admin',
    value: '',
  },

  {
    id: 'area_acres',
    label: 'Acres',
    required: true,
    placeholder: '',
    type: 'float',
    variant: 'admin',
    value: 0,
  },
  {
    id: 'area_sq_ft',
    label: 'Square Ft',
    required: true,
    placeholder: '',
    type: 'float',
    variant: 'admin',
    value: 0,
  },
  {
    id: 'building_count',
    label: 'No. of Buildings',
    required: true,
    placeholder: '',
    type: 'float',
    variant: 'admin',
    value: 0,
  },
  {
    id: 'county_land_use_code',
    label: 'Building Code',
    required: true,
    placeholder: '',
    type: 'text',
    variant: 'admin',
    value: '',
  },

  {
    id: 'county_land_use_description',
    label: 'Property Type',
    value: '',
    variant: 'admin',
    required: true,
    type: 'select',
    options: [
      {
        option_label: 'Multi Family',
        option_value: 'Multi Family',
        selected: false,
      },
      {
        option_label: 'Apartment',
        option_value: 'Apartment',
        selected: false,
      },

      {
        option_label: 'Commercial',
        option_value: 'Commercial',
        selected: false,
      },
      {
        option_label: 'Storage',
        option_value: 'Storage',
        selected: false,
      },
      {
        option_label: 'Single Family',
        option_value: 'Single Family',
        selected: false,
      },
    ],
  },

  {
    id: 'county_name',
    label: 'County Name',
    required: true,
    placeholder: '',
    type: 'text',
    variant: 'admin',
    value: '',
  },

  {
    id: 'frontage_ft',
    label: 'Frontage',
    required: true,
    placeholder: '',
    type: 'float',
    variant: 'admin',
    value: 0,
  },
  {
    id: 'subdivision',
    label: 'Neighborhood',
    required: true,
    placeholder: '',
    type: 'text',
    variant: 'admin',
    value: '',
  },
  {
    id: 'zoning',
    label: 'Zoning',
    required: true,
    placeholder: '',
    type: 'text',
    variant: 'admin',
    value: '',
  },
]

export const reListingManual: IField[] = [
  {
    id: 'assetPrice',
    label: 'Asset Price',
    required: true,
    placeholder: '',
    type: 'float',
    variant: 'admin',
    value: 0,
  },
  {
    id: 'closingCost',
    label: 'Closing Costs',
    required: true,
    placeholder: '',
    type: 'float',
    variant: 'admin',
    value: 0,
  },
  {
    id: 'expectedGrossRentPerProperty',
    label: 'Expected Gross Rent Per Property',
    required: true,
    placeholder: '',
    type: 'float',
    variant: 'admin',
    value: 0,
  },
  {
    id: 'expectedReserveFunds',
    label: 'Expected Reserve Funds',
    required: true,
    placeholder: '',
    type: 'float',
    variant: 'admin',
    value: 0,
  },
  {
    id: 'renovationCosts',
    label: 'Renovation Costs',
    required: true,
    placeholder: '',
    type: 'float',
    variant: 'admin',
    value: 0,
  },

  {
    id: 'propertyTaxes',
    label: 'Property Taxes',
    required: true,
    placeholder: '',
    type: 'float',
    variant: 'admin',
    value: 0,
  },
  {
    id: 'insurance',
    label: 'Insurance',
    required: true,
    placeholder: '',
    type: 'float',
    variant: 'admin',
    value: 0,
  },

  {
    id: 'utilities',
    label: 'Utilities',
    required: true,
    placeholder: '',
    variant: 'admin',
    type: 'float',
    value: 0,
  },
  {
    id: 'platformListFeesInPercentage',
    label: 'Platform Listing Fees',
    required: true,
    placeholder: '',
    variant: 'admin',
    type: 'float',
    value: 0,
  },
  {
    id: 'rentProcessingFees',
    label: 'Rent Processing Fees(%)',
    required: true,
    placeholder: '',
    variant: 'admin',
    type: 'float',
    value: 0,
  },
  {
    id: 'propertyMgmtFees',
    label: 'Property Management Fees(%)',
    required: true,
    placeholder: '',
    variant: 'admin',
    type: 'float',
    value: 0,
  },
  {
    id: 'maintenanceExpenseInPercentage',
    label: ' AirBnb/VRBO fees(%)',
    required: true,
    placeholder: '',
    variant: 'admin',
    type: 'float',
    value: 0,
  },
  {
    id: 'estimatedRentStartDate',
    label: 'Estimated Rent Start Date',
    required: true,
    placeholder: '',
    variant: 'admin',
    type: 'date',
    value: '',
  },
  {
    id: 'minimumTokensBuy',
    label: 'Minimum Tokens Buy',
    required: false,
    placeholder: '',
    type: 'number',
    variant: 'admin',
    value: '',
  },
]
export const reListingToken: IField[] = [
  {
    id: 'companyName',
    label: 'Company Name',
    required: true,
    placeholder: '',
    type: 'text',
    variant: 'admin',
    value: '',
  },
  {
    id: 'closingDate',
    label: 'Closing Date',
    required: true,
    placeholder: '',
    type: 'date',
    variant: 'admin',
    value: '',
  },
  {
    id: 'offeringMemorandumDate',
    label: 'Offering Memorandum Date',
    required: true,
    placeholder: '',
    type: 'date',
    variant: 'admin',
    value: '',
  },
  {
    id: 'tokenId',
    label: 'Token Id',
    required: false,
    placeholder: '',
    type: 'textarea',
    variant: 'admin',
    value: '',
    maxlength: 200,
  },

  {
    id: 'tokenSmartContractUrl',
    label: 'Token Smart Contract Url',
    required: false,
    placeholder: '',
    type: 'text',
    variant: 'admin',
    value: '',
  },
  {
    id: 'propertyDescription',
    label: 'Property Description',
    required: false,
    placeholder: '',
    type: 'textarea',
    variant: 'admin',
    value: '',
    maxlength: 200,
  },
  {
    id: 'additionalPerks',
    label: 'Additional Perks',
    required: false,
    placeholder: '',
    type: 'textarea',
    variant: 'admin',
    value: '',
  },
]

export const reListingFinancials: IField[] = [
  {
    id: 'listingPrice',
    label: 'Listing Price',
    required: true,
    placeholder: '',
    type: 'float',
    variant: 'admin',
    value: 0,
  },
  {
    id: 'totalInvestments',
    label: 'Total Investments',
    required: true,
    placeholder: '',
    type: 'float',
    variant: 'admin',
    value: 0,
  },
  {
    id: 'assetPrice',
    label: 'Asset Price',
    required: true,
    placeholder: '',
    type: 'float',
    variant: 'admin',
    value: 0,
  },
  {
    id: 'closingCost',
    label: 'Closing Cost',
    required: true,
    placeholder: '',
    type: 'float',
    variant: 'admin',
    value: 0,
  },
  {
    id: 'renovationCosts',
    label: 'Renovation Costs',
    required: true,
    placeholder: '',
    type: 'float',
    variant: 'admin',
    value: 0,
  },

  {
    id: 'platformListingFees',
    label: 'Platform Listing Fees ',
    required: true,
    placeholder: '',
    variant: 'admin',
    type: 'float',
    value: 0,
  },

  {
    id: 'expectedNetRentRoiPerProperty',
    label: 'Expected Net Rent Roi Per Property ',
    required: true,
    placeholder: '',
    type: 'float',
    variant: 'admin',
    value: 0,
  },
  {
    id: 'expectedNetRoiPerProperty',
    label: 'Expected Net Roi Per Property ',
    required: true,
    placeholder: '',
    type: 'float',
    variant: 'admin',
    value: 0,
  },
  {
    id: 'insurance',
    label: 'Insurance',
    required: true,
    placeholder: '',
    type: 'float',
    variant: 'admin',
    value: 0,
  },
  {
    id: 'utilities',
    label: 'Utilities',
    required: true,
    placeholder: '',
    variant: 'admin',
    type: 'float',
    value: 0,
  },
  {
    id: 'propertyMgtFees',
    label: 'Property Management Fees ',
    required: true,
    placeholder: '',
    variant: 'admin',
    type: 'float',
    value: 0,
  },
  {
    id: 'propertyTaxes',
    label: 'Property Taxes',
    required: true,
    placeholder: '',
    variant: 'admin',
    type: 'float',
    value: 0,
  },

  {
    id: 'expectedGrossRoiPerProperty',
    label: 'Expected Gross Roi Per Property',
    required: true,
    placeholder: '',
    variant: 'admin',
    type: 'float',
    value: 0,
  },
  {
    id: 'expectedGrossRentPerProperty',
    label: 'Expected Gross Rent Per Property',
    required: true,
    placeholder: '',
    variant: 'admin',
    type: 'float',
    value: 0,
  },
  {
    id: 'expectedReserveFunds',
    label: 'Expected Reserved Funds',
    required: true,
    placeholder: '',
    variant: 'admin',
    type: 'float',
    value: 0,
  },

  {
    id: 'numberOfTokens',
    label: 'No. of Tokens',
    required: true,
    placeholder: '',
    variant: 'admin',
    type: 'float',
    value: 0,
  },
  {
    id: 'tokenPrice',
    label: 'Token Price',
    required: true,
    placeholder: '',
    variant: 'admin',
    type: 'float',
    value: 0,
  },
  {
    id: 'netRentPerToken',
    label: 'Net Rent Per Token',
    required: true,
    placeholder: '',
    variant: 'admin',
    type: 'float',
    value: 0,
  },
  {
    id: 'expenses',
    label: 'Expenses',
    required: true,
    placeholder: '',
    variant: 'admin',
    type: 'float',
    value: 0,
  },
  {
    id: 'maintenanceExpense',
    label: 'Maintenance Expense',
    required: true,
    placeholder: '',
    variant: 'admin',
    type: 'float',
    value: 0,
  },
]
