import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { toFixedSimple } from 'src/utils/relisting'
import Button from '../button/Button'
import { checkAllPermissions } from 'src/utils/config/routes'
import { Slide, toast } from 'react-toastify'
import { enableBuyTokens } from 'src/utils/constants'
import { useAuth } from 'src/context/useAuth'
import { useLazyQuery } from '@apollo/client'
import { GET_REAL_ESTATE_FINANCE_BY_RE_ID } from 'src/client/queries/relisting'

interface ITokenFormInputs {
  tokens: number
  dollar: number
}

export default function TokenForm({
  tokenPrice,
  realEstateId,
  totalNumberOfTokens,

  rbacPerm,
}: any) {
  const formattedTokenPrice = toFixedSimple(Number(tokenPrice), 2)
  const [dollarVal, setDollarVal] = useState(formattedTokenPrice)
  const [loading, setLoading] = useState(false)
  const [changeInputs, setChangeInputs] = useState(false)

  const [getTokensRemaining, { loading: tokenFetchLoading }] = useLazyQuery(
    GET_REAL_ESTATE_FINANCE_BY_RE_ID,
    {
      variables: { input: { re_id: realEstateId } },
      onCompleted: (_data) => {},
    }
  )

  //@ts-ignore
  const { user } = useAuth()
  const updateDollarValue = (e: any) => {
    setDollarVal(toFixedSimple(e.target.value * formattedTokenPrice, 2))
  }

  const navigate = useNavigate()
  const authAlert = () => {
    return toast(enableBuyTokens, {
      position: 'top-center',
      autoClose: 4000,
      hideProgressBar: false,
      theme: 'colored',
      type: 'info',
      onClose: () => navigate('/me/details'),
    })
  }

  const signAlert = () => {
    return toast('Please Sign In to buy tokens.', {
      position: 'top-center',
      autoClose: 4000,
      hideProgressBar: false,
      theme: 'colored',
      type: 'info',
      onClose: () => navigate('/auth'),
    })
  }

  const formErrorAlert = (errorMsg: string) => {
    return toast.error(errorMsg, {
      position: 'top-left',
      hideProgressBar: true,
      pauseOnHover: false,
      draggable: true,
      closeButton: false,
      transition: Slide,
    })
  }

  const onComplete = () => {
    setChangeInputs(true)
    authAlert()
  }
  const onCompleteSign = () => {
    setChangeInputs(true)
    signAlert()
  }
  const tokenValues = {
    tokens: 0,
  }
  const tokenSchema = yup.object().shape({
    tokens: yup.number().positive().typeError('').required('Enter number of tokens'),
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ITokenFormInputs>({
    resolver: yupResolver(tokenSchema),
    defaultValues: tokenValues,
  })

  const allowedPermissions = ['kyc_status', 'blockchain_address']

  const onSubmit: SubmitHandler<ITokenFormInputs> = async (data: any) => {
    const permitted = checkAllPermissions(rbacPerm, allowedPermissions)
    if (permitted) {
      const {
        data: {
          getRealEstateFinanceByReId: { tokensRemaining },
        },
      } = await getTokensRemaining()

      const availableTokens = tokensRemaining !== null ? tokensRemaining : totalNumberOfTokens

      if (data.tokens > availableTokens) {
        formErrorAlert(`Tokens available to buy at this moment is ${availableTokens}`)
        return
      }

      setLoading(!loading)
      data.reId = await realEstateId
      data.tokenPrice = Number(await tokenPrice)
      data.totalNumberOfTokens = await totalNumberOfTokens
      localStorage.removeItem('payData')
      localStorage.setItem('payData', JSON.stringify(data))
      setLoading(loading)
      navigate(`/pay/${realEstateId}`, {
        state: { reData: realEstateId },
      })
    } else {
      setChangeInputs(true)
      authAlert()
    }
  }
  useEffect(() => {
    if (errors?.tokens?.message) {
      formErrorAlert(`${errors.tokens?.message}`)
    }
  }, [errors, changeInputs])
  if (user.userId && user.userId.length > 0) {
    return (
      <>
        <div className={changeInputs ? `token-panel  -token-dim` : `token-panel`}>
          <form className="token-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="details">
              <label className={`label`} htmlFor="firstname">
                Quantity to Buy
              </label>
              {totalNumberOfTokens && tokenPrice ? (
                <div className="inputcontainer">
                  <input
                    className="input"
                    type="number"
                    {...register('tokens', { onChange: updateDollarValue })}
                  />
                </div>
              ) : (
                <div>loading</div>
              )}
            </div>
            <div className="details">
              <label className={`label`} htmlFor="firstname">
                Dollar Value
              </label>
              <div className="inputcontainer">
                <input
                  className="input -dollarValue"
                  disabled
                  type="number"
                  value={dollarVal}
                  {...register('dollar', { min: formattedTokenPrice })}
                />
              </div>
            </div>

            <div className="token-submit">
              {tokenFetchLoading ? (
                <Button size="md" variant="buyToken" label="Buy Tokens" loading={true} />
              ) : (
                <Button size="md" variant="buyToken" label="Buy Tokens" loading={false} />
              )}
            </div>
          </form>
        </div>
      </>
    )
  }

  return (
    <>
      <div className={changeInputs ? `token-panel  -token-dim` : `token-panel`}>
        <form className="token-form" onSubmit={(e) => e.preventDefault()}>
          <div className="details">
            <label className={changeInputs ? `labeldim` : `label`} htmlFor="firstname">
              Quantity to Buy
            </label>
            {totalNumberOfTokens && tokenPrice ? (
              <div className="inputcontainer">
                <input
                  className="input"
                  type="number"
                  {...register('tokens', { onChange: updateDollarValue })}
                />
              </div>
            ) : (
              <div>loading</div>
            )}
          </div>
          <div className="details">
            <label className={changeInputs ? `labeldim` : `label`} htmlFor="firstname">
              Dollar Value
            </label>
            <div className="inputcontainer">
              <input
                className="input -dollarValue"
                disabled
                type="number"
                value={dollarVal}
                {...register('dollar', { min: formattedTokenPrice })}
              />
            </div>
          </div>

          <div className="token-submit">
            <Button size="sm" handleClick={onCompleteSign} label="Buy Tokens" />
          </div>
        </form>
      </div>
    </>
  )
}
