import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useDropzone } from 'react-dropzone'
import REFilePreview from './REFilePreview'
import REFileHomeImagePreview from './REFileHomeImagePreview'

interface IFileInputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string
  mode?: 'update' | 'append'
  name: string
  accept: string
}

export const FileInput: FC<IFileInputProps> = (props: IFileInputProps) => {
  const { name, accept, label = name, mode = 'update' } = props
  const { register, unregister, setValue, watch } = useFormContext()
  const [home, setHome] = useState<ReactNode>(null)
  const files: File[] = watch(name)

  const onDrop = useCallback(
    (droppedFiles) => {
      let newFiles: File[] = mode === 'update' ? droppedFiles : [...(files || []), ...droppedFiles]
      if (mode === 'append') {
        newFiles = newFiles.reduce((prev: any, file: any) => {
          const fo = Object.entries(file)
          if (
            prev.find((e: File) => {
              const eo = Object.entries(e)
              return eo.every(
                ([key, value], index) => key === fo[index][0] && value === fo[index][1]
              )
            })
          ) {
            return prev
          } else {
            return [...prev, file]
          }
        }, [])
      }
      setValue(name, newFiles, { shouldValidate: true })
    },
    [setValue, name, mode, files]
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: accept,
  })

  const handleAddHomeImage = (idx: number) => (e: any) => {
    e.preventDefault()
    setHome(<REFileHomeImagePreview img={URL.createObjectURL(files[idx])} file={files[idx]} />)
    localStorage.setItem('homeNumber', `${idx}`)
  }

  const removeFile = (file: File) => () => {
    const newFiles = [...files]
    newFiles.splice(newFiles.indexOf(file), 1)
    setValue(name, newFiles)
  }

  const removeAll = () => {
    setValue(name, [])
    localStorage.clear()
    setHome(null)
  }

  useEffect(() => {
    register(name)
    return () => {
      unregister(name)
    }
  }, [register, unregister, name])
  return (
    <>
      <div className="upload-label">
        <label htmlFor={label}>Real Estate {label}</label>
      </div>
      <div className="upload">
        <div className="upload-box" {...getRootProps()}>
          <input {...props} id={name} type="file" {...getInputProps()} />
          <div className="upload-box-images">
            <p>Drop files here</p>
            <button type="button" className="browse">
              Browse Images
            </button>
          </div>
        </div>
        {!!files?.length && (
          <div className="preview">
            <div className="preview-info">
              <button className="button" onClick={removeAll}>
                Start Over
              </button>
              <p>Click on a Thumbnail Image from the below images.</p>
            </div>
            <div className="preview-thumbnail">
              <>{home}</>
            </div>
            <div className="preview-gallery">
              <>
                {files.map((file, index) => {
                  return (
                    <REFilePreview
                      key={file.name}
                      img={URL.createObjectURL(file)}
                      file={file}
                      number={index}
                      onClick={removeFile(file)}
                      handleAddHomeImage={handleAddHomeImage(index)}
                    />
                  )
                })}
              </>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
