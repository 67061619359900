import { gql } from '@apollo/client'

export const GET_REFERRAL_INFO = gql`
  query GetReferralInfo($userId: String!) {
    getReferralInfo(userId: $userId) {
      referralCode
      referralStats {
        noOfUsersCompletedKyc
        noOfUsersPurchasedTokens
        noOfUsersRegistered
        totalCommissionDistributed
        totalCommissionEarned
        totalCommissionRemaining
        totalDollarsSpentByReferrals
        totalPointsEarned
        totalTokensPurchasedByReferrals
      }
    }
  }
`
