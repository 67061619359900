import React from 'react'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Dialog from '@material-ui/core/Dialog'
import Button from '../ui/button/Button'
import { downloadContract } from 'src/utils/relisting'
import { ThreeBlocksLoader } from '../ui/loader/Loader'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { UPDATE_PPM_OP_AGREEMENT } from 'src/client/mutations/auth'
import { OperatingAgreement } from '../ui/contracts/GenericContracts'
import { GET_PPM_OP_AGREEMENT } from 'src/client/queries/auth'
import { GET_AGREEMENT_DOC_LINK } from 'src/client/queries/relisting'
import UserAgreementOp from '../ui/form/UserAgreementOp'
import { CONTRACT_EXPIRY } from 'src/utils/constants'

export default function OpContract({
  realEstateId,
  user,
  allReData,
  allReLoading,
  opDialogStatus,
  setOpDialogStatus,
}: any) {
  const [open, setOpen] = React.useState(opDialogStatus ? true : false)
  const [globalLoading, setGlobalLoading] = React.useState(false)
  const [activateDownloadBtn, setActivateDownloadBtn] = React.useState(false)
  const [updatePpmOrOperatingDocAgreement] = useMutation(UPDATE_PPM_OP_AGREEMENT, {
    refetchQueries: [
      {
        query: GET_PPM_OP_AGREEMENT,
      },
    ],
  })

  const [getPpmOrOperatingDocAgreement, { loading: queryLoading }] = useLazyQuery(
    GET_PPM_OP_AGREEMENT,
    {
      onCompleted: (_data: any) => {
        if (_data?.getPpmOrOperatingDocAgreement?.operatingAgreement) {
          setGlobalLoading(false)
          setOpen(false)
        }
      },
    }
  )

  const { data: ppmLinkData, loading: ppmLinkLoading } = useQuery(GET_AGREEMENT_DOC_LINK, {
    variables: {
      reId: realEstateId,
    },
  })

  const updateOp = () => {
    setGlobalLoading(true)
    updatePpmOrOperatingDocAgreement({
      variables: {
        operatingAccepted: true,
        reId: realEstateId,
        userId: user.userId,
      },
      onCompleted: (data: any) => {},
    })
    setTimeout(() => {
      getPpmOrOperatingDocAgreement({
        variables: {
          userId: user.userId,
          reId: realEstateId,
        },
      })
    }, CONTRACT_EXPIRY)
    setOpDialogStatus(false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCloseDownload = () => {
    setGlobalLoading(true)
    downloadContract(
      allReData?.getRealEstateInfoById.address.formatted_street_address,
      `Operating Agreement`,
      `${realEstateId}/operating.pdf`
    )
    updateOp()
  }

  if (allReLoading || ppmLinkLoading || queryLoading) {
    return (
      <div>
        {' '}
        <Dialog
          className="tnc-dialog"
          open={open}
          onClose={(_, reason) => {
            if (!(reason === 'backdropClick' || reason === 'escapeKeyDown')) {
              handleClose()
            }
          }}
        >
          <ThreeBlocksLoader />
        </Dialog>
      </div>
    )
  }
  return (
    <div>
      <Dialog
        className="tnc-dialog"
        open={open}
        onClose={(_, reason) => {
          if (!(reason === 'backdropClick' || reason === 'escapeKeyDown')) {
            handleClose()
          }
        }}
      >
        <DialogContent className="dialogcontent">
          <DialogContentText className="dialogcontenttext">
            <OperatingAgreement
              realEstate={allReData?.getRealEstateInfoById.address.formatted_street_address}
              docLink={ppmLinkData?.getAgreementDocLink.operatingLink}
            />
          </DialogContentText>
          <UserAgreementOp
            activateDownloadBtn={activateDownloadBtn}
            setActivateDownloadBtn={setActivateDownloadBtn}
            handleCloseDownload={handleCloseDownload}
            contractType={'op'}
          />
          {activateDownloadBtn ? (
            <DialogActions className="dialogactions">
              <div className="actionBtns">
                <Button
                  variant={globalLoading ? 'disabled' : 'primary'}
                  disabled={globalLoading ? true : false}
                  handleClick={handleCloseDownload}
                  label={globalLoading ? 'Downloading...' : 'Download & Close'}
                />
              </div>
            </DialogActions>
          ) : null}
        </DialogContent>
      </Dialog>
    </div>
  )
}
