import { Outlet } from 'react-router-dom'
import Header from 'src/components/sections/Header'
import { useAuth } from 'src/context/useAuth'
import { protectedRoute } from 'src/utils/config/routes'
import FooterLandingPage from 'src/components/sections/FooterLandingPage'

function REListingPage() {
  //@ts-ignore
  const { user } = useAuth()

  return (
    <>
      <Header variant="standard" user={user} />
      <Outlet />
      <FooterLandingPage />
    </>
  )
}

export default protectedRoute(REListingPage)
