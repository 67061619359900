// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-DocumentVideo-CaptureControls-controls{-webkit-box-flex:1;-webkit-flex:1;flex:1;margin:0 1em 3em;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column}.onfido-sdk-ui-DocumentVideo-CaptureControls-buttonPlaceholder{height:3em}.onfido-sdk-ui-DocumentVideo-CaptureControls-progress{-webkit-align-self:center;align-self:center;background-color:rgba(255,255,255,.4);border-radius:.25em;color:rgb(var(--ods-color-neutral-white));font-size:var(--onfido-font-size-small);margin-top:1em;padding:.25em .625em;text-transform:uppercase}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_animation_duration": "200ms",
	"controls": "onfido-sdk-ui-DocumentVideo-CaptureControls-controls",
	"buttonPlaceholder": "onfido-sdk-ui-DocumentVideo-CaptureControls-buttonPlaceholder",
	"progress": "onfido-sdk-ui-DocumentVideo-CaptureControls-progress"
};
export default ___CSS_LOADER_EXPORT___;
