import { AiOutlineTwitter } from 'react-icons/ai'
import { BiLinkExternal } from 'react-icons/bi'
import {
  FaDiscord,
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
  FaTelegram,
  FaYoutube,
} from 'react-icons/fa'
import { Link } from 'react-router-dom'

const FooterLandingPage: any = () => {
  return (
    <div className="footerlanding">
      <div className="mainlinks">
        <div className="sociallinks">
          <ul className="soc">
            <li className="socitem">
              <a href="https://web.facebook.com/3Blocks.io/" target="_blank" rel="noreferrer">
                <div className="social_icons">
                  <FaFacebook size={20} />
                </div>
              </a>
            </li>
            <li className="socitem">
              <a href="https://twitter.com/3blocks_io" target="_blank" rel="noreferrer">
                <div className="social_icons">
                  <AiOutlineTwitter size={20} />
                </div>
              </a>
            </li>

            <li className="socitem">
              <a
                href="https://www.linkedin.com/company/3blocks-io/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="social_icons">
                  <FaLinkedinIn size={20} />
                </div>
              </a>
            </li>
            <li className="socitem">
              <a href="https://www.instagram.com/3blocks_io/" target="_blank" rel="noreferrer">
                <div className="social_icons">
                  <FaInstagram size={20} />
                </div>
              </a>
            </li>
            <li className="socitem">
              <a
                href="https://www.youtube.com/channel/UCSYcAcHJ9n9VICYRTaCCrgQ"
                target="_blank"
                rel="noreferrer"
              >
                <div className="social_icons">
                  <FaYoutube size={20} />
                </div>
              </a>
            </li>
            <li className="socitem">
              <a href="https://discord.gg/XS2QZTbYsy" target="_blank" rel="noreferrer">
                <div className="social_icons">
                  <FaDiscord size={20} />
                </div>
              </a>
            </li>
            <li className="socitem">
              <a href="https://t.me/Three_Blocks_General_Discussions">
                <div className="social_icons">
                  <FaTelegram size={20} />
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div className="otherpages">
          <ul className="soc -about">
            <li className="socitem">
              <Link to={'/about'}>About Us</Link>
            </li>
            <li className="socitem">
              <Link to={'/blogs'}>Blogs</Link>
            </li>
            <li className="socitem">
              <Link to={'/faq'}>FAQs</Link>
            </li>
            <li className="socitem">
              <a
                href="https://gamma.app/docs/3Blocks-Whitepaper-aajs6lpnp2194wi"
                target="_blank"
                rel="noreferrer"
              >
                <div className="socitem">
                  Whitepaper
                </div>
              </a>
            </li>
          
          </ul>
        </div>
        <div className="legallinks">
          <ul className="soc -about">
            <li className="socitem">
              <Link to={'/terms-of-use'}>Terms of Use</Link>
            </li>
            <li className="socitem">
              <Link to={'/privacy-policy'}>Privacy Policy </Link>
            </li>
            <li className="socitem">
              <Link to={'/cookie-policy'}>Cookie Policy </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="disclaimer">
        <p>
          This site is operated by 3Blocks Inc, which is not a registered broker-dealer or
          investment advisor or ATS.
        </p>
        <p>
          3Blocks Inc does not give investment advice, endorsement, analysis or recommendations with
          respect to any securities.
        </p>
        <p>
          Offers to sell, or the solicitations of offers to buy, any security can only be made
          through official offering documents.
        </p>
        <p>
          Investors should conduct their own due diligence, not rely on the financial assumptions or
          estimates displayed on this website, and are encouraged to consult
        </p>
        <p>
          with the financial advisor, attorney, accountant, and any other professional that can help
          understand and assess the risks associated with any investment opportunity.
        </p>
        <p>
          3Blocks Inc, does not guarantee any investment performance, outcome or return of capital
          for any investment opportunity posted on this site.
        </p>
        <p>
          By accessing this site and any pages thereof, you agree to be bound by the Terms of
          Service and Privacy Policy.
        </p>
      </div>
      <div>
        <div className="date">
          <p>3Blocks © 2023.&nbsp; &nbsp;All rights reserved.</p>
        </div>
      </div>
    </div>
  )
}

export default FooterLandingPage
