import { Controller, useFormContext } from 'react-hook-form'
import { TextareaAutosize } from '@mui/material'

interface IRETextAreaProps {
  id: string
  label: string
  required: boolean
  maxlength: number
  type: string
  variant: string
  value: string
  handleChange: Function
}
export default function RETextarea({
  id,
  label,
  variant,
  required,
  maxlength,
  handleChange,
}: IRETextAreaProps) {
  const { register, control } = useFormContext()

  return (
    <>
      <div className="relisting-input">
        <Controller
          name={id}
          control={control}
          rules={{ required: required }}
          render={({ field }) => (
            <TextareaAutosize
              id={id}
              className="textarea"
              aria-label="empty textarea"
              placeholder={label}
              minRows={4}
              {...register(id, {
                onChange: (event) => handleChange(id, event),
              })}
            />
          )}
        />
      </div>
    </>
  )
}
