import { gql } from '@apollo/client'

export const LIST_FILES = gql`
  query ListFiles($listFilesId: String!) {
    listFiles(id: $listFilesId) {
      imageUrl
      Name
      Prefix
    }
  }
`

export const GET_REAL_ESTATE_FINANCE_BY_RE_ID = gql`
  query GetRealEstateFinanceByReId($input: ReIdInput!) {
    getRealEstateFinanceByReId(input: $input) {
      assetPrice
      closingCost
      expectedGrossRentPerProperty
      expectedGrossRoi
      expectedNetRentPerProperty
      expectedNetRoi
      expectedReserveFunds
      expenses
      insurance
      listingPrice
      maintenanceExpense
      netRentPerToken
      platformFees
      platformListingFees
      propertyMgtFees
      propertyTaxes
      re_id
      renovationCosts
      tokenPrice
      totalInvestments
      totalNumberOfTokens
      user_id
      utilities
      tokensRemaining
    }
  }
`

export const GET_RE_FIN_TKN_INFO = gql`
  query GetRealEstateFinanceByReId($input: ReIdInput!) {
    getRealEstateFinanceByReId(input: $input) {
      expectedNetRentPerProperty
      listingPrice
      closingCost
      totalInvestments
      expectedNetRoi
      netRentPerToken
      assetPrice
      renovationCosts
      platformFees
      re_id
      user_id
    }
  }
`

export const GET_RE_BASIC_TKN_INFO = gql`
  query GetRealEstateBasicInfoById($reId: String!) {
    getRealEstateBasicInfoById(re_id: $reId) {
      metaLink
      youtubeLink
      tokenSmartContractUrl
    }
  }
`

export const GET_RE_BASIC_TKN = gql`
  query GetRealEstateBasicInfoById($reId: String!) {
    getRealEstateBasicInfoById(re_id: $reId) {
      address {
        formatted_street_address
      }
    }
  }
`

export const GET_RE_FINANCE_TKN = gql`
  query GetRealEstateFinanceByReId($input: ReIdInput!) {
    getRealEstateFinanceByReId(input: $input) {
      totalNumberOfTokens
      tokenPrice
    }
  }
`

export const GET_REAL_ESTATE_BASIC_INFO_BY_ID = gql`
  query GetRealEstateBasicInfoById($reId: String!) {
    getRealEstateBasicInfoById(re_id: $reId) {
      address {
        city
        formatted_street_address
        state
        zip_code
        zip_plus_four_code
      }
      parcel {
        area_acres
        area_sq_ft
        building_count
        county_land_use_code
        county_land_use_description
        county_name
        depth_ft
        fips_code
        frontage_ft
        legal_description
        location_descriptions
        lot_code
        lot_number
        municipality
        section_township_range
        standardized_land_use_category
        standardized_land_use_type
        subdivision
        tax_account_number
        zoning
      }

      structure {
        air_conditioning_type
        amenities
        architecture_type
        basement_type
        baths
        beds_count
        condition
        construction_type
        effective_year_built
        exterior_wall_type
        fireplaces
        flooring_types
        foundation_type
        heating_fuel_type
        heating_type
        interior_wall_type
        other_rooms
        parking_spaces_count
        parking_type
        partial_baths_count
        plumbing_fixtures_count
        pool_type
        quality
        roof_material_type
        roof_style_type
        rooms_count
        sewer_type
        stories
        total_area_sq_ft
        units_count
        water_type
        year_built
      }
      taxes {
        amount
        exemptions
        rate_code_area
        year
      }
      additionalPerks
      propertyDescription
      metaLandRevenueStream
      metaLink
      youtubeLink
      tokenSmartContractUrl
      updated_at
      tokenId
    }
  }
`

export const GET_RE_BASIC_DETAILS = gql`
  query GetRealEstateBasicInfoById($reId: String!) {
    getRealEstateBasicInfoById(re_id: $reId) {
      parcel {
        county_land_use_description
        subdivision
      }

      structure {
        year_built
        stories
        units_count
        foundation_type
        roof_material_type
        parking_spaces_count
        heating_type
        exterior_wall_type
        air_conditioning_type
        amenities
        architecture_type
        baths
      }
    }
  }
`

export const GET_RE_BASIC_ABT = gql`
  query GetRealEstateBasicInfoById($reId: String!) {
    getRealEstateBasicInfoById(re_id: $reId) {
      additionalPerks
      propertyDescription
    }
  }
`

export const GET_CURRENT_REMAINING_TOKENS = gql`
  query GetRealEstateInfoById($reId: String!) {
    getRealEstateInfoById(reId: $reId) {
      totalNumberOfTokens
    }
  }
`

export const GET_ALL_RE_BY_ID = gql`
  query GetRealEstateInfoById($reId: String!) {
    getRealEstateInfoById(reId: $reId) {
      additionalPerks
      tokensRemaining
      totalNumberOfTokens
      companyName
      closingDate
      offeringMemorandumDate
      address {
        city
        formatted_street_address
      }
      assetPrice

      closingCost
      created_at
      estimatedRentStartDate
      expectedGrossRentPerProperty
      expectedGrossRoi
      expectedNetRentPerProperty
      expectedNetRoi
      expenses
      expectedReserveFunds
      insurance
      listingPrice
      maintenanceExpense
      metaLandRevenueStream
      metaLink
      netRentPerToken
      parcel {
        area_acres
        area_sq_ft
        building_count
        county_land_use_code
        county_land_use_description
        county_name

        legal_description
        location_descriptions
        lot_code
        lot_number
        municipality
        section_township_range
        standardized_land_use_category
        standardized_land_use_type
        subdivision
        tax_account_number
        zoning
      }
      youtubeLink
      utilities
      user_id
      updated_at
      totalNumberOfTokens
      totalInvestments
      tokensRemaining
      tokenSmartContractUrl
      tokenPrice
      tokenId
      taxes {
        amount
        exemptions
        rate_code_area
        year
      }
      platformFees
      platformListingFees
      propertyDescription
      propertyMgtFees
      propertyTaxes
      re_id
      renovationCosts
      structure {
        air_conditioning_type
        amenities
        architecture_type
        basement_type
        baths
        beds_count
        condition
        construction_type

        exterior_wall_type
        fireplaces

        foundation_type
        heating_fuel_type
        heating_type
        interior_wall_type
        other_rooms
        parking_spaces_count
        parking_type
        partial_baths_count
        plumbing_fixtures_count
        pool_type
        quality
        roof_material_type
        roof_style_type
        rooms_count
        sewer_type
        stories
        units_count
        water_type
        year_built
      }
    }
  }
`
export const GET_EXTRA_DATA = gql`
  query GetRealEstateInfoById($reId: String!) {
    getRealEstateInfoById(reId: $reId) {
      tokensRemaining
    }
  }
`

export const GET_RE_INFO_MKT = gql`
  query GetRealEstateInfoList {
    getRealEstateInfoList {
      address
      created_at
      expectedNetRoi
      isAvailable
      estimatedRentStartDate
      listingPrice
      re_id
      tokenPrice
      tokensRemaining
      totalNumberOfTokens
      updated_at
      thumbnail
      metaLink
    }
  }
`

export const GET_REAL_ESTATE_INFO_LIST = gql`
  query GetRealEstateInfoList {
    getRealEstateInfoList {
      additionalPerks
      address {
        city
        formatted_street_address
        state
        street_name
        street_number
        zip_code
        zip_plus_four_code
      }
      expectedGrossRentPerProperty
      expectedGrossRoi
      expectedNetRentPerProperty
      expectedNetRoi
      expectedReserveFunds
      expenses
      insurance
      metaLink
      listingPrice
      maintenanceExpense
      metaLandRevenueStream
      netRentPerToken
      parcel {
        apn_original
        apn_previous
        apn_unformatted
        area_acres
        area_sq_ft
        building_count
        county_land_use_code
        county_land_use_description
        county_name
        depth_ft
        fips_code
        frontage_ft
        legal_description
        location_descriptions
        lot_code
        lot_number
        municipality
        section_township_range
        standardized_land_use_category
        standardized_land_use_type
        subdivision
        tax_account_number
        zoning
      }
      youtubeLink
      utilities
      updated_at
      totalNumberOfTokens
      totalInvestments
      tokenSmartContractUrl
      tokenPrice
      tokenId
      taxes {
        amount
        exemptions
        rate_code_area
        year
      }
      created_at
      closingCost
      assetPrice
      metadata {
        publishing_date
      }
      platformFees
      platformListingFees
      propertyDescription
      propertyMgtFees
      propertyTaxes
      re_id
      renovationCosts
      structure {
        air_conditioning_type
        amenities
        architecture_type
        basement_type
        baths
        beds_count
        condition
        construction_type
        effective_year_built
        exterior_wall_type
        fireplaces
        flooring_types
        foundation_type
        heating_fuel_type
        heating_type
        interior_wall_type
        other_rooms
        parking_spaces_count
        parking_type
        partial_baths_count
        plumbing_fixtures_count
        pool_type
        quality
        roof_material_type
        roof_style_type
        rooms_count
        sewer_type
        stories
        total_area_sq_ft
        units_count
        water_type
        year_built
      }
      user_id
    }
  }
`

export const PRE_SIGNED_URL = gql`
  query PreSignedUrl($re_id: String!, $keys: [String!]!) {
    preSignedUrl(re_id: $re_id, keys: $keys) {
      key
      url
    }
  }
`

export const GET_REAL_ESTATE_INFOLIST_BY_IDS = gql`
  query GetRealEstateInfoListByIds($reIds: [String!]!) {
    getRealEstateInfoListByIds(reIds: $reIds) {
      address {
        city
        formatted_street_address
        state
        zip_code
        zip_plus_four_code
      }
      parcel {
        area_acres
        area_sq_ft
        building_count
        county_land_use_code
        county_land_use_description
        county_name
        depth_ft
        fips_code
        frontage_ft
        legal_description
        location_descriptions
        lot_code
        lot_number
        municipality
        section_township_range
        standardized_land_use_category
        standardized_land_use_type
        subdivision
        tax_account_number
        zoning
      }

      structure {
        air_conditioning_type
        amenities
        architecture_type
        basement_type
        baths
        beds_count
        condition
        construction_type
        effective_year_built
        exterior_wall_type
        fireplaces
        flooring_types
        foundation_type
        heating_fuel_type
        heating_type
        interior_wall_type
        other_rooms
        parking_spaces_count
        parking_type
        partial_baths_count
        plumbing_fixtures_count
        pool_type
        quality
        roof_material_type
        roof_style_type
        rooms_count
        sewer_type
        stories
        total_area_sq_ft
        units_count
        water_type
        year_built
      }
      taxes {
        amount
        exemptions
        rate_code_area
        year
      }
      additionalPerks
      propertyDescription
      metaLandRevenueStream
      metaLink
      youtubeLink
      tokenSmartContractUrl
      updated_at
      tokenId
      re_id
    }
  }
`

export const GET_AGREEMENT_DOC_LINK = gql`
  query Query($reId: String!) {
    getAgreementDocLink(re_id: $reId) {
      operatingLink
      ppmLink
      subscriptionLink
    }
  }
`
export const GET_RE_YOUTUBELINK = gql`
  query GetRealEstateInfoById($reId: String!) {
    getRealEstateInfoById(reId: $reId) {
      youtubeLink
    }
  }
`

export const GET_METALINK = gql`
  query GetRealEstateInfoById($reId: String!) {
    getRealEstateInfoById(reId: $reId) {
      metaLink
    }
  }
`
export const GET_RE_PRICES = gql`
  query GetRealEstateInfoById($reId: String!) {
    getRealEstateInfoById(reId: $reId) {
      totalNumberOfTokens
      tokenPrice
    }
  }
`
