// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer{margin-top:1.5em;text-align:left}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_animation_duration": "200ms",
	"selectionContainer": "onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer"
};
export default ___CSS_LOADER_EXPORT___;
