import { gql } from "@apollo/client";

export const CREATE_APPLICANT = gql`
  mutation CreateApplicant($inputs: CreateApplicantRequest!) {
    createApplicant(inputs: $inputs) {
      lastName
      firstName
      address {
        town
        postcode
        state
        line1
        line2
        country
      }
      dob
      id
    }
  }
`

export const GENERATE_SDK_TOKEN = gql`
  mutation Mutation($inputs: GenerateTokenInput!) {
    generateSdkToken(inputs: $inputs)
  }
`

export const SUBMIT_WORKFLOW_RESPONSE = gql`
  mutation SubmitWorkflowResponse($inputs: OnfidoWorkflowRunResponseInput!) {
    submitWorkflowResponse(inputs: $inputs) {
      user_id
      workflow_run_id
      applicant_id
    }
  }
`
