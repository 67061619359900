import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer'
import { opContract, ppmContract } from 'src/utils/data/contract'
import dayjs from 'dayjs'
import { BiLinkExternal } from 'react-icons/bi'
import { toFixedTrunc } from 'src/utils/relisting'
interface ISubscriptionAgreementProps {
  realEstate: string
  realEstateId: string
  user: any
  currentTokens: any
  closingDate: any
  memoDate: any
  companyName: any
}

interface IPpmAgreementProps {
  realEstate: string
  docLink?: string
}

const styles = StyleSheet.create({
  page: {
    fontSize: 12,
    marginTop: 20,
    paddingBottom: 10,
  },
  section: {
    marginLeft: 20,
    marginRight: 20,
    margin: 10,
    padding: 10,
    textAlign: 'left',
  },
})

const styleSubtitle = StyleSheet.create({
  text: { fontSize: 14, fontWeight: 'bold' },
})

const styleBold = StyleSheet.create({
  text: { fontWeight: 'bold', marginBottom: 10 },
})
const styleStandard = StyleSheet.create({
  text: { marginBottom: 10 },
})

const stylesMainPage = StyleSheet.create({
  section: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 60,
    marginTop: 80,
  },
})

const styleLink = StyleSheet.create({
  text: { color: 'blue' },
})

const stylesMainCompany = StyleSheet.create({
  section: {
    fontSize: 32,
    textAlign: 'center',
    marginBottom: 80,
  },
})
const stylesMainCompanyAlt = StyleSheet.create({
  section: {
    fontSize: 32,
    textAlign: 'center',
    marginTop: 40,
    marginBottom: 20,
  },
})

const stylesMainLLC = StyleSheet.create({
  section: {
    fontSize: 10,
    textAlign: 'center',
    marginBottom: 250,
  },
})
export const SubscriptionAgreement = ({
  realEstate,
  user,
  currentTokens,
  closingDate,
  memoDate,
  companyName,
}: ISubscriptionAgreementProps) => {
  const formatDate = (str: string) => {
    if (str) {
      let memoDateArr = str.split(' ')
      const memo = dayjs().format(`${memoDateArr[0]}`)
      return memo
    }
  }

  return (
    <Document title={`Subscription Agreement ${realEstate}`} author="3Blocks.io">
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View wrap={false}>
            <Text style={stylesMainPage.section}>SUBSCRIPTION AGREEMENT</Text>
            <Text style={stylesMainCompany.section}>{companyName}</Text>
            <Text style={stylesMainLLC.section}>
              A limited liability company formed under the laws of the State of Florida.
            </Text>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={stylesMainCompanyAlt.section}>SUBSCRIPTION AGREEMENT</Text>
          <Text style={styleBold.text}>
            The securities have not been registered under the Securities Act of 1933 or the
            securities laws of any state or other jurisdiction. There are further restrictions on
            the transferability of the securities described in this agreement.
          </Text>
          <Text style={styleBold.text}>
            The purchase of securities involves a high degree of risk and should be considered only
            by persons who can bear the risk of the loss of their entire investment.
          </Text>
          <Text style={styleStandard.text}>
            You understand that {companyName}, a limited liability company organized under the laws
            of Florida (the “Company”), is offering an aggregate of {currentTokens.tokens} of its
            limited liability interest("Units") issued in the form of non-fungible tokens
            (“Tokens”), at a current value of $ {currentTokens.finalTokenPrice} per Unit in a
            private placement. This offering is made pursuant to the Offering Memorandum, dated{' '}
            {formatDate(memoDate)} (the “Offering Documents”), all as more particularly described
            and set forth in the Offering Documents.
          </Text>
          <Text style={styleSubtitle.text}>1. Subscription</Text>
          <Text style={styleStandard.text}>
            Subject to the terms and conditions hereof and the provisions of the Offering Documents,
            you hereby irrevocably agree to purchase the Units as follows:
          </Text>

          <Text style={styleBold.text}>Units to Be Acquired {currentTokens.tokens} units</Text>
          <Text style={styleBold.text}>
            Total Purchase Price US ${' '}
            {toFixedTrunc(Number(currentTokens.tokens) * currentTokens.finalTokenPrice, 2)}
          </Text>
          <Text style={styleStandard.text}>
            You acknowledge that the Units will be subject to restrictions on transfer as set forth
            in this subscription agreement (the “Subscription Agreement”).
          </Text>
          <Text style={styleStandard.text}>
            You further acknowledge receipt of the Company Operating Agreement, available at{' '}
            <Text style={styleLink.text}>https://bit.ly/3F04ba1</Text> and by signing this
            Subscription Agreement, you agree with all of the terms of the Operating Agreement.
          </Text>
          <Text style={styleSubtitle.text}>
            2. Acceptance of Subscription and Issuance of Units.
          </Text>
          <Text style={styleStandard.text}>
            <Text style={styleBold.text}>
              It is understood and agreed that the Company has the sole right, at its complete
              discretion, to accept or reject this subscription, in whole or in part, for any reason
            </Text>{' '}
            and that this subscription is accepted by the Company only when it is signed by a duly
            authorized officer of the Company and delivered to you at the Closing referred to in
            Section 3. Subscriptions need not be accepted in the order received, and the Units may
            be allocated among subscribers at any time. Notwithstanding anything in this
            Subscription Agreement to the contrary, the Company has no obligation to issue any of
            the Units to a U.S. person or any person who is a resident of a jurisdiction in which
            the issuance of Tokens to such person would constitute a violation of the securities,
            “blue sky,” or other similar laws of such jurisdiction (collectively referred to as the
            “Local Securities Laws”).
          </Text>

          <Text style={styleSubtitle.text}>3. The Closing.</Text>
          <Text style={styleStandard.text}>
            The closing of the purchase and sale of the Tokens (the “Closing”) takes place on the
            3Blocks.io platform at the time that you sign this Agreement. You must provide the
            required information, and download and review the Offering Memorandum, Operating
            Agreement, and this Subscription Agreement prior to Closing. Upon execution of this
            Agreement and payment for the Units, the Company will review your application. Upon
            approval, the Company will issue Units and the representative Tokens. This offering
            expires on {formatDate(closingDate)}.
          </Text>
          <Text style={styleSubtitle.text}>4. Payment for Units.</Text>
          <Text style={styleStandard.text}>
            Payment for the Units must be received by the Company from you at or prior to the
            Closing. All Units must be purchased through the 3Blocks platform. Purchases can be made
            in U.S. Dollars, or certain cryptocurrencies accepted by the platform. All subscriptions
            to the Units must be paid for at the time of execution of the subscription agreement.
            Once the Company has approved your application, it will issue Tokens to your wallet
            representing the Units. As the Company’s Units are uncertificated, the Company will
            enter your ownership in book-entry form.
          </Text>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styleSubtitle.text}>5. Restrictions on the Units.</Text>
          <Text style={styleStandard.text}>
            You understand and agree that selling the Units is restricted. This offering is being
            made in accordance with Regulation S under the Securities Act. The Units have not been
            and will not be registered under the Securities Act or under any applicable state
            securities laws and may not be offered or sold except pursuant to an exemption from, or
            in a transaction not subject to, the registration requirements of the Securities Act.
            Accordingly, the Units are being offered hereby only in offers and sales that occur
            outside the United States to persons other than U.S. persons (“non-U.S. purchasers,”
            which term includes dealers or other professional fiduciaries in the United States
            acting on a discretionary basis for non-U.S. beneficial owners (other than an estate or
            trust)), in offshore transactions meeting the requirements of Rule 903 of Regulation S.
            As used herein, the terms “offshore transactions,” “United States” and “U.S. persons”
            have the respective meanings given to them in Regulation S.
          </Text>
          <Text style={styleStandard.text}>5.1 Restrictions on Transfers</Text>
          <Text style={styleStandard.text}>
            At this time, there is no market for the Unit or Tokens. 3Blocks, Inc. is in the process
            of creating a Token Trading System, which will be the sole method of transferring
            Tokens, but there is no guarantee that such a system will ever be functional. Until such
            time as a Token Trading System exists, transfers of the Tokens is prohibited. Any
            potential transfer requires the Manager’s prior written approval. The Company will not
            register any transfer in violation of Section 5, or any transfer that may violate any
            law or jeopardize the Regulation S exemption upon which this Offering relies. Other
            restrictions on transfers are outlined in the Operating Agreement, including a right of
            first refusal.
          </Text>
          <Text style={styleStandard.text}>5.2 Regulation S Representations</Text>
          <Text style={styleStandard.text}>You agree as follows:</Text>
          <Text style={styleStandard.text}>
            1. You are not a U.S. person and are acquiring the Units in an offshore transaction
            pursuant to Regulation S;
          </Text>
          <Text style={styleStandard.text}>
            2. You understand that the Units are being offered in a transaction not involving any
            public offering in the United States within the meaning of the Securities Act, that the
            Units have not been and will not be registered under the Securities Act, and that, prior
            to the expiration of the one-year “distribution compliance period” within the meaning of
            Regulation S the Units may not be offered or sold within the United States or to, or for
            the account or benefit of, U.S. persons except (i) pursuant to an exemption from
            registration under the Securities Act (if available) or (ii) pursuant to an effective
            registration statement under the Securities Act, in each case in accordance with any
            applicable state securities laws;
          </Text>
          <Text style={styleStandard.text}>
            3. You understand that, prior to the expiration of the one-year “distribution compliance
            period” within the meaning of Regulation S, hedging transactions involving the Units may
            not be conducted unless in compliance with the Securities Act;
          </Text>
          <Text style={styleStandard.text}>
            4. You acknowledge that the Company will rely upon the truth and accuracy of the
            foregoing acknowledgments, representations and agreements.
          </Text>

          <Text style={styleStandard.text}>5.3 Restrictive Legend</Text>
          <Text style={styleStandard.text}>
            If certificated shares are used by the Company, representing the Units sold pursuant to
            this Subscription Agreement will be imprinted with a legend in substantially the
            following form:
          </Text>
          <Text style={styleStandard.text}>
            “THE SECURITIES EVIDENCED BY THIS CERTIFICATE HAVE NOT BEEN REGISTERED UNDER THE
            SECURITIES ACT OF 1933, AS AMENDED (THE “SECURITIES ACT”), OR THE SECURITIES LAWS OF ANY
            STATE OR OTHER JURISDICTION. THE SECURITIES MAY NOT BE OFFERED, SOLD, PLEDGED OR
            OTHERWISE TRANSFERRED, AND HEDING TRANSACTIONS INVOLVING THE SECURITIES IS PROHIBITED
            EXCEPT (1) PURSUANT TO AN EXEMPTION FROM REGISTRATION UNDER THE SECURITIES ACT OR (2)
            PURSUANT TO AN EFFECTIVE REGISTRATION STATEMENT UNDER THE SECURITIES ACT, IN EACH CASE
            IN ACCORDANCE WITH ALL APPLICABLE STATE SECURITIES LAWS AND THE SECURITIES LAWS OF OTHER
            JURISDICTIONS, AND IN THE CASE OF A TRANSACTION EXEMPT FROM REGISTRATION, UNLESS THE
            COMPANY HAS RECEIVED AN OPINION OF COUNSEL REASONABLY SATISFACTORY TO IT THAT SUCH
            TRANSACTION DOES NOT REQUIRE REGISTRATION UNDER THE SECURITIES ACT AND SUCH OTHER
            APPLICABLE LAWS. PRIOR TO EXPIRATION OF THE 1-YEAR DISTRIBUTION COMPLIANCE PERIOD (AS
            DEFINED IN REGULATION S (&quot;REGULATION S&quot;) UNDER THE SECURITIES ACT), THIS
            SECURITY MAY NOT BE OFFERED, SOLD, PLEDGED OR OTHERWISE TRANSFERRED WITHIN THE UNITED
            STATES (AS DEFINED IN REGULATION S) OR TO, OR FOR THE ACCOUNT OR BENEFIT OF, A U.S.
            PERSON (AS DEFINED IN REGULATION S) EXCEPT (1) PURSUANT TO AN AVAILABLE EXEMPTION FROM
            REGISTRATION UNDER THE SECURITIES ACT OR (2) PURSUANT TO AN EFFECTIVE REGISTRATION
            STATEMENT UNDER THE SECURITIES ACT, AND IN EACH CASE IN ACCORDANCE WITH ANY APPLICABLE
            STATE SECURITIES LAWS. THE HOLDER OF THIS SECURITY OR TOKENS BY ITS ACCEPTANCE WILL BE
            DEEMED TO HAVE REPRESENTED AND WARRANTED THAT EITHER (1)·NO PORTION OF THE ASSETS USED
            BY SUCH HOLDER TO ACQUIRE OR HOLD THIS SECURITY OR TOKEN CONSTITUTES THE ASSETS OF AN
            EMPLOYEE BENEFIT PLAN THAT IS SUBJECT TO TITLE I OF THE U.S. EMPLOYEE RETIREMENT INCOME
            SECURITY ACT OF 1974, AS AMENDED (“ERISA”), A PLAN TO WHICH SECTION·4975 OF THE U.S.
            INTERNAL REVENUE CODE OF 1986, AS AMENDED (THE “CODE”) APPLIES (INCLUDING AN INDIVIDUAL
            RETIREMENT ACCOUNT), AN ENTITY WHOSE UNDERLYING ASSETS ARE CONSIDERED TO INCLUDE PLAN
            ASSETS OF ANY SUCH EMPLOYEE BENEFIT PLAN, OR PLAN, A GOVERNMENTAL PLAN (AS DEFINED IN
            SECTION·3(32) OF ERISA), A CHURCH PLAN (AS DEFINED IN SECTION·3(33) OF ERISA) THAT HAS
            NOT MADE AN ELECTION UNDER SECTION·410(D)·OF THE CODE, OR A NON-U.S. PLAN, OR (2)(A)·THE
            HOLDER IS, OR IS USING, THE ASSETS OF A GOVERNMENTAL PLAN, A CHURCH PLAN THAT HAS NOT
            MADE AN ELECTION UNDER SECTION·410(D)·OF THE CODE, OR A NON-U.S. PLAN AND (B)·THE
            ACQUISITION AND HOLDING OF THIS SECURITY OR TOKEN WILL NOT CONSTITUTE A VIOLATION UNDER
            ANY APPLICABLE PROVISIONS UNDER ANY FEDERAL, STATE, LOCAL, NON-U.S. OR OTHER LAWS OR
            REGULATIONS THAT REGULATE SUCH PLAN’S INVESTMENTS.
          </Text>
          <Text style={styleStandard.text}>
            The Tokens are digital instruments and, as such, will not contain legends. However, if
            you were to sell or transfer the Units or Tokens, you must present the above information
            to any potential holder, including the legends above. The new owner must, at a minimum,
            affirmatively signal their understanding of the information and provide the Company with
            certain representations on their investor status and location. The Operating Agreement
            and a copy of this Subscription Agreement must be presented at that time as well.
          </Text>
          <Text style={styleSubtitle.text}>6. Redemption Rights.</Text>
          <Text style={styleStandard.text}>
            The Company has the right to redeem the Units, in whole or in part, at any time. If
            fewer than all of the outstanding Units are to be redeemed at any time, the Company may
            choose to redeem the Units proportionally from all subscribers, or may choose the Units
            to be redeemed by lot or by any other equitable method.
          </Text>
          <Text style={styleStandard.text}>
            The redemption price for a Units is either (i)·its fair market value (if any) as
            determined in good faith by the Manager (but, in no event, less than $50.00 per Units)
            or (ii)·if no market value is determinable at such time, USD $50.00 per Token (the
            “Redemption Price”). The Redemption Price, in the sole discretion of the Company, may be
            paid in USDC, Bitcoin, or Ether. Payments will be valued in U.S. dollars.
          </Text>

          <Text style={styleSubtitle.text}>7. Representations and Warranties of the Company.</Text>

          <Text style={styleStandard.text}>
            As of the Closing, the Company represents and warrants that:
          </Text>
          <Text style={styleStandard.text}>
            A. The Company is duly formed and validly existing under the laws of Florida, with full
            power and authority to conduct its business as it is currently being conducted and to
            own its assets; and has secured any other authorizations, approvals, permits and orders
            required by law for the conduct by the Company of its business as it is currently being
            conducted.
          </Text>
          <Text style={styleStandard.text}>
            B. The Units have been duly authorized and, when issued, delivered and paid for in the
            manner set forth in this Subscription Agreement, will be validly issued, fully paid and
            nonassessable.
          </Text>
          <Text style={styleSubtitle.text}>8. Your Representations and Warranties.</Text>
          <Text style={styleStandard.text}>
            The Company is entitled to rely upon the accuracy of your representations. The Company
            may, but under no circumstances will it be obligated to, require additional evidence
            that a prospective purchaser meets the standards set forth below at any time prior to
            its acceptance of your subscription. You are not obligated to supply any information
            requested by the Company, but the Company may reject a subscription from you or any
            person who fails to supply such information.
          </Text>
          <Text style={styleStandard.text}>
            You hereby represent and warrant to and covenant to the Company that:
          </Text>

          <Text style={styleStandard.text}>A. General</Text>
          <Text style={styleStandard.text}>
            i. You have all requisite authority (and in the case of an individual, the capacity) to
            purchase the Units, enter into this Subscription Agreement, agree to the Operating
            Agreement, and to perform all the obligations required to be performed by you, and such
            purchase will not contravene any law, rule or regulation binding on you or any
            investment guideline or restriction applicable to you.
          </Text>
          <Text style={styleStandard.text}>
            ii. You are an individual and not a fund or organization.
          </Text>
          <Text style={styleStandard.text}>iii. You do not reside in the United States.</Text>
          <Text style={styleStandard.text}>
            iv. You are not in the United States at the time of purchasing the Units.
          </Text>
          <Text style={styleStandard.text}>
            v. You are not purchasing on behalf of someone that is a non-U.S. person.
          </Text>
          <Text style={styleStandard.text}>
            vi. You will comply with all applicable laws and regulations in effect in any
            jurisdiction in which you purchase or sell Units and obtain any consent, approval or
            permission required for such purchases or sales under the laws and regulations of any
            jurisdiction to which you are subject or in which you make such purchases or sales, and
            the Company shall have no responsibility therefor.
          </Text>
          <Text style={styleStandard.text}>
            vii. You are acquiring the Units for your own account and investment purposes only and
            have no present intention, agreement, or arrangement for the distribution, transfer,
            assignment, fractionalization, resale, or subdivision of the Units; you have no
            contract, undertaking, agreement, or arrangement with any person to sell, transfer or
            pledge to such person or anyone else, all or any part of the Units; and you have no
            plans or intentions to enter into any such contract, undertaking or arrangement.
          </Text>
          <Text style={styleStandard.text}>
            viii. Information that you have provided is correct and complete as of this Agreement,
            and if there should be any material change in such information prior to the acceptance
            of your investment in the Company, you must immediately provide it to the Manager.
          </Text>
          <Text style={styleStandard.text}>
            ix. You have had an opportunity to ask questions of and receive answers from the Company
            and company representatives concerning the terms and conditions of this investment, and
            all such questions have been answered to your full satisfaction.
          </Text>

          <Text style={styleStandard.text}>
            x. You understand that, unless you notify the Company in writing to the contrary at or
            before the Closing, each of your representations and warranties contained in this
            Subscription Agreement will be deemed to have been reaffirmed and confirmed as of the
            Closing, taking into account all information received by you.
          </Text>
          <Text style={styleStandard.text}>
            xi. You acknowledge that the Company has the right in its sole and absolute discretion
            to abandon this private placement at any time prior to the completion of the offering.
            This Subscription Agreement will then have no force or effect and the Company will
            return the previously paid subscription price of the Units, without interest thereon, to
            you.
          </Text>

          <Text style={styleStandard.text}>B. Information Concerning the Company.</Text>
          <Text style={styleStandard.text}>
            i. You have received a copy of the Offering Documents. You have not been furnished any
            offering literature other than the Offering Documents and have relied only on the
            information contained therein.You have read, understand, and are fully familiar with and
            agree to be bound by the terms and conditions of this Subscription Agreement, the
            Operating Agreement, and the Offering Memorandum. You are basing your decision to invest
            only on the Memorandum and related documents. You have relied on the information
            contained in said materials and have not relied upon any representations made by any
            other person.
          </Text>
          <Text style={styleStandard.text}>
            ii. You recognize that the Company has very little operating history and as such may not
            have sufficient working capital to continue the operation of its business or distribute
            any future or accumulated earnings to you.
          </Text>
          <Text style={styleStandard.text}>
            iii. You understand that no governmental authority has made any finding or determination
            relating to the fairness of an investment in the Tokens and that no governmental
            authority has recommended or endorsed or will recommend or endorse the Tokens.
          </Text>
          <Text style={styleStandard.text}>C. Understanding of the Risks of Investing.</Text>

          <Text style={styleStandard.text}>
            i. You understand that an investment in the Units involves substantial risks and you are
            fully cognizant of, and understand, all of the risk factors relating to a purchase of
            the Units, including, without limitation, those risks set forth below in the section
            entitled “Risk Factors” of the Memorandum, and that your investment in the Units may be
            lost due to the enumerated risks and other risks not described in the Memorandum.
          </Text>
          <Text style={styleStandard.text}>
            ii.You expressly represent that: (a) your financial condition is such that you have no
            need for liquidity with respect to the Units to satisfy any existing or contemplated
            undertaking or indebtedness; (b) you are able to bear the economic risk of this
            investment in the Company for an indefinite period of time, including the risk of losing
            all of your investment, and the loss of your entire investment would not materially
            adversely affect your standard of living; (c) you have either secured independent tax
            advice with respect to your investment, upon which you are solely relying, or you are
            sufficiently familiar with the income taxation of Corporate transactions that you have
            deemed such independent advice unnecessary; (d) you are not seeking a current cash
            return with respect to your investment and have no need for a current return; and (e)
            after reasonable inquiry, considering your investment objectives, financial situation
            and needs, you believe that an investment in the Company is a suitable investment for
            you.
          </Text>
          <Text style={styleStandard.text}>D. Understanding of the Restrictions on the Units</Text>
          <Text style={styleStandard.text}>
            i. You recognize that there will be no market for the Units and that you cannot expect
            to be able readily to liquidate this investment. You also understand that as a result of
            the illiquid nature of the Units you must bear the economic risk of the investment for
            an indefinite period of time.
          </Text>
          <Text style={styleStandard.text}>
            ii. You understand that the Units are being offered pursuant to an exemption from the
            Securities Act under Regulation S, and you cannot resell the Units without permission
            from the Company. If you were to violate these restrictions, it could undermine the
            exemption, requiring the company to register the securities, and could also lead to a
            right of rescission from other subscribers. Such actions would cause the Company to face
            severe financial demands which could adversely affect the Company as a whole, and thus,
            the value of the Units.
          </Text>
          <Text style={styleStandard.text}>E. Representations Concerning OFAC Regulations.</Text>
          <Text style={styleStandard.text}>
            i. You represent that the amounts invested by you in this Offering were not and are not
            directly or indirectly derived from any activities that contravene U.S. Federal, state
            or international laws and regulations, including anti-money laundering laws and
            regulations. Federal regulations and Executive Orders administered by the OFAC prohibit,
            among other things, the engagement in transactions with, and the provision of services
            to, certain foreign countries, territories, entities and individuals. The lists of the
            OFAC-prohibited countries, territories, individuals and entities can be found on the
            OFAC website at http://www.treas.gov/ofac. In addition, the programs administered by the
            OFAC (the “OFAC Programs”) prohibit dealing with individuals or entities in certain
            countries, regardless of whether such individuals or entities appear on any OFAC list.
          </Text>
          <Text style={styleStandard.text}>
            ii. You are not acting as agent or nominee in connection with this investment is a
            country, territory, entity or individual named on an OFAC list, or a person or entity
            prohibited under the OFAC Programs. Please be advised that the Company may not accept
            any subscription amounts from a prospective purchaser if such purchasers cannot make the
            representation set forth in the preceding sentence. You agree to promptly notify the
            Company should you become aware of any change in the information set forth in any of
            these representations. You are advised that, by law, the Company may be obligated to
            “freeze the account” of any purchaser, either by prohibiting additional subscriptions
            from it, declining any redemption requests or segregating the assets in the account in
            compliance with governmental regulations, and that the Company may also be required to
            report such action and to disclose such purchaser’s identity to the OFAC;
          </Text>
          <Text style={styleStandard.text}>
            iii. You are not a senior foreign political figure, or an immediate family member, or
            close associate of a senior foreign political figure, as such terms are defined in the
            law; and if you are affiliated with a non-U.S. banking institution (a “Foreign Bank”),
            or if you receive deposits from, make payments on behalf of, or handle other financial
            transactions related to a Foreign Bank, you represent and warrant to the Company that:
            (1) the Foreign Bank has a fixed address, and not solely an electronic address, in a
            country in which the Foreign Bank is authorized to conduct banking activities; (2) the
            Foreign Bank maintains operating records related to its banking activities; (3) the
            Foreign Bank is subject to inspection by the banking authority that licensed the Foreign
            Bank to conduct its banking activities; and (4) the Foreign Bank does not provide
            banking services to any other Foreign Bank that does not have a physical presence in any
            country and that is not a regulated affiliate.
          </Text>
          <Text style={styleSubtitle.text}>
            9. Conditions to Your Obligations of You and the Company.
          </Text>
          <Text style={styleStandard.text}>
            Your obligations to purchase and pay for the Units and of the Company to sell the Units
            are subject to the satisfaction at or prior to the Closing of the following conditions
            precedent: the representations and warranties contained in Sections 5.2 and 8 are true
            and correct as of the Closing in all respects with the same effect as though such
            representations and warranties had been made as of the Closing.
          </Text>
          <Text style={styleSubtitle.text}>10. Obligations Irrevocable.</Text>
          <Text style={styleStandard.text}>Your obligations are irrevocable.</Text>
          <Text style={styleSubtitle.text}>11. Confidentiality.</Text>
          <Text style={styleStandard.text}>
            You acknowledge that the existence and the terms of this Agreement, the Offering
            Memorandum, the Operating Agreement, and any oral or written information provided by the
            Company in relation to this Agreement is confidential information. You must maintain
            confidentiality of all confidential information, and must not disclose any confidential
            information to any third parties, except for the information that: (a) is or will be in
            the public domain (other than through your unauthorized disclosure); (b) is under the
            obligation to be disclosed pursuant to the applicable laws or regulations, or orders of
            the court or other government authorities; or (c) is required to be disclosed to your
            legal counsels or financial advisors regarding the transaction contemplated, provided
            that such legal counsels or financial advisors are bound by the confidentiality
            obligations similar to those set forth in this Section. Disclosure of any confidential
            information This Section survives the termination of this Agreement for any reason.
          </Text>

          <Text style={styleSubtitle.text}>12. Indemnification.</Text>
          <Text style={styleStandard.text}>
            PLEASE READ THIS INDEMNIFICATION PROVISION CAREFULLY BECAUSE IT LIMITS YOUR ABILITY TO
            SEEK RELIEF FROM AN INDEMNIFIED PARTY.
          </Text>
          <Text style={styleStandard.text}>
            You acknowledge that you understand the meaning and legal consequences of the
            representations and warranties contained in this Subscription Agreement, and except as
            otherwise agreed to in writing with the Company, you agree to indemnify and hold
            harmless the Company and Manager, and each other person, if any, who controls, is
            controlled by, or is under common control with any of the foregoing (each, an
            “Indemnified Party”) from and against any and all loss, claim, damage, liability or
            expense whatsoever (including reasonable attorneys’ fees and disbursements) due to or
            arising out of or based upon (i) any inaccurate representation or warranty made by you,
            or breach or failure by you to comply with any covenant or agreement made by you in this
            Subscription Agreement including the answers in your profile, or in any other document
            furnished by you to any of the foregoing in connection with this transaction, (ii) any
            action for securities, commodities, or money transmission law violations instituted by
            you that is finally resolved by judgment against you, or (iii) any action instituted by
            or on behalf of you against an Indemnified Party that is finally resolved by judgment
            against you or in favor of an Indemnified Party.
          </Text>
          <Text style={styleSubtitle.text}>13. Waiver, Amendment.</Text>
          <Text style={styleStandard.text}>
            Neither this Subscription Agreement nor any provisions hereof shall be modified,
            changed, discharged or terminated except by an instrument in writing, signed by the
            party against whom any waiver, change, discharge or termination is sought.
          </Text>
          <Text style={styleSubtitle.text}>14. Assignability.</Text>
          <Text style={styleStandard.text}>
            Neither this Subscription Agreement nor any right, remedy, obligation or liability
            arising hereunder or by reason hereof is assignable by either the Company or you without
            the prior written consent of the other party.
          </Text>
          <Text style={styleSubtitle.text}>15. Waiver of Jury Trial</Text>
          <Text style={styleStandard.text}>
            YOU IRREVOCABLY WAIVE ANY AND ALL RIGHT TO TRIAL BY JURY WITH RESPECT TO ANY LEGAL
            PROCEEDING ARISING OUT OF THE TRANSACTIONS CONTEMPLATED BY THIS SUBSCRIPTION AGREEMENT.
          </Text>
          <Text style={styleSubtitle.text}>16. Miscellaneous</Text>
          <Text style={styleStandard.text}>
            A. <Text style={styleBold.text}>Governing Law. </Text> This Agreement is governed and
            interpreted by Florida law. Any lawsuit arising directly or indirectly out of this
            Agreement will be litigated in the courts of Florida, or, if original jurisdiction can
            be established, in a United States District Court in Florida.
          </Text>
          <Text style={styleStandard.text}>
            B. <Text style={styleBold.text}>Section and Other Headings.</Text>
            The section and other headings contained in this Subscription Agreement are for
            reference purposes only and do not affect the meaning or interpretation of this
            Subscription Agreement.
          </Text>
          <Text style={styleStandard.text}>
            C. <Text style={styleBold.text}>Counterparts.</Text>
            This Agreement may be signed in one or more counterparts, and each counterpart will be
            considered an original Agreement. All of the counterparts will be considered one
            document and become a binding agreement when one or more counterparts have been signed
            by each of the parties and delivered to the other. The parties agree that electronic
            signatures may be used for this Agreement, and for any documents related to this
            Agreement that require signatures.
          </Text>
          <Text style={styleStandard.text}>
            D. <Text style={styleBold.text}>Binding Effect. </Text>The provisions of this
            Subscription Agreement are binding upon and accrue to the benefit of the parties and
            their respective heirs, legal representatives, successors, and assigns.
          </Text>
          <Text style={styleStandard.text}>
            E. <Text style={styleBold.text}>Survival. </Text>All representations, warranties and
            covenants contained in this Subscription Agreement shall survive (i) the acceptance of
            the subscription by the Company and the Closing, (ii) changes in the transactions,
            documents and instruments described in the Offering Documents which are not material or
            which are to your benefit and (iii) your death or disability.
          </Text>
          <Text style={styleStandard.text}>
            F. <Text style={styleBold.text}>Notification of Changes. </Text> You agree to notify the
            Company upon the occurrence of any event prior to the closing of the purchase of the
            Tokens pursuant to this Subscription Agreement which would cause any of your
            representations, warranties, or covenants contained in this Subscription Agreement to be
            false or incorrect.
          </Text>
          <Text style={styleStandard.text}>
            G. <Text style={styleBold.text}>Severability.</Text> Each provision of this Agreement
            must be interpreted in a way that is valid under applicable law. If any provision is
            held invalid, the rest of the Agreement remains in full effect.
          </Text>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.section} wrap={false}>
          <Text style={styleStandard.text}>
            I agree with the above terms and have read the Offering Memorandum. I have read and
            agree to be bound by the provisions of the Operating Agreement, available at
            https://bit.ly/3F04ba1. I hereby certify that all of the representations in Sections 5.2
            and 8 are correct and that I am not a U.S. person as defined in the Regulation S of the
            Securities Act.
          </Text>
          <Text style={styleStandard.text}>
            This Agreement is effective as of {formatDate(memoDate)}
          </Text>
          {/* line for signature */}
          <Text>
            Name: {user.name} {user.family_name}{' '}
          </Text>
          <Text>
            Total Purchase Price: US $
            {toFixedTrunc(Number(currentTokens.tokens) * currentTokens.finalTokenPrice, 2)}
          </Text>
          <Text> Number of Units: {currentTokens.tokens} units.</Text>
          <Text style={styleStandard.text}>
            The offer to purchase Securities as set forth above is confirmed and accepted by the
            Company as to {currentTokens.tokens} Units.
          </Text>
          <Text style={styleStandard.text}>Company: </Text>

          <Text>Siddalingesh Kadi </Text>
          <Text>3Blocks, Manager</Text>
          <Text>By Siddalingesh Kadi, Authorized Member</Text>
        </View>
      </Page>
    </Document>
  )
}

export const PpmAgreement = ({ realEstate, docLink }: IPpmAgreementProps) => {
  return (
    <div>
      <h5
        style={{
          color: 'var(--gray-90)',
          textAlign: 'center',
          fontWeight: 'bold',
        }}
        className="title"
      >
        {realEstate}
      </h5>

      <div className="contract">{ppmContract}</div>
      <div
        style={{
          color: 'var(--main)',
          margin: 20,
          textAlign: 'center',
          padding: 4,
          fontWeight: 'bold',
        }}
      >
        Private Placement Memorandum Agreement
        <span>
          <a href={docLink} rel="noopener noreferrer" target={'_blank'}>
            <BiLinkExternal />
          </a>
        </span>
      </div>
    </div>
  )
}

export const OperatingAgreement = ({ realEstate, docLink }: IPpmAgreementProps) => {
  return (
    <div>
      <h5
        className="title"
        style={{
          color: 'var(--gray-90)',
          textAlign: 'center',
          fontWeight: 'bold',
        }}
      >
        {realEstate}
      </h5>
      <div className="contract">{opContract}</div>
      <div
        style={{
          color: 'var(--main)',
          fontWeight: 'bold',
          margin: 20,
          textAlign: 'center',
          padding: 4,
        }}
      >
        Operating Agreement
        <span>
          <a href={docLink} rel="noopener noreferrer" target={'_blank'}>
            <BiLinkExternal />
          </a>
        </span>
      </div>
    </div>
  )
}
