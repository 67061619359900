import { Link } from 'react-router-dom'
import Button from 'src/components/ui/button/Button'
import { useAuth } from 'src/context/useAuth'

type Props = {}
const NotFound = (props: Props) => {
  //@ts-ignore
  const { user } = useAuth()
  return (
    <>
      {user ? (
        <Link to="/">
          <div className="notfoundpage">
            <div className="access">
              <h2>Can't Access Page</h2>
              <Button label="Go to Marketplace" />
            </div>
          </div>
        </Link>
      ) : (
        <Link to="/auth">
          <div className="notfoundpage">
            <div className="access">
              <h2>Can't Access Page</h2>
              <Button label="Go to Sign in Page" />
            </div>
          </div>
        </Link>
      )}
    </>
  )
}

export default NotFound
