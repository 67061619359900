import { useLocation } from 'react-router-dom'

export default function Stripe() {
  const { pathname } = useLocation()
  if (`${pathname}?success`) {
    return <div>payment successful</div>
  }

  if (`${pathname}?failure`) {
    return <div>payment unsuccessful</div>
  }

  return(
    <div>stripe</div>
  )
}
