import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from 'src/components/sections/Header'
import { useAuth } from 'src/context/useAuth'
import { noticeVerify } from 'src/utils/constants'

function VerifyUserPage() {
  //@ts-ignore
  const { user } = useAuth()
  return (
    <>
      <Header user={user} />
      <div className="verifypage">
        <h5 className="notice">{noticeVerify}</h5>
        <Outlet />
      </div>
    </>
  )
}

export default VerifyUserPage
