import { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import main_logo from '../../../assets/main_logo.png'
import { FaGoogle } from 'react-icons/fa'
import { BiMenuAltRight } from 'react-icons/bi'
import { MdClose } from 'react-icons/md'
import Avatar from '@mui/material/Avatar'
import Button from '../button/Button'
import { googleSignIn } from 'src/utils/auth'
import { useAuth } from 'src/context/useAuth'
import { Auth } from 'aws-amplify'
import Skeleton from 'react-loading-skeleton'
// import { perm } from 'src/utils/profile'
import { AccessControl } from 'src/utils/config/routes'
import { HeaderSkeleton } from '../loader/Skeleton'
import Popover from '@mui/material/Popover'
import MuiButton from '@mui/material/Button'
import { useScrollPosition } from 'src/context/usePosition'
import EmailForm from '../form/EmailForm'
import { FaLinkedinIn, FaTelegramPlane } from 'react-icons/fa'
import { AiOutlineTwitter } from 'react-icons/ai'

const SocialIcons = () => {
  return (
    <div className="icons_div">
      <ul>
        <li>
          <a href="https://twitter.com/3blocks_io" target="_blank" rel="noreferrer">
            <div className="icon">
              <AiOutlineTwitter />
            </div>
          </a>
        </li>
        <li>
          <a href="https://t.me/Three_Blocks_General_Discussions" target="_blank" rel="noreferrer">
            <div className="icon">
              <FaTelegramPlane />
            </div>
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/company/3blocks-io/" target="_blank" rel="noreferrer">
            <div className="icon">
              <FaLinkedinIn />
            </div>
          </a>
        </li>
      </ul>
    </div>
  )
}

export default function Navbar({ toggleSidebar, variant, showSidebar }: any): JSX.Element {
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const scrollPosition = useScrollPosition()
  //@ts-ignore
  const { user, rbac, loader } = useAuth()

  async function signOut() {
    setTimeout(async () => {
      try {
        setLoading(true)
        await Auth.signOut({
          global: true,
        })
        // window.location.reload()
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
      localStorage.clear()
    })
  }

  //for popover functionality on avatar click
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  if (loader) {
    return (
      <nav className={`navbar -${variant}`}>
        <ul className="navbar__list">
          <Link className="navbar__logo" to="/">
            <img src={main_logo} alt="three blocks logo" />
          </Link>
        </ul>
        <ul className="navbar__list">
          <>
            <li className="onboard navbar__list__item">
              <NavLink to="/auth" className="navbar__link">
                <HeaderSkeleton />
              </NavLink>
            </li>
            <li className="onboard navbar__list__item">
              <HeaderSkeleton />
            </li>
          </>
        </ul>
      </nav>
    )
  }

  return (
    <>
      <nav className={scrollPosition > 100 ? `navbar -${variant}--pos` : `navbar -${variant}`}>
        <ul className="navbar__list">
          <Link className="navbar__logo" to="/">
            <img src={main_logo} alt="3Blocks logo" />
          </Link>
        </ul>
        {user.userId && user.userId.length > 0 && rbac.length > 0 ? (
          <ul className="navbar__list -main">
            <li className="navbar__list__item">
              <Link className="navbar__link" to={`/market/`}>
                Marketplace
              </Link>
            </li>
            <li className="navbar__list__item">
              <Link className="navbar__link" to={`/affiliate/`}>
                Affiliate Program
              </Link>
            </li>
            <AccessControl
              filteredUserPermissions={rbac}
              allowedPermissions={['re_listing']}
              renderNoAccess={() => null}
            >
              <li className="navbar__list__item">
                <Link className="navbar__link" to={`/relisting/`}>
                  RE Listing{' '}
                </Link>
              </li>
            </AccessControl>
          </ul>
        ) : (
          <>
            <ul className="navbar__list -main">
              <li className="navbar__list__item">
                <Link className="navbar__link" to={`/market/`}>
                  Marketplace
                </Link>
              </li>
              <li className="navbar__list__item">
                <Link className="navbar__link" to={`/auth`}>
                  Affiliate Program
                </Link>
              </li>
            </ul>
          </>
        )}

        {user.userId && user.userId.length > 0 ? (
          <>
            <ul className="navbar__list">
              {loader ? (
                <Skeleton height={20} />
              ) : (
                <>
                  <li className="onboard navbar__list__item">{SocialIcons()}</li>
                </>
              )}
            </ul>
            <div className="navbar__list -avatar">
              <MuiButton aria-describedby={id} onClick={handleClick}>
                <Avatar sx={{ width: 28, height: 28, bgcolor: 'var(--main)' }}></Avatar>
              </MuiButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                disablePortal={true}
                sx={{ zIndex: 3000 }}
              >
                <ul style={{ width: '250px', margin: '0' }}>
                  <li
                    className="dropdown__list__item"
                    style={{
                      padding: '10px 0',
                      borderBottom: '1px solid var(--gray-20)',
                      fontSize: '1.1rem',
                    }}
                  >
                    {' '}
                    <Link className="navbar__link" to={`/me/details/`}>
                      Profile
                    </Link>
                  </li>
                  <li
                    className="dropdown__list__item"
                    style={{
                      padding: '10px 0',
                      borderBottom: '1px solid var(--gray-20)',
                      fontSize: '1.1rem',
                    }}
                  >
                    {' '}
                    <Link className="navbar__link" to={`/me/portfolio/`}>
                      Portfolio
                    </Link>
                  </li>
                  <li
                    className="dropdown__list__item"
                    style={{ padding: '10px 0', fontSize: '1.1rem', fontWeight: 'bold' }}
                    onClick={signOut}
                  >
                    {!loading ? (
                      <Link className="navbar__link" to={'/'}>
                        Signout
                      </Link>
                    ) : (
                      <Link style={{ color: 'gray' }} className="navbar__link" to={'#'}>
                        Signing Out
                      </Link>
                    )}
                  </li>
                </ul>
              </Popover>
            </div>
          </>
        ) : (
          <ul className="navbar__list">
            {loader ? (
              <Skeleton height={20} />
            ) : (
              <>
                <li className="onboard navbar__list__item">
                  <EmailForm />
                </li>
                <li className="onboard navbar__list__item">{SocialIcons()}</li>
                <li className="onboard navbar__list__item">
                  <NavLink to="/auth" className="navbar__link">
                    Log In
                  </NavLink>
                </li>
                <li className="onboard navbar__list__item">
                  <NavLink to="/auth" className="navbar__link">
                    <Button variant="signup" label="Sign Up" size="sm" />
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        )}
        <div className="isMobileIcons"> {SocialIcons()}</div>

        <div className="navbar__button" onClick={toggleSidebar}>
          {!showSidebar ? <BiMenuAltRight size={40} /> : <MdClose size={40} />}
        </div>
      </nav>
    </>
  )
}
