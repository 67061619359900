export const countryList = [
  { name: 'Afghanistan', code: 'AFG', 'country-code': '004' },
  { name: 'Åland Islands', code: 'ALA', 'country-code': '248' },
  { name: 'Albania', code: 'ALB', 'country-code': '008' },
  { name: 'Algeria', code: 'DZA', 'country-code': '012' },
  { name: 'American Samoa', code: 'ASM', 'country-code': '016' },
  { name: 'Andorra', code: 'AND', 'country-code': '020' },
  { name: 'Angola', code: 'AGO', 'country-code': '024' },
  { name: 'Anguilla', code: 'AIA', 'country-code': '660' },
  { name: 'Antarctica', code: 'ATA', 'country-code': '010' },
  { name: 'Antigua and Barbuda', code: 'ATG', 'country-code': '028' },
  { name: 'Argentina', code: 'ARG', 'country-code': '032' },
  { name: 'Armenia', code: 'ARM', 'country-code': '051' },
  { name: 'Aruba', code: 'ABW', 'country-code': '533' },
  { name: 'Australia', code: 'AUS', 'country-code': '036' },
  { name: 'Austria', code: 'AUT', 'country-code': '040' },
  { name: 'Azerbaijan', code: 'AZE', 'country-code': '031' },
  { name: 'Bahamas', code: 'BHS', 'country-code': '044' },
  { name: 'Bahrain', code: 'BHR', 'country-code': '048' },
  { name: 'Bangladesh', code: 'BGD', 'country-code': '050' },
  { name: 'Barbados', code: 'BRB', 'country-code': '052' },
  { name: 'Belarus', code: 'BLR', 'country-code': '112' },
  { name: 'Belgium', code: 'BEL', 'country-code': '056' },
  { name: 'Belize', code: 'BLZ', 'country-code': '084' },
  { name: 'Benin', code: 'BEN', 'country-code': '204' },
  { name: 'Bermuda', code: 'BMU', 'country-code': '060' },
  { name: 'Bhutan', code: 'BTN', 'country-code': '064' },
  { name: 'Bolivia (Plurinational State of)', code: 'BOL', 'country-code': '068' },
  { name: 'Bonaire, Sint Eustatius and Saba', code: 'BES', 'country-code': '535' },
  { name: 'Bosnia and Herzegovina', code: 'BIH', 'country-code': '070' },
  { name: 'Botswana', code: 'BWA', 'country-code': '072' },
  { name: 'Bouvet Island', code: 'BVT', 'country-code': '074' },
  { name: 'Brazil', code: 'BRA', 'country-code': '076' },
  { name: 'British Indian Ocean Territory', code: 'IOT', 'country-code': '086' },
  { name: 'Brunei Darussalam', code: 'BRN', 'country-code': '096' },
  { name: 'Bulgaria', code: 'BGR', 'country-code': '100' },
  { name: 'Burkina Faso', code: 'BFA', 'country-code': '854' },
  { name: 'Burundi', code: 'BDI', 'country-code': '108' },
  { name: 'Cabo Verde', code: 'CPV', 'country-code': '132' },
  { name: 'Cambodia', code: 'KHM', 'country-code': '116' },
  { name: 'Cameroon', code: 'CMR', 'country-code': '120' },
  { name: 'Canada', code: 'CAN', 'country-code': '124' },
  { name: 'Cayman Islands', code: 'CYM', 'country-code': '136' },
  { name: 'Central African Republic', code: 'CAF', 'country-code': '140' },
  { name: 'Chad', code: 'TCD', 'country-code': '148' },
  { name: 'Chile', code: 'CHL', 'country-code': '152' },
  { name: 'China', code: 'CHN', 'country-code': '156' },
  { name: 'Christmas Island', code: 'CXR', 'country-code': '162' },
  { name: 'Cocos (Keeling) Islands', code: 'CCK', 'country-code': '166' },
  { name: 'Colombia', code: 'COL', 'country-code': '170' },
  { name: 'Comoros', code: 'COM', 'country-code': '174' },
  { name: 'Congo', code: 'COG', 'country-code': '178' },
  { name: 'Congo, Democratic Republic of the', code: 'COD', 'country-code': '180' },
  { name: 'Cook Islands', code: 'COK', 'country-code': '184' },
  { name: 'Costa Rica', code: 'CRI', 'country-code': '188' },
  { name: "Côte d'Ivoire", code: 'CIV', 'country-code': '384' },
  { name: 'Croatia', code: 'HRV', 'country-code': '191' },
  { name: 'Cuba', code: 'CUB', 'country-code': '192' },
  { name: 'Curaçao', code: 'CUW', 'country-code': '531' },
  { name: 'Cyprus', code: 'CYP', 'country-code': '196' },
  { name: 'Czechia', code: 'CZE', 'country-code': '203' },
  { name: 'Denmark', code: 'DNK', 'country-code': '208' },
  { name: 'Djibouti', code: 'DJI', 'country-code': '262' },
  { name: 'Dominica', code: 'DMA', 'country-code': '212' },
  { name: 'Dominican Republic', code: 'DOM', 'country-code': '214' },
  { name: 'Ecuador', code: 'ECU', 'country-code': '218' },
  { name: 'Egypt', code: 'EGY', 'country-code': '818' },
  { name: 'El Salvador', code: 'SLV', 'country-code': '222' },
  { name: 'Equatorial Guinea', code: 'GNQ', 'country-code': '226' },
  { name: 'Eritrea', code: 'ERI', 'country-code': '232' },
  { name: 'Estonia', code: 'EST', 'country-code': '233' },
  { name: 'Eswatini', code: 'SWZ', 'country-code': '748' },
  { name: 'Ethiopia', code: 'ETH', 'country-code': '231' },
  { name: 'Falkland Islands (Malvinas)', code: 'FLK', 'country-code': '238' },
  { name: 'Faroe Islands', code: 'FRO', 'country-code': '234' },
  { name: 'Fiji', code: 'FJI', 'country-code': '242' },
  { name: 'Finland', code: 'FIN', 'country-code': '246' },
  { name: 'France', code: 'FRA', 'country-code': '250' },
  { name: 'French Guiana', code: 'GUF', 'country-code': '254' },
  { name: 'French Polynesia', code: 'PYF', 'country-code': '258' },
  { name: 'French Southern Territories', code: 'ATF', 'country-code': '260' },
  { name: 'Gabon', code: 'GAB', 'country-code': '266' },
  { name: 'Gambia', code: 'GMB', 'country-code': '270' },
  { name: 'Georgia', code: 'GEO', 'country-code': '268' },
  { name: 'Germany', code: 'DEU', 'country-code': '276' },
  { name: 'Ghana', code: 'GHA', 'country-code': '288' },
  { name: 'Gibraltar', code: 'GIB', 'country-code': '292' },
  { name: 'Greece', code: 'GRC', 'country-code': '300' },
  { name: 'Greenland', code: 'GRL', 'country-code': '304' },
  { name: 'Grenada', code: 'GRD', 'country-code': '308' },
  { name: 'Guadeloupe', code: 'GLP', 'country-code': '312' },
  { name: 'Guam', code: 'GUM', 'country-code': '316' },
  { name: 'Guatemala', code: 'GTM', 'country-code': '320' },
  { name: 'Guernsey', code: 'GGY', 'country-code': '831' },
  { name: 'Guinea', code: 'GIN', 'country-code': '324' },
  { name: 'Guinea-Bissau', code: 'GNB', 'country-code': '624' },
  { name: 'Guyana', code: 'GUY', 'country-code': '328' },
  { name: 'Haiti', code: 'HTI', 'country-code': '332' },
  { name: 'Heard Island and McDonald Islands', code: 'HMD', 'country-code': '334' },
  { name: 'Holy See', code: 'VAT', 'country-code': '336' },
  { name: 'Honduras', code: 'HND', 'country-code': '340' },
  { name: 'Hong Kong', code: 'HKG', 'country-code': '344' },
  { name: 'Hungary', code: 'HUN', 'country-code': '348' },
  { name: 'Iceland', code: 'ISL', 'country-code': '352' },
  { name: 'India', code: 'IND', 'country-code': '356' },
  { name: 'Indonesia', code: 'IDN', 'country-code': '360' },
  { name: 'Iran (Islamic Republic of)', code: 'IRN', 'country-code': '364' },
  { name: 'Iraq', code: 'IRQ', 'country-code': '368' },
  { name: 'Ireland', code: 'IRL', 'country-code': '372' },
  { name: 'Isle of Man', code: 'IMN', 'country-code': '833' },
  { name: 'Israel', code: 'ISR', 'country-code': '376' },
  { name: 'Italy', code: 'ITA', 'country-code': '380' },
  { name: 'Jamaica', code: 'JAM', 'country-code': '388' },
  { name: 'Japan', code: 'JPN', 'country-code': '392' },
  { name: 'Jersey', code: 'JEY', 'country-code': '832' },
  { name: 'Jordan', code: 'JOR', 'country-code': '400' },
  { name: 'Kazakhstan', code: 'KAZ', 'country-code': '398' },
  { name: 'Kenya', code: 'KEN', 'country-code': '404' },
  { name: 'Kiribati', code: 'KIR', 'country-code': '296' },
  { name: "Korea (Democratic People's Republic of)", code: 'PRK', 'country-code': '408' },
  { name: 'Korea, Republic of', code: 'KOR', 'country-code': '410' },
  { name: 'Kuwait', code: 'KWT', 'country-code': '414' },
  { name: 'Kyrgyzstan', code: 'KGZ', 'country-code': '417' },
  { name: "Lao People's Democratic Republic", code: 'LAO', 'country-code': '418' },
  { name: 'Latvia', code: 'LVA', 'country-code': '428' },
  { name: 'Lebanon', code: 'LBN', 'country-code': '422' },
  { name: 'Lesotho', code: 'LSO', 'country-code': '426' },
  { name: 'Liberia', code: 'LBR', 'country-code': '430' },
  { name: 'Libya', code: 'LBY', 'country-code': '434' },
  { name: 'Liechtenstein', code: 'LIE', 'country-code': '438' },
  { name: 'Lithuania', code: 'LTU', 'country-code': '440' },
  { name: 'Luxembourg', code: 'LUX', 'country-code': '442' },
  { name: 'Macao', code: 'MAC', 'country-code': '446' },
  { name: 'Madagascar', code: 'MDG', 'country-code': '450' },
  { name: 'Malawi', code: 'MWI', 'country-code': '454' },
  { name: 'Malaysia', code: 'MYS', 'country-code': '458' },
  { name: 'Maldives', code: 'MDV', 'country-code': '462' },
  { name: 'Mali', code: 'MLI', 'country-code': '466' },
  { name: 'Malta', code: 'MLT', 'country-code': '470' },
  { name: 'Marshall Islands', code: 'MHL', 'country-code': '584' },
  { name: 'Martinique', code: 'MTQ', 'country-code': '474' },
  { name: 'Mauritania', code: 'MRT', 'country-code': '478' },
  { name: 'Mauritius', code: 'MUS', 'country-code': '480' },
  { name: 'Mayotte', code: 'MYT', 'country-code': '175' },
  { name: 'Mexico', code: 'MEX', 'country-code': '484' },
  { name: 'Micronesia (Federated States of)', code: 'FSM', 'country-code': '583' },
  { name: 'Moldova, Republic of', code: 'MDA', 'country-code': '498' },
  { name: 'Monaco', code: 'MCO', 'country-code': '492' },
  { name: 'Mongolia', code: 'MNG', 'country-code': '496' },
  { name: 'Montenegro', code: 'MNE', 'country-code': '499' },
  { name: 'Montserrat', code: 'MSR', 'country-code': '500' },
  { name: 'Morocco', code: 'MAR', 'country-code': '504' },
  { name: 'Mozambique', code: 'MOZ', 'country-code': '508' },
  { name: 'Myanmar', code: 'MMR', 'country-code': '104' },
  { name: 'Namibia', code: 'NAM', 'country-code': '516' },
  { name: 'Nauru', code: 'NRU', 'country-code': '520' },
  { name: 'Nepal', code: 'NPL', 'country-code': '524' },
  { name: 'Netherlands', code: 'NLD', 'country-code': '528' },
  { name: 'New Caledonia', code: 'NCL', 'country-code': '540' },
  { name: 'New Zealand', code: 'NZL', 'country-code': '554' },
  { name: 'Nicaragua', code: 'NIC', 'country-code': '558' },
  { name: 'Niger', code: 'NER', 'country-code': '562' },
  { name: 'Nigeria', code: 'NGA', 'country-code': '566' },
  { name: 'Niue', code: 'NIU', 'country-code': '570' },
  { name: 'Norfolk Island', code: 'NFK', 'country-code': '574' },
  { name: 'North Macedonia', code: 'MKD', 'country-code': '807' },
  { name: 'Northern Mariana Islands', code: 'MNP', 'country-code': '580' },
  { name: 'Norway', code: 'NOR', 'country-code': '578' },
  { name: 'Oman', code: 'OMN', 'country-code': '512' },
  { name: 'Pakistan', code: 'PAK', 'country-code': '586' },
  { name: 'Palau', code: 'PLW', 'country-code': '585' },
  { name: 'Palestine, State of', code: 'PSE', 'country-code': '275' },
  { name: 'Panama', code: 'PAN', 'country-code': '591' },
  { name: 'Papua New Guinea', code: 'PNG', 'country-code': '598' },
  { name: 'Paraguay', code: 'PRY', 'country-code': '600' },
  { name: 'Peru', code: 'PER', 'country-code': '604' },
  { name: 'Philippines', code: 'PHL', 'country-code': '608' },
  { name: 'Pitcairn', code: 'PCN', 'country-code': '612' },
  { name: 'Poland', code: 'POL', 'country-code': '616' },
  { name: 'Portugal', code: 'PRT', 'country-code': '620' },
  { name: 'Puerto Rico', code: 'PRI', 'country-code': '630' },
  { name: 'Qatar', code: 'QAT', 'country-code': '634' },
  { name: 'Réunion', code: 'REU', 'country-code': '638' },
  { name: 'Romania', code: 'ROU', 'country-code': '642' },
  { name: 'Russian Federation', code: 'RUS', 'country-code': '643' },
  { name: 'Rwanda', code: 'RWA', 'country-code': '646' },
  { name: 'Saint Barthélemy', code: 'BLM', 'country-code': '652' },
  { name: 'Saint Helena, Ascension and Tristan da Cunha', code: 'SHN', 'country-code': '654' },
  { name: 'Saint Kitts and Nevis', code: 'KNA', 'country-code': '659' },
  { name: 'Saint Lucia', code: 'LCA', 'country-code': '662' },
  { name: 'Saint Martin (French part)', code: 'MAF', 'country-code': '663' },
  { name: 'Saint Pierre and Miquelon', code: 'SPM', 'country-code': '666' },
  { name: 'Saint Vincent and the Grenadines', code: 'VCT', 'country-code': '670' },
  { name: 'Samoa', code: 'WSM', 'country-code': '882' },
  { name: 'San Marino', code: 'SMR', 'country-code': '674' },
  { name: 'Sao Tome and Principe', code: 'STP', 'country-code': '678' },
  { name: 'Saudi Arabia', code: 'SAU', 'country-code': '682' },
  { name: 'Senegal', code: 'SEN', 'country-code': '686' },
  { name: 'Serbia', code: 'SRB', 'country-code': '688' },
  { name: 'Seychelles', code: 'SYC', 'country-code': '690' },
  { name: 'Sierra Leone', code: 'SLE', 'country-code': '694' },
  { name: 'Singapore', code: 'SGP', 'country-code': '702' },
  { name: 'Sint Maarten (Dutch part)', code: 'SXM', 'country-code': '534' },
  { name: 'Slovakia', code: 'SVK', 'country-code': '703' },
  { name: 'Slovenia', code: 'SVN', 'country-code': '705' },
  { name: 'Solomon Islands', code: 'SLB', 'country-code': '090' },
  { name: 'Somalia', code: 'SOM', 'country-code': '706' },
  { name: 'South Africa', code: 'ZAF', 'country-code': '710' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'SGS', 'country-code': '239' },
  { name: 'South Sudan', code: 'SSD', 'country-code': '728' },
  { name: 'Spain', code: 'ESP', 'country-code': '724' },
  { name: 'Sri Lanka', code: 'LKA', 'country-code': '144' },
  { name: 'Sudan', code: 'SDN', 'country-code': '729' },
  { name: 'Suriname', code: 'SUR', 'country-code': '740' },
  { name: 'Svalbard and Jan Mayen', code: 'SJM', 'country-code': '744' },
  { name: 'Sweden', code: 'SWE', 'country-code': '752' },
  { name: 'Switzerland', code: 'CHE', 'country-code': '756' },
  { name: 'Syrian Arab Republic', code: 'SYR', 'country-code': '760' },
  { name: 'Taiwan, Province of China', code: 'TWN', 'country-code': '158' },
  { name: 'Tajikistan', code: 'TJK', 'country-code': '762' },
  { name: 'Tanzania, United Republic of', code: 'TZA', 'country-code': '834' },
  { name: 'Thailand', code: 'THA', 'country-code': '764' },
  { name: 'Timor-Leste', code: 'TLS', 'country-code': '626' },
  { name: 'Togo', code: 'TGO', 'country-code': '768' },
  { name: 'Tokelau', code: 'TKL', 'country-code': '772' },
  { name: 'Tonga', code: 'TON', 'country-code': '776' },
  { name: 'Trinidad and Tobago', code: 'TTO', 'country-code': '780' },
  { name: 'Tunisia', code: 'TUN', 'country-code': '788' },
  { name: 'Turkey', code: 'TUR', 'country-code': '792' },
  { name: 'Turkmenistan', code: 'TKM', 'country-code': '795' },
  { name: 'Turks and Caicos Islands', code: 'TCA', 'country-code': '796' },
  { name: 'Tuvalu', code: 'TUV', 'country-code': '798' },
  { name: 'Uganda', code: 'UGA', 'country-code': '800' },
  { name: 'Ukraine', code: 'UKR', 'country-code': '804' },
  { name: 'United Arab Emirates', code: 'ARE', 'country-code': '784' },
  {
    name: 'United Kingdom of Great Britain and Northern Ireland',
    code: 'GBR',
    'country-code': '826',
  },
  { name: 'United States of America', code: 'USA', 'country-code': '840' },
  { name: 'United States Minor Outlying Islands', code: 'UMI', 'country-code': '581' },
  { name: 'Uruguay', code: 'URY', 'country-code': '858' },
  { name: 'Uzbekistan', code: 'UZB', 'country-code': '860' },
  { name: 'Vanuatu', code: 'VUT', 'country-code': '548' },
  { name: 'Venezuela (Bolivarian Republic of)', code: 'VEN', 'country-code': '862' },
  { name: 'Viet Nam', code: 'VNM', 'country-code': '704' },
  { name: 'Virgin Islands (British)', code: 'VGB', 'country-code': '092' },
  { name: 'Virgin Islands (U.S.)', code: 'VIR', 'country-code': '850' },
  { name: 'Wallis and Futuna', code: 'WLF', 'country-code': '876' },
  { name: 'Western Sahara', code: 'ESH', 'country-code': '732' },
  { name: 'Yemen', code: 'YEM', 'country-code': '887' },
  { name: 'Zambia', code: 'ZMB', 'country-code': '894' },
  { name: 'Zimbabwe', code: 'ZWE', 'country-code': '716' },
]
