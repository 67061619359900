import Skeleton from 'react-loading-skeleton'
interface ICardPropertyDetailsProps {
  heading: string
  variant?: string
  propertyData?: any
}

const CardPropertyDetails = ({ heading, variant, propertyData }: ICardPropertyDetailsProps) => {
  const cardClass = `cardproperty -${variant}`
  return (
    <div className={cardClass}>
      <div className="title">
        <h4>{heading}</h4>
      </div>
      <div className="caption">
        <p>Highlights</p>
      </div>
      <div className="info-property">
        {
          <>
            <div className="stats -head">
              <div>Property Type</div>
              <div>
                {propertyData.getRealEstateInfoById.parcel.county_land_use_description ? (
                  propertyData.getRealEstateInfoById.parcel.county_land_use_description
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
            <div className="stats">
              <div>Neighborhood</div>
              <div>
                {propertyData.getRealEstateInfoById.parcel.subdivision ? (
                  propertyData.getRealEstateInfoById.parcel.subdivision
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
            <div className="stats">
              <div>Construction Year</div>
              <div>
                {propertyData.getRealEstateInfoById.structure.year_built ? (
                  propertyData.getRealEstateInfoById.structure.year_built
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
            <div className="stats">
              <div>Stories</div>
              <div>
                {propertyData.getRealEstateInfoById.structure.stories ? (
                  propertyData.getRealEstateInfoById.structure.stories
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
            <div className="stats">
              <div>Total Units</div>
              <div>
                {propertyData.getRealEstateInfoById.structure.units_count ? (
                  propertyData.getRealEstateInfoById.structure.units_count
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
          </>
        }
      </div>
      <div className="caption">
        <p>Details</p>
      </div>
      <div className="info-property -sub">
        {
          <>
            <div className="stats">
              <div>Foundation</div>
              <div>
                {propertyData.getRealEstateInfoById.structure.foundation_type ? (
                  propertyData.getRealEstateInfoById.structure.foundation_type
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
            <div className="stats">
              <div>Exterior Wall</div>
              <div>
                {propertyData.getRealEstateInfoById.structure.exterior_wall_type ? (
                  propertyData.getRealEstateInfoById.structure.exterior_wall_type
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
            <div className="stats">
              <div>Roof Type</div>
              <div>
                {propertyData.getRealEstateInfoById.structure.roof_material_type ? (
                  propertyData.getRealEstateInfoById.structure.roof_material_type
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
            <div className="stats">
              <div>Parking</div>
              <div>
                {propertyData.getRealEstateInfoById.structure.parking_spaces_count ? (
                  propertyData.getRealEstateInfoById.structure.parking_spaces_count
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
            <div className="stats">
              <div>Heating</div>
              <div>
                {propertyData.getRealEstateInfoById.structure.heating_type ? (
                  propertyData.getRealEstateInfoById.structure.heating_type
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
            <div className="stats">
              <div>Cooling</div>
              <div>
                {propertyData.getRealEstateInfoById.structure.air_conditioning_type ? (
                  propertyData.getRealEstateInfoById.structure.air_conditioning_type
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
            <div className="stats">
              <div>No. of Bathrooms</div>
              <div>
                {propertyData.getRealEstateInfoById.structure.baths ? (
                  propertyData.getRealEstateInfoById.structure.baths
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
            <div className="stats">
              <div>No. of Bedrooms</div>
              <div>
                {propertyData.getRealEstateInfoById.structure.beds_count ? (
                  propertyData.getRealEstateInfoById.structure.beds_count
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
            <div className="stats">
              <div>Acres</div>
              <div>
                {propertyData.getRealEstateInfoById.parcel.area_acres ? (
                  propertyData.getRealEstateInfoById.parcel.area_acres
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>{' '}
            <div className="stats">
              <div>Square Ft.</div>
              <div>
                {propertyData.getRealEstateInfoById.parcel.area_sq_ft ? (
                  propertyData.getRealEstateInfoById.parcel.area_sq_ft
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default CardPropertyDetails