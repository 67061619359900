import { useFormContext, Controller } from 'react-hook-form'
import { TextField } from '@mui/material'

interface IREInputProps {
  id: string
  label: string
  required: boolean
  maxlength?: number
  type: string
  variant: string
  value?: string
  handleChange: Function
  readonly: boolean
}

export default function REInput({
  id,
  label,
  value,
  type,
  variant,
  required,
  handleChange,
  readonly,
}: IREInputProps) {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext()

  return (
    <>
      <div className="relisting-input">
        <Controller
          name={id}
          control={control}
          rules={{ required: required }}
          render={({ field }) => (
            <TextField
              {...field}
              id={id}
              className="details"
              label={label}
              size="small"
              value={value ?? ''}
              data-cy={id}
              error={errors[id] && errors[id]?.type === 'required'}
              helperText={errors[id]?.type === 'required' ? `${label} is required` : ' '}
              {...register(id, {
                onChange: (event) => handleChange(id, event),
              })}
            />
          )}
        />
      </div>
    </>
  )
}
